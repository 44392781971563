export default function ThreeDotsIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66658 9.99992C8.66658 9.07944 9.41278 8.33325 10.3333 8.33325C11.2537 8.33325 11.9999 9.07944 11.9999 9.99992C11.9999 10.9204 11.2537 11.6666 10.3333 11.6666C9.41278 11.6666 8.66658 10.9204 8.66658 9.99992Z"
        fill="#E0DFE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4999 9.99992C14.4999 9.07944 15.2461 8.33325 16.1666 8.33325C17.0871 8.33325 17.8333 9.07944 17.8333 9.99992C17.8333 10.9204 17.0871 11.6666 16.1666 11.6666C15.2461 11.6666 14.4999 10.9204 14.4999 9.99992Z"
        fill="#E0DFE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.83325 9.99992C2.83325 9.07944 3.57944 8.33325 4.49992 8.33325C5.42039 8.33325 6.16659 9.07944 6.16659 9.99992C6.16659 10.9204 5.42039 11.6666 4.49992 11.6666C3.57944 11.6666 2.83325 10.9204 2.83325 9.99992Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
