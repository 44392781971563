import { memo, useCallback, useEffect, useState } from "react";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { jsonParser } from "../../../common/util";
import { FormValuesType } from "../../../pages/programSetup/formConfigerProgramme";
import { getKPI, getValueDriverList } from "../../../services/authForms";
import GridCard from "../../atoms/gridCard";
import CheckBoxCard, { CheckBoxCardProps } from "../../molecules/checkBoxCard";
import style from "./stepValueDriver.module.scss";

type props = {
  keyProcesses: any[];
  errors?: FieldErrors<FormValuesType>;
  register?: UseFormRegister<FormValuesType>;
  setValue?: UseFormSetValue<FormValuesType>;
  watch: UseFormWatch<FormValuesType>;
};
// /value-driver/{keyProcessId}

function countMatchString(item: string, array: string[]) {
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i].includes(item)) {
      count++;
    }
  }
  return count;
}

const StepValueDriver: React.FC<props> = ({
  keyProcesses,
  errors,
  register,
  setValue,
  watch,
}) => {
  const [valueDrivers, setValueDrivers] = useState<any>({});
  const [valueKPI, setvalueKPI] = useState<any>({});
  const [dataKeys, setDataKeys] = useState<string[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const getData = useCallback(async () => {
    setIsDataLoading(true);
    const keyProcessIds = keyProcesses.map((keyProcess) => keyProcess.id);
    const promises = [getValueDriverList(keyProcessIds), getKPI(keyProcessIds)];

    const response = await Promise.allSettled(promises);

    const responseValueDriver: any = response[0];
    const responseKPI: any = response[1];

    const valueDriverData = responseValueDriver?.value?.data?.data || {};
    const KPIdata = responseKPI?.value?.data?.data || {};
    for (const k in KPIdata) {
      const dataArr = KPIdata[k].map((kpi: any) => {
        return { ...kpi, formulaVariables: jsonParser(kpi.formulaVariables) };
      });
      KPIdata[k] = dataArr;
    }
    // get unique keys from both data
    const valueDriverKeys = [...Object.keys(valueDriverData)];
    const KPIkeys = [...Object.keys(KPIdata)];
    const keys = [...new Set([...KPIkeys, ...valueDriverKeys])];

    setValueDrivers(valueDriverData);
    setvalueKPI(KPIdata);
    setDataKeys(keys);
    setIsDataLoading(false);
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {dataKeys.map((keyName) => {
        const keyNameNoSpace = keyName.replace(/\s/g, "");
        const selectedvalueDrivers = watch(
          `valueDriversAndKPIs.valueDrivers.${keyNameNoSpace}`
        );
        const selectedDriverCount = selectedvalueDrivers?.length || 0;
        const selectedKPI = watch(`valueDriversAndKPIs.KPIs.${keyNameNoSpace}`);
        const selectedKpiCount = selectedKPI?.length || 0;

        // error state
        const valueDriversError =
          errors?.valueDriversAndKPIs?.valueDrivers || {};
        const valueDriversErrorMsg =
          valueDriversError[keyNameNoSpace]?.message || "";
        const kpiError = errors?.valueDriversAndKPIs?.KPIs || {};
        const kpiErrorMsg = kpiError[keyNameNoSpace]?.message || "";
        return (
          <GridCard key={keyName} className={style.cardStyle}>
            <div className={style.stepBoxWrapper}>
              <h4>{keyName}</h4>
              <div className={style.driversWrapper}>
                <h5> What are your value drivers? </h5>
                {valueDriversErrorMsg && (
                  <p className='text-danger'>{valueDriversErrorMsg}</p>
                )}
                <div className={style.list}>
                  {valueDrivers[keyName]?.map((valueDriver: any) => {
                    const isDisabled =
                      !!selectedvalueDrivers?.find &&
                      !selectedvalueDrivers.find((vDriver) =>
                        vDriver.includes(valueDriver.id)
                      ) &&
                      selectedDriverCount >= 3;
                    return (
                      <MemoChecbox
                        key={valueDriver.id}
                        label={valueDriver.name}
                        isCheckboxLeft={true}
                        disabled={isDisabled}
                        inputProps={{
                          ...(register
                            ? register(
                                `valueDriversAndKPIs.valueDrivers.${keyNameNoSpace}`,
                                {
                                  required: "Please select at least one item.",
                                }
                              )
                            : {}),
                          value: JSON.stringify({
                            id: valueDriver.id,
                            name: valueDriver.name,
                            keyProcessId: valueDriver.keyProcessId,
                            keyProcess: valueDriver.keyProcess,
                          }),
                        }}
                        title={valueDriver?.definition}
                      />
                    );
                  })}
                </div>
              </div>

              <h5> What are the key performance indicators?</h5>
              {kpiErrorMsg && <p className='text-danger'>{kpiErrorMsg}</p>}
              <div className={style.list}>
                {valueKPI[keyName]?.map((kpi: any) => {
                  const isDisabled =
                    !!selectedKPI?.find &&
                    !selectedKPI.find((sKpi) => sKpi.includes(kpi.id)) &&
                    selectedKpiCount >= 3;
                  const kpiValue = {
                    id: kpi.id,
                    name: kpi.name,
                    formulaVariables: kpi.formulaVariables,
                    formula: kpi.formula,
                    keyProcessId: kpi.keyProcessId,
                    keyProcess: kpi.keyProcess,
                    Metrics: kpi.Metrics,
                  };

                  const reg: any = register
                    ? register(`valueDriversAndKPIs.KPIs.${keyNameNoSpace}`, {
                        required: "Please select at least one item.",
                      })
                    : {};
                  return (
                    <MemoChecbox
                      key={kpi.id}
                      label={kpi.name}
                      isCheckboxLeft={true}
                      disabled={isDisabled}
                      inputProps={{
                        ...reg,
                        onChange: (e) => {
                          reg?.onChange && reg.onChange(e);
                          setValue && setValue("baseline", []);
                        },
                        value: JSON.stringify(kpiValue),
                      }}
                      title={kpi?.definition}
                    />
                  );
                })}
              </div>
            </div>
          </GridCard>
        );
      })}

      {!dataKeys.length && (
        <div>
          {isDataLoading ? (
            <big className={`noDataBigText`}>Loading...</big>
          ) : (
            <big className={`noDataBigText`}>
              Data not found! <br /> <small>Go back and change options.</small>
            </big>
          )}
          <input
            hidden
            value=''
            {...(register
              ? register("valueDriversAndKPIs.KPIs", {
                  required: "You can't go forward.",
                })
              : {})}
          />
        </div>
      )}
    </div>
  );
};
export default StepValueDriver;

const MemoChecbox = memo((props: CheckBoxCardProps) => (
  <CheckBoxCard {...props} />
));
