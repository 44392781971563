import { StarIcon } from "../icons";
import style from "./starRating.module.scss";

type props = {
  rating: number;
};

const StarRating: React.FC<props> = ({ rating }) => {
  const ratingRound = rating.toFixed(rating % 1 === 0 ? 0 : 1);
  return (
    <div className={style.rating}>
      <StarIcon className={style.ratingIcon} /> <span className={style.ratingText}>{ratingRound}</span>
    </div>
  );
};
export default StarRating;
