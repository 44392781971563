import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`;
const customerBaseUrl = `${process.env.REACT_APP_API_BASE_URL}customer`;

export const fetchPrograms = () => {
  let url;
  url = `${customerBaseUrl}/project`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const createMeeting = (body: any) => {
  let url;
  url = `${baseUrl}meeting`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const confirmMeeting = (meetingId: string, body: any) => {
  let url;
  url = `${baseUrl}meeting/${meetingId}/confirm`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, body);
};

export const stripeCheckout = (meetingId: string) => {
  let url;
  url = `${baseUrl}meeting/${meetingId}/payment/checkout`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
};

export const stripeCheckoutSuccess = (meetingId: string, body: any) => {
  let url;
  url = `${baseUrl}meeting/${meetingId}/payment/success`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, body);
};
