import { InfoOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { ReactNode } from "react";
import styles from "./GridCard.module.scss";

const GridCard = ({
  children,
  className,
  title,
  info,
  cornerInfo,
  variant = "primary",
}: {
  children?: ReactNode;
  className?: string;
  title?: string;
  info?: any;
  variant?: string;
  cornerInfo?: ReactNode;
}) => {
  
  return (
    <div className={`${styles.card} ${className}`} data-variant={variant}>
      {(title || cornerInfo) && (
        <div className={styles.cardTitle}>
          {title}
          {info && (
            <Tooltip title={info}>
              <InfoOutlined className="info_icon ms-2" />
            </Tooltip>
          )}
          {cornerInfo && (
            <strong className={styles.cornerInfo}>{cornerInfo}</strong>
          )}
        </div>
      )}
      <div className={styles.cardContent}>{children}</div>
    </div>
  );
};

export default GridCard;
