import { memo, useEffect, useState } from "react";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { FormValuesType } from "../../../pages/programSetup/formConfigerProgramme";
import { getBusinessProblemList } from "../../../services/authForms";
import GridCard from "../../atoms/gridCard";
import CheckBoxCard from "../../molecules/checkBoxCard";

import style from "./stepBusinessProblem.module.scss";

type props = {
  register?: UseFormRegister<FormValuesType>;
  errors?: FieldErrors<FormValuesType>;
  industryId: string;
  lineofBusinessId: string;
  setValue?: UseFormSetValue<FormValuesType>;
};

const StepBusinessProblem: React.FC<props> = ({
  industryId,
  lineofBusinessId,
  errors,
  register,
  setValue,
}) => {
  const [businessProblemData, setBusinessProblemData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const errorMsg = errors?.businessProblem?.message || "";

  useEffect(() => {
    setIsDataLoading(true);
    getBusinessProblemList({
      industryId: industryId,
      lineofBusinessId: lineofBusinessId,
    })?.then((res: any) => {
      const resData = res?.data;
      if (resData?.status === "success") {
        setBusinessProblemData(resData.data);
      }
      setIsDataLoading(false);
    });
  }, [industryId, lineofBusinessId]);
  return (
    <GridCard>
      <div className={style.stepBoxWrapper}>
        <h4>What is your business problem?</h4>
        {errorMsg && <p className='text-danger'>{errorMsg}</p>}
        <div className={style.list}>
          {businessProblemData.map((businessProblem: any) => {
            const reg: any = register
              ? register("businessProblem", {
                  required: "Business problem is required.",
                })
              : {};
            return (
              <CheckBoxCard
                key={businessProblem.id}
                label={businessProblem.name}
                type='radio'
                inputProps={{
                  ...reg,
                  value: `${businessProblem.id},${businessProblem.name}`,
                  onChange: (e) => {
                    reg?.onChange(e);
                    setValue && setValue("keyProcesses", []);
                  },
                }}
                title={businessProblem.definition}
              />
            );
          })}
        </div>
        {!businessProblemData.length && (
          <div>
            {isDataLoading ? (
              <big className={`noDataBigText`}>Loading...</big>
            ) : (
              <big className={`noDataBigText`}>
                Data not found! <br />{" "}
                <small>Go back and change options.</small>
              </big>
            )}
            <input
              hidden
              value=''
              {...(register
                ? register("businessProblem", {
                    required: "You can't go forward.",
                  })
                : {})}
            />
          </div>
        )}
      </div>
    </GridCard>
  );
};
export default memo(StepBusinessProblem);
