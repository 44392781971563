import React, { InputHTMLAttributes, ReactNode } from "react";
import style from "./checkBoxCustom.module.scss";

type props = {
  type?: 'checkbox' | 'radio';
  label?: ReactNode;
  className?: string | undefined;
  isLabelComponent?: boolean
} & InputHTMLAttributes<HTMLInputElement>;

const CheckBoxCustom = React.forwardRef((props: props, ref: any) => {
  const {
    type = "checkbox",
    label,
    className = "",
    isLabelComponent = true,
    ...restProps
  } = props;
  const Component = isLabelComponent ? "label" : "div";

  return (
    <Component ref={ref} className={`${style.checkBox} ${className}`}>
      <div className={style.inputWrapper}>
        <input type={type} {...restProps} />
        <span className={style.checkmark}></span>
      </div>
      {!!label && <span className={style.text}>{label}</span>}
    </Component>
  );
});

export default React.memo(CheckBoxCustom);
