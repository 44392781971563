export default function AivaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33332 2.70841C8.59485 2.70841 8.82872 2.87124 8.91948 3.11652L10.6204 7.71305L15.2169 9.41393C15.4622 9.50468 15.625 9.73856 15.625 10.0001C15.625 10.2616 15.4622 10.4955 15.2169 10.5862L10.6204 12.2871L8.91948 16.8836C8.82872 17.1289 8.59485 17.2917 8.33332 17.2917C8.0718 17.2917 7.83793 17.1289 7.74717 16.8836L6.04629 12.2871L1.44976 10.5862C1.20449 10.4955 1.04166 10.2616 1.04166 10.0001C1.04166 9.73856 1.20449 9.50468 1.44976 9.41393L6.04629 7.71305L7.74717 3.11652C7.83793 2.87124 8.0718 2.70841 8.33332 2.70841ZM8.33332 5.13438L7.11888 8.41637C7.05557 8.58745 6.92069 8.72233 6.74962 8.78563L3.46762 10.0001L6.74962 11.2145C6.92069 11.2778 7.05557 11.4127 7.11888 11.5838L8.33332 14.8658L9.54777 11.5838C9.61107 11.4127 9.74596 11.2778 9.91703 11.2145L13.199 10.0001L9.91703 8.78563C9.74596 8.72233 9.61107 8.58745 9.54777 8.41637L8.33332 5.13438Z"
        fill="#E0DFE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6667 1.04175C16.9282 1.04175 17.1621 1.20458 17.2528 1.44985L17.6032 2.39684L18.5502 2.74726C18.7955 2.83802 18.9583 3.07189 18.9583 3.33341C18.9583 3.59494 18.7955 3.82881 18.5502 3.91957L17.6032 4.26999L17.2528 5.21698C17.1621 5.46225 16.9282 5.62508 16.6667 5.62508C16.4051 5.62508 16.1713 5.46225 16.0805 5.21698L15.7301 4.26999L14.7831 3.91957C14.5378 3.82881 14.375 3.59494 14.375 3.33341C14.375 3.07189 14.5378 2.83802 14.7831 2.74726L15.7301 2.39684L16.0805 1.44985C16.1713 1.20458 16.4051 1.04175 16.6667 1.04175Z"
        fill="#E0DFE3"
      />
      <path
        d="M16.6667 14.3751C16.9282 14.3751 17.1621 14.5379 17.2528 14.7832L17.6032 15.7302L18.5502 16.0806C18.7955 16.1714 18.9583 16.4052 18.9583 16.6667C18.9583 16.9283 18.7955 17.1621 18.5502 17.2529L17.6032 17.6033L17.2528 18.5503C17.1621 18.7956 16.9282 18.9584 16.6667 18.9584C16.4051 18.9584 16.1713 18.7956 16.0805 18.5503L15.7301 17.6033L14.7831 17.2529C14.5378 17.1621 14.375 16.9283 14.375 16.6667C14.375 16.4052 14.5378 16.1714 14.7831 16.0806L15.7301 15.7302L16.0805 14.7832C16.1713 14.5379 16.4051 14.3751 16.6667 14.3751Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
