import { Clear } from "@mui/icons-material";
import React, { useState } from "react";
import { FileIcon, UploadIcon } from "../../atoms/icons";
import style from "./uploadDocument.module.scss";

type UploadDocumentProps = {
  instructions?: React.ReactNode;
  error?: string | null;
  onChange?: (file: File | null) => void;
  allowedFileTypes?: string; // Comma-separated list of allowed file extensions, e.g., ".pdf,.docx,.doc,.mp3,.txt"
  disabled?: boolean;
};

const UploadDocument: React.FC<UploadDocumentProps> = ({
  instructions,
  error,
  onChange,
  allowedFileTypes = "",
  disabled = false,
}) => {
  const [file, setFile] = useState<File | null>(null);

  const handleChange = (e: any) => {
    e.preventDefault();
    const files = e.target?.files || e?.dataTransfer?.files;
    if (files) {
      const file = files[0];
      setFile(file);
      onChange && onChange(file);
    }
  };

  const handleClear = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setFile(null);
    onChange && onChange(null);
  };

  return (
    <div className={`input-row mb-0 ${disabled ? "disabled" : ""}`}>
      <label className={style.addBorder} onDrop={handleChange} onDragOver={(e) => e.preventDefault()}>
        {file && <Clear className={style.clearIcon} onClick={handleClear} />}
        <div className={style.iconWrapper}>
          {file ? <FileIcon width={`48px`} height={`48px`} /> : <UploadIcon width={`48px`} height={`48px`} />}
        </div>
        {!!file && <p className={style.fileName}>{file.name}</p>}
        {!file && (
          <p className={style.infoText}>
            <strong>Drag & drop your document here.</strong>
            <span className={style.orText}>or</span>
            <span>Browse your files</span>
          </p>
        )}
        <input
          hidden
          type="file"
          value={!!file ? "" : ""}
          onChange={handleChange}
          accept={allowedFileTypes}
          disabled={disabled}
        />
      </label>
      {!!error && <div className={`text-danger`}>{error}</div>}
      <div className={style.instructionsText}>{instructions}</div>
    </div>
  );
};

export default UploadDocument;
