import { ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../atoms/button";
import SuccessIcon from "../../atoms/icons/Success";
import style from "./congratulations.module.scss";

type props = {
  children?: ReactNode;
  setupName: string;
};

const Congratulations: React.FC<props> = ({ children, setupName }) => {
  const navigate = useNavigate();
  const { programId } = useParams();
  return (
    <div className={style.congratulationsWrapper}>
      <SuccessIcon />
      <h1>Congratulations</h1>
      <p className={style.subtext}>
        You've successfully set-up program in <strong>{setupName}</strong>.
      </p>
      {children && (
        <div className={style.congratulationsContent}>{children}</div>
      )}

      <div className={style.congratulationsFooter}>
        <Button
          onClick={() => navigate(`/program/summary/${programId}`)}
          variant='light'
        >
          Get started on plan
        </Button>
      </div>
    </div>
  );
};
export default Congratulations;
