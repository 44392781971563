import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { Badge, Box, Drawer, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAppDataContext } from "../../../context/appData.context";
import { fetchMeeting } from "../../../services/calendar";
import {
  CalendarIcon,
  DashboardIcon,
  HelpCricleIcon,
  SettingsIcon,
  UsersIcon,
} from "../../atoms/icons";
import IdeaIcon from "../../atoms/icons/IdeaIcon";
import HelpModal from "../../molecules/helpModal";
import UpcomingCalls from "../../molecules/upcomingCalls";
import styles from "./header.module.scss";
import HeaderResponsive from "./headerResponsive";

function Header() {
  const { programId } = useParams();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openHelpModal, setOpenHelpModal] = useState(false);

  //meetingsCount
  const [totalMeetings, setTotalMeetings] = useState(0);

  // get profile data
  const { userData, logout } = useAppDataContext();
  const [upcomingCallsDrawer, setUpcomingCallsDrawer] = useState(false);
  const logOutHandler = () => {
    logout();
  };

  const customerDetails = userData?.userProfile;
  const customerSubscriptionDetails =
    userData?.userProfile?.customerProfile?.customerSubscription;

  const now = new Date();
  const startTime = new Date(now);
  const limit = 5;

  const fetchMeetingsCount = async () => {
    try {
      const res = await fetchMeeting(
        startTime.toISOString(),
        startTime.toISOString(),
        limit
      );
      setTotalMeetings(res?.data?.data?.totalMeetings);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchMeetingsCount();
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.headerMobile}>
        <IconButton
          onClick={() => setIsDrawerOpen(true)}
          size='large'
          edge='start'
          color='inherit'
          aria-label='logo'
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor='left'
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <Box
            sx={{ background: "#151419" }}
            p={2}
            width='300px'
            role='presentation'
            textAlign='center'
          >
            <HeaderResponsive />
          </Box>
        </Drawer>
      </div>
      <div className={styles.headerLeft}>
        <img src='/assets/images/header-logo.svg' loading='lazy' alt='' />
        <ul className={styles.menuItems}>
          <li>
            <NavLink to='/aiva'>
              <DashboardIcon />
              A.I.va
            </NavLink>
          </li>
          {customerSubscriptionDetails?.subscriptionPlanName ===
            "Evise Enterprise" && (
            <li>
              <NavLink to={programId ? `/program/${programId}` : "/program"}>
                <IdeaIcon />
                Program
              </NavLink>
            </li>
          )}

          <li>
            <NavLink to='/experts'>
              <UsersIcon /> Experts
            </NavLink>
          </li>
        </ul>
      </div>

      <ul className={styles.headerRight}>
        <li onClick={() => setOpenHelpModal(true)}>
          <HelpCricleIcon />
        </li>
        {openHelpModal && (
          <HelpModal
            openModal={openHelpModal}
            setOpenModal={setOpenHelpModal}
          />
        )}
        <li onClick={() => setUpcomingCallsDrawer(true)}>
          <Badge
            badgeContent={totalMeetings}
            color='secondary'
            classes={{ badge: "custom-badge" }}
          >
            <CalendarIcon />
          </Badge>
        </li>
        {upcomingCallsDrawer && (
          <UpcomingCalls
            openDrawer={upcomingCallsDrawer}
            setOpenDrawer={setUpcomingCallsDrawer}
          />
        )}
        <li>
          <NavLink to='/setting'>
            <SettingsIcon />
          </NavLink>
        </li>

        <li className='dropdown'>
          <div
            id='logoutDropdown'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            <img
              className={styles.userIcon}
              src={customerDetails?.avatarUrl || "/assets/images/userdummy.png"}
              alt='profile pic'
              title={customerDetails?.name}
            />
            <KeyboardArrowDownOutlinedIcon />
          </div>

          <ul className='dropdown-menu' aria-labelledby='logoutDropdown'>
            <li>
              <a className='dropdown-item' onClick={logOutHandler} href='#'>
                Log out
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </header>
  );
}

export default Header;
