import styles from "./GridContainer.module.scss";

const GridContainer = ({
  children = "",
  variant="",
  className = "",
  colSize,
}: {
  children?: any;
  className?: string;
  variant?: string;
  colSize?: number;
}) => {
  
  return (
    <section
      data-colsize={colSize}
      className={`${styles.grid} ${styles[variant]} ${className}`}
    >
      {children}
    </section>
  );
};

export default GridContainer;
