import { ChevronLeftOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { handleScrollToTop } from "../../common/util";
import CustomAlert from "../../components/atoms/alert/CustomAlert";
import Button from "../../components/atoms/button";
import GridCard from "../../components/atoms/gridCard";
import StepperCustom from "../../components/molecules/stepperCustom";
import StepBusinessProblem from "../../components/organisms/stepBusinessProblem";
import StepCrucialProcessesProblem from "../../components/organisms/stepCrucialProcessesProblem";
import StepIndustryList from "../../components/organisms/stepIndustryList";
import StepLineofBusiness from "../../components/organisms/stepLineofBusiness";
import StepSetBaseline from "../../components/organisms/stepSetBaseline";
import StepValueDriver from "../../components/organisms/stepValueDriver";
import { useProgramContext } from "../../context/program.context";
import { FormValuesType } from "./formConfigerProgramme";
import ProgramSetupFinished from "./programFinished";
import style from "./programSetup.module.scss";

type props = {};
const ProgramSetup: React.FC<props> = () => {
  const navigate = useNavigate();
  const {
    programId,
    selectedProgramDetail,
    programLoadingStatus,
    updateProgram,
  } = useProgramContext();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState<FormValuesType>();
  const [isStepFinished, setIsStepFinished] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [alert, SetAlert] = useState<any>({
    show: false,
    state: "error",
    message: "Somthing went Wrong!",
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValuesType>();

  const onSubmit = handleSubmit((data) => {
    handleScrollToTop();
    if (!!data?.keyProcesses) {
      const keyProcesses = data.keyProcesses;
      data.keyProcesses = keyProcesses.map((keyProcess) =>
        JSON.parse(keyProcess)
      );
    }
    if (!!data?.valueDriversAndKPIs?.KPIs) {
      const formKpi: any = data.valueDriversAndKPIs.KPIs || {};
      for (const key in formKpi) {
        if (!!formKpi[key]?.length) {
          formKpi[key] = formKpi[key].map((item: string) => JSON.parse(item));
        } else {
          formKpi[key] = [];
        }
      }
    }
    if (!!data?.valueDriversAndKPIs?.valueDrivers) {
      const formValueDrivers: any = data.valueDriversAndKPIs.valueDrivers;
      for (const key in formValueDrivers) {
        if (!!formValueDrivers[key]?.length) {
          formValueDrivers[key] = formValueDrivers[key].map((item: string) =>
            JSON.parse(item)
          );
        } else {
          formValueDrivers[key] = [];
        }
      }
    }

    setFormData(data);
    if (step !== 5) {
      setStep(step + 1);
    } else {
      const valueDrivers: string[] = Object.values(
        data.valueDriversAndKPIs.valueDrivers
      )
        .flat()
        .map((item: any) => item.id);
      const kpis: any[] = data.baseline.map((baselineItem: any) => {
        const kpiArr = baselineItem.ids.split(",");
        return {
          baseLineValue: baselineItem.formulaTotal,
          kpi: kpiArr,
        };
      });
      const keyProcessId = data.keyProcesses.map(
        (keyProcess: any) => keyProcess.id
      );
      const payload = {
        industryId: `${data.industry}`.split(",")[0],
        lineOfBusinessId: `${data.lineOfBusiness}`.split(",")[0],
        businessProblemId: `${data.businessProblem}`.split(",")[0],
        valueDriver: valueDrivers,
        keyProcess: keyProcessId,
        kpis: kpis,
        projectStatus: "TO_BE_STARTED",
      };
      updateProgram(payload, (resData) => {
        if (resData.status === "success") {
          setIsStepFinished(true);
          setEditMode(false);
        } else {
          SetAlert({ ...alert, show: true });
        }
      });
    }
  });

  return (
    <>
      {(!selectedProgramDetail ||
        selectedProgramDetail?.projectStatus !== "IN_SHELL") &&
        !isStepFinished && (
          <div className={style.stepInfoBox}>
            <GridCard>
              <big className={`noDataBigText`}>
                {programLoadingStatus === "failed" && (
                  <>
                    404!!!
                    <div style={{ fontSize: "75%", marginBottom: "1.25rem" }}>
                      Program not found.
                    </div>
                    <Button
                      onClick={() => {
                        navigate("/program");
                      }}
                    >
                      <ChevronLeftOutlined /> Change Program
                    </Button>
                  </>
                )}
                {programLoadingStatus === "success" &&
                  selectedProgramDetail?.projectStatus !== "IN_SHELL" && (
                    <>
                      <div style={{ marginBottom: "1.25rem" }}>
                        This program is already set-up
                      </div>
                      <Button
                        onClick={() => {
                          navigate(`/program/${programId}`);
                        }}
                      >
                        <ChevronLeftOutlined /> Back to Program
                      </Button>
                    </>
                  )}
              </big>
            </GridCard>
          </div>
        )}

      {(selectedProgramDetail?.projectStatus === "IN_SHELL" ||
        (editMode && isStepFinished)) && (
        <div className={style.stepperWrapper}>
          <form onSubmit={onSubmit}>
            <StepperCustom
              title='Set-up program'
              finalBtnType='submit'
              nextBtnType='submit'
              activeStep={step}
              onBackClick={(stepIndex) => {
                setStep(stepIndex);
                if (stepIndex === -1) {
                  navigate(-1);
                }
              }}
              data={[
                <StepIndustryList
                  isFilter={true}
                  register={register}
                  errors={errors}
                />,
                <StepLineofBusiness
                  isFilter={true}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                />,
                <StepBusinessProblem
                  register={register}
                  errors={errors}
                  industryId={formData?.industry || ""}
                  lineofBusinessId={formData?.lineOfBusiness || ""}
                  setValue={setValue}
                />,
                <StepCrucialProcessesProblem
                  register={register}
                  errors={errors}
                  businessProblemId={formData?.businessProblem || ""}
                  watch={watch}
                  setValue={setValue}
                />,
                <StepValueDriver
                  register={register}
                  errors={errors}
                  keyProcesses={formData?.keyProcesses || []}
                  watch={watch}
                  setValue={setValue}
                />,
                <StepSetBaseline
                  register={register}
                  errors={errors}
                  formData={formData}
                  watch={watch}
                  setValue={setValue}
                />,
              ]}
            />
            <CustomAlert
              show={alert.show}
              message={alert.message}
              type={alert.state}
              onClose={() => {
                SetAlert({ ...alert, show: false });
              }}
            />
          </form>
        </div>
      )}

      {!!selectedProgramDetail &&
        selectedProgramDetail?.projectStatus !== "IN_SHELL" &&
        isStepFinished &&
        !editMode && (
          <ProgramSetupFinished
            onEdit={(index) => {
              setEditMode(true);
              setStep(index);
            }}
            submitDetails={selectedProgramDetail}
            formData={formData}
          />
        )}
    </>
  );
};
export default ProgramSetup;
