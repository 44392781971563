import ExpertsListingContent from "../../components/organisms/expertsListingContent";
import Header from "../../components/organisms/header";

type props = {};

const ExpertsList: React.FC<props> = () => {

  return (
    <div className="mainWrapper">
      {/* header */}
      <Header />
      <section className="contentWrapper">
        <ExpertsListingContent />
      </section>
    </div>
  );
};
export default ExpertsList;
