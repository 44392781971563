export default function UploadIcon(props:any = {}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_4057_3410)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.71946 2.27388C8.85237 2.23214 9.98 2.44777 11.0176 2.90456C12.0551 3.36135 12.9757 4.04742 13.7099 4.91118C14.3028 5.60861 14.7618 6.40725 15.066 7.26675H15.5C16.6109 7.26748 17.6905 7.63813 18.5673 8.3202C19.4441 9.00226 20.0688 9.9569 20.3428 11.0334C20.6168 12.11 20.5245 13.2471 20.0805 14.2654C19.6364 15.2836 18.8659 16.125 17.8906 16.6567C17.4865 16.877 16.9803 16.7281 16.76 16.324C16.5398 15.9199 16.6887 15.4137 17.0928 15.1934C17.7431 14.8389 18.2567 14.278 18.5527 13.5992C18.8488 12.9203 18.9103 12.1622 18.7276 11.4445C18.545 10.7268 18.1285 10.0904 17.5439 9.63572C16.9594 9.18101 16.2401 8.93391 15.4995 8.93342H14.4501C14.0702 8.93342 13.7383 8.67648 13.6432 8.30867C13.4225 7.455 13.0112 6.66246 12.4401 5.99065C11.869 5.31884 11.153 4.78523 10.346 4.42995C9.53902 4.07467 8.66198 3.90696 7.78083 3.93942C6.89968 3.97189 6.03735 4.20368 5.25868 4.61739C4.48001 5.03109 3.80526 5.61594 3.28515 6.32795C2.76505 7.03997 2.41312 7.86063 2.25583 8.72823C2.09854 9.59584 2.13999 10.4878 2.37704 11.3371C2.6141 12.1864 3.04061 12.9709 3.62449 13.6316C3.92926 13.9765 3.89675 14.5031 3.55188 14.8079C3.20701 15.1126 2.68037 15.0801 2.3756 14.7352C1.62489 13.8857 1.07653 12.8771 0.771741 11.7852C0.466952 10.6932 0.413669 9.54642 0.615897 8.43093C0.818125 7.31544 1.2706 6.2603 1.93931 5.34485C2.60802 4.4294 3.47556 3.67746 4.47671 3.14556C5.47785 2.61365 6.58656 2.31563 7.71946 2.27388ZM9.91079 10.0108C10.2362 9.68539 10.7639 9.68539 11.0893 10.0108L14.4226 13.3442C14.7481 13.6696 14.7481 14.1972 14.4226 14.5227C14.0972 14.8481 13.5696 14.8481 13.2441 14.5227L11.3334 12.6119V18.1001C11.3334 18.5603 10.9603 18.9334 10.5 18.9334C10.0398 18.9334 9.66672 18.5603 9.66672 18.1001V12.6119L7.75597 14.5227C7.43053 14.8481 6.9029 14.8481 6.57746 14.5227C6.25202 14.1972 6.25202 13.6696 6.57746 13.3442L9.91079 10.0108Z"
          fill="#E0DFE3"
        />
      </g>
      <defs>
        <clipPath id="clip0_4057_3410">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5 0.600098)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
