import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { evaluateExpression } from "../../../common/util";
import { FormValuesType } from "../../../pages/programSetup/formConfigerProgramme";
import GridCard from "../../atoms/gridCard";
import style from "./stepSetBaseline.module.scss";

type props = {
  register: UseFormRegister<FormValuesType>;
  errors?: FieldErrors<FormValuesType>;
  formData: any;
  watch: UseFormWatch<FormValuesType>;
  setValue?: UseFormSetValue<FormValuesType>;
};

function mergeItemsBySameName(inputObject: any) {
  const mergedArray = [];
  const nameMap: any = {};

  for (const key in inputObject) {
    if (Object.hasOwnProperty.call(inputObject, key)) {
      const processArray = inputObject[key];

      processArray.forEach((item: any) => {
        const name = item?.name;
        if (!nameMap[name]) {
          nameMap[name] = {
            item,
            ids: [],
            keyProcesses: { ids: [], names: [] },
          };
        }

        nameMap[name].ids.push(item.id);
        nameMap[name].keyProcesses.ids.push(item.keyProcessId);
        if (item.keyProcess?.name) {
          nameMap[name].keyProcesses.names.push(item.keyProcess.name);
        }
      });
    }
  }

  for (const name in nameMap) {
    if (Object.hasOwnProperty.call(nameMap, name)) {
      const { item, ids, keyProcesses } = nameMap[name];
      item.ids = ids;
      item.keyProcesses = keyProcesses;
      delete item.id;
      delete item.keyProcessId;
      delete item.keyProcess;
      mergedArray.push(item);
    }
  }

  return mergedArray;
}

const StepSetBaseline: React.FC<props> = ({
  register,
  formData,
  errors,
  watch,
  setValue,
}) => {
  const selectedKPIs = formData?.valueDriversAndKPIs?.KPIs || {};
  const uniqueKpiBaseline = mergeItemsBySameName(selectedKPIs);

  return (
    <div>
      <h4 className='mb-3'>Set a baseline</h4>
      {uniqueKpiBaseline.map((KPI: any, index: number) => {
        return (
          <KpiFormulaSolution
            KPI={KPI}
            baselineIndex={index}
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
            key={index}
          />
        );
      })}

      {!uniqueKpiBaseline.length && (
        <GridCard className={style.formWrapper}>
          <big className={`noDataBigText`}>
            Data not found! <br /> <small>Go back and change options.</small>
          </big>
        </GridCard>
      )}
    </div>
  );
};
export default StepSetBaseline;

const KpiFormulaSolution = ({
  KPI,
  baselineIndex,
  register,
  errors,
  watch,
  setValue,
}: any) => {
  const inputVariables =
    watch(`baseline.${baselineIndex}.formulaVariables`) || [];
  const formulaVariablesDetails: any = KPI.formulaVariables;
  const formulaVariablesArr = Object.keys(formulaVariablesDetails) || [];
  const kpiFormula = KPI.formula;
  const baseLineError: any = !!errors?.baseline
    ? errors?.baseline[baselineIndex]
    : {};

  // calculating
  let formulaError = "";
  let evalResult = NaN;
  let finalResult = "0";
  // check Variable exists in formula
  if (
    formulaVariablesArr.every((variable: string) =>
      kpiFormula.includes(variable)
    )
  ) {
    let formula = kpiFormula;
    formulaVariablesArr.forEach((variable: string, i: number) => {
      const val = inputVariables[i]?.value ? inputVariables[i]?.value : 0;
      formula = formula.replace(new RegExp(variable, "g"), val);
    });
    evalResult = evaluateExpression(formula);

    if (!isNaN(evalResult)) {
      finalResult = `${Math.round(evalResult)}`;
    } else {
      console.log("Error formula", formula);
      finalResult = "--";
      formulaError = "Calculation error!";
    }
    finalResult = finalResult === "Infinity" ? "--" : finalResult;
  } else {
    formulaError = "Calculation error! please, choose other KPI";
  }

  setValue &&
    setValue(
      `baseline.${baselineIndex}.formulaTotal`,
      finalResult !== "--" ? finalResult : "NA"
    );
  return (
    <GridCard className={style.formWrapper}>
      <h4> {KPI.name} </h4>
      <div className={style.list}>
        {!!formulaVariablesArr.length &&
          formulaVariablesArr.map((variablesKey: any, i: number) => {
            let errorMsg: any = !!baseLineError?.formulaVariables
              ? baseLineError.formulaVariables[i]?.value?.message
              : "";

            return (
              <div className={`input-row ${style.inputRow}`} key={i}>
                <label>{formulaVariablesDetails[variablesKey]}</label>
                <input
                  className={`input-field ${style.inputBg}`}
                  type='number'
                  placeholder='0'
                  {...register(
                    `baseline.${baselineIndex}.formulaVariables.${i}.value`,
                    {
                      required: `Please provide the input.`,
                    }
                  )}
                />

                <p className={`text-danger ${style.error}`}>{errorMsg}</p>
                <input
                  type='text'
                  value={variablesKey}
                  {...register(
                    `baseline.${baselineIndex}.formulaVariables.${i}.name`
                  )}
                  hidden
                />
                <input
                  type='text'
                  value={formulaVariablesDetails[variablesKey]}
                  {...register(
                    `baseline.${baselineIndex}.formulaVariables.${i}.label`
                  )}
                  hidden
                />
              </div>
            );
          })}
      </div>

      <div className='d-flex'>
        {formulaError &&
          !!inputVariables?.length &&
          inputVariables.every((item: any) => !!item?.value) && (
            <div className='text-danger'>{formulaError}</div>
          )}
        <p className='ms-auto'>
          Total:{" "}
          <span data-total={evalResult}>
            {" "}
            {finalResult} {finalResult !== "--" && KPI.Metrics}
          </span>
        </p>
      </div>

      <input
        type='text'
        value={KPI.ids.join(",")}
        {...register(`baseline.${baselineIndex}.ids`)}
        hidden
      />
      <input
        type='text'
        value={KPI.keyProcesses.ids.join(",")}
        {...register(`baseline.${baselineIndex}.keyProcesses.ids`)}
        hidden
      />
      <input
        type='text'
        value={KPI.keyProcesses?.names.join(",")}
        {...register(`baseline.${baselineIndex}.keyProcesses.names`)}
        hidden
      />
      <input
        type='text'
        value={KPI.name}
        {...register(`baseline.${baselineIndex}.name`)}
        hidden
      />
      <input
        type='text'
        value={KPI.Metrics}
        {...register(`baseline.${baselineIndex}.Metrics`)}
        hidden
      />
      <input
        type='text'
        value={KPI.formula}
        {...register(`baseline.${baselineIndex}.formula`)}
        hidden
      />
    </GridCard>
  );
};
