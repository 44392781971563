import { useEffect, useMemo, useState } from "react";
import useQueryParams from "../../../common/hooks/useQueryParams";
import { toQueryString } from "../../../helper/utility";
import { fetchExpertsList } from "../../../services/expert";
import Button from "../../atoms/button";
import GridCard from "../../atoms/gridCard";
import GridContainer from "../../atoms/gridContainer";
import ExpertCard from "../../molecules/expertCard";
import ExpertFilter from "../../molecules/expertFilters";

import useDebounce from "../../../common/hooks/useDebounce";
import { UserIcon } from "../../atoms/icons";
import Loader from "../../atoms/loader";
import style from "./expertsListingContent.module.scss";

const limit = 9;

const ExpertsListingContent: React.FC<any> = () => {
  const [expertList, setExpertList] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [clearPrevData, setClearPrevData] = useState(true);
  const { params, setParams } = useQueryParams(
    "search",
    "industryIds",
    "lineOfBusinessIds",
    "locations",
    "ratingSort",
    "skip",
    "limit"
  );

  useEffect(() => {
    setParams({
      ...params,
      limit: limit,
      skip: 0,
      ...(!params?.ratingSort ? { ratingSort: "asc" } : {}),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowMore = () => {
    setClearPrevData(false);
    setParams({ ...params, skip: Number(params.skip) + limit });
  };

  // store memorize params values in string
  const paramsString = useMemo(() => {
    return `?${toQueryString(params)}`;
  }, [params]);

  // delay in memorize params values (paramsString).
  const debounceParamsString = useDebounce<string>(paramsString, 200);

  useEffect(() => {
    if (debounceParamsString !== "?") {
      setIsLoading(true);
      fetchExpertsList(debounceParamsString)?.then((respnse: any) => {
        const respnseData = respnse?.data || {};
        if (respnseData?.status === "success" && respnseData?.data?.smeUsers) {
          const smeData = respnseData.data?.smeUsers || [];
          const data = clearPrevData ? smeData : [...expertList, ...smeData];
          setExpertList(data);
          const dataLength = respnseData.data?.totalSMECount || 0;
          setTotalPage(Math.ceil(dataLength / 9) - 1);
          setClearPrevData(true);
          setIsLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceParamsString]);

  return (
    <GridContainer className={style.customContainer}>
      <div className="gridCol">
        <ExpertFilter queryParams={params} setQueryParams={setParams} />
      </div>
      <div className="gridCol">
        {!!expertList.length && (
          <GridContainer colSize={3}>
            {expertList.map((expert) => {
              return (
                <div className="gridCol" key={expert.id}>
                  <GridCard>
                    <ExpertCard {...expert} />
                  </GridCard>
                </div>
              );
            })}
          </GridContainer>
        )}
        {!expertList.length && (
          <GridContainer colSize={1}>
            {isLoading ? (
              <div className={style.loadingWrapper}>
                <Loader absolute={true} />
              </div>
            ) : (
              <GridCard>
                <div className={style.noSmeBox}>
                  <big className="noDataBigText">
                    <div>
                      <UserIcon />
                    </div>
                    <span>
                      Currently, no experts match the specified filters on the
                      platform. Kindly consider adjusting your filters or
                      exploring alternative criteria for a more refined search.
                    </span>
                  </big>
                </div>
              </GridCard>
            )}
          </GridContainer>
        )}
        {totalPage > Number(params.skip) && (
          <Button
            variant="transparent"
            className={`w-100 button ${style.buttonWrapper}`}
            onClick={() => {
              !isLoading && handleShowMore();
            }}
          >
            {isLoading ? "Loading..." : "Show More"}
          </Button>
        )}
      </div>
    </GridContainer>
  );
};
export default ExpertsListingContent;
