/* eslint-disable react/jsx-no-undef */
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Avatar, AvatarGroup, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProgramContext } from "../../../context/program.context";
import Button from "../../atoms/button";
import CustomStatus from "../../atoms/customStatus";
import DialogModal from "../../atoms/dialog";
import { PlusIcon, TrashIcon } from "../../atoms/icons";
import { AddCollborators, AddNewProgram, FinishProgram, RemoveProgram, StartProgram } from "./programModalBody";
import style from "./programView.module.scss";

type props = {};

const ProgramHeader: React.FC<props> = () => {
  const navigate = useNavigate();
  const { programList, selectedProgramDetail, programLoadingStatus, programId } = useProgramContext();
  const [openAddProgramModal, setOpenAddProgramModal] = useState(false);
  const [removeProgramModal, setRemoveProgramModal] = useState(false);
  const [startProgramModal, setStartProgramModal] = useState(false);
  const [finishProgramModal, setFinishProgramModal] = useState(false);
  const [collaboratorsModal, setcollaboratorsModal] = useState(false);
  const programStatus = selectedProgramDetail?.projectStatus;
  const collaborators = selectedProgramDetail?.collaborators || [];
  //remove button
  const handleRemove = async (e: any) => {
    setRemoveProgramModal(true);
  };

  const handleProgram = (e: any) => {
    const val = e?.target?.value;
    if (val === "addProgram") {
      setOpenAddProgramModal(true);
    } else {
      navigate(`/program/${val}`);
    }
  };
  const handleStartProgram = () => {
    setStartProgramModal(true);
  };

  const handleFinishProgram = () => {
    setFinishProgramModal(true);
  };

  const handleCollaborators = () => {
    setcollaboratorsModal(true);
  };

  return (
    <>
      <div className={style.subHeader}>
        <div className={style.headerLeft}>
          <div className={style.menuItems}>
            <div>
              <Select
                labelId="programSorting"
                id="programSorting"
                label="programSorting"
                className={`w-100 ${style.SortingSelect}`}
                value={programId}
                onChange={handleProgram}
                IconComponent={ExpandMore}
              >
                {programLoadingStatus === "failed" && (
                  <MenuItem hidden className={` ${style.menuOption}`} value={programId}>
                    Select program
                  </MenuItem>
                )}
                {programList.map((progam) => {
                  return (
                    <MenuItem key={progam.id} className={` ${style.menuOption}`} value={progam.id}>
                      {progam.projectName}
                    </MenuItem>
                  );
                })}
                <MenuItem className={style.menuOption} value={"addProgram"}>
                  <span className="pe-2">
                    <PlusIcon />
                  </span>
                  Add Program
                </MenuItem>
              </Select>
            </div>

            <div>
              {programStatus === "IN_SHELL" && <CustomStatus status={"pending"} message={"Not Started"} />}
              {programStatus === "TO_BE_STARTED" && <CustomStatus status={"pending"} message={"Not Started"} />}
              {programStatus === "IN_PROGRESS" && <CustomStatus status={"inProgress"} message={"In Progress"} />}
              {programStatus === "FINISHED" && <CustomStatus status={"approved"} message={"finished"} />}
            </div>
          </div>
        </div>

        <div className={style.headerRight}>
          {programLoadingStatus !== "failed" && (
            <ul className={style.menuItems}>
              <div>
                <AvatarGroup
                  onClick={handleCollaborators}
                  className={`${style.avatarGroupIcon} pointer`}
                  renderSurplus={(surplus) => <span className={style.addMoreIcon}>+{surplus.toString()[1]}</span>}
                  total={5}
                >
                  {collaborators.slice(0, 4).map((collaborator: any) => {
                    return (
                      <Avatar
                        key={collaborator.id}
                        className={style.avatarIcon}
                        title={collaborator.user.name}
                        alt={collaborator.user.name}
                        src={collaborator.user.avatarUrl || "/assets/images/userdummy.png"}
                      />
                    );
                  })}
                </AvatarGroup>
              </div>

              <div>
                <Button
                  variant="error"
                  className={`d-flex pointer justify-content-center align-items-center`}
                  onClick={handleRemove}
                >
                  <TrashIcon className={style.addIcon} />
                  <p style={{ color: "#D86561" }} className="p-0 ms-1">
                    Remove
                  </p>
                </Button>
              </div>
              {programStatus === "IN_SHELL" && (
                <div className="me-0">
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => {
                      navigate(`/program/setup/${programId}`);
                    }}
                  >
                    Set-up program
                  </Button>
                </div>
              )}

              {programStatus === "TO_BE_STARTED" && (
                <div className="me-0">
                  <Button variant="light" onClick={handleStartProgram}>
                    Start program
                  </Button>
                </div>
              )}

              {programStatus === "IN_PROGRESS" && (
                <div className="me-0">
                  <Button variant="light" onClick={handleFinishProgram}>
                    Finish program
                  </Button>
                </div>
              )}
            </ul>
          )}
        </div>
      </div>

      {/* add program modal */}
      <DialogModal openModal={openAddProgramModal} setOpenModal={setOpenAddProgramModal} heading={"Name new program"}>
        <AddNewProgram onClose={() => setOpenAddProgramModal(false)} />
      </DialogModal>

      {/* remove collaborators modal */}
      <DialogModal openModal={removeProgramModal} setOpenModal={setRemoveProgramModal} heading={"Remove Program"}>
        <RemoveProgram onClose={setRemoveProgramModal} />
      </DialogModal>

      {/* update program status modal */}
      <DialogModal openModal={startProgramModal} setOpenModal={setStartProgramModal} heading={"Start project"}>
        <StartProgram onClose={setStartProgramModal} />
      </DialogModal>

      <DialogModal openModal={finishProgramModal} setOpenModal={setFinishProgramModal} heading={"Finish project"}>
        <FinishProgram onClose={setFinishProgramModal} />
      </DialogModal>

      {/* profile modal */}
      <DialogModal openModal={collaboratorsModal} setOpenModal={setcollaboratorsModal} heading={"Collaborators"}>
        <AddCollborators onClose={setcollaboratorsModal} />
      </DialogModal>
    </>
  );
};

export default ProgramHeader;
