import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useAppDataContext } from "../../../context/appData.context";
import { FormValuesType } from "../../../pages/programSetup/formConfigerProgramme";
import GridCard from "../../atoms/gridCard";
import CheckBoxCard from "../../molecules/checkBoxCard";
import style from "./stepLineofBusiness.module.scss";

type props = {
  register?: UseFormRegister<FormValuesType>;
  errors?: FieldErrors<FormValuesType>;
  setValue?: UseFormSetValue<FormValuesType>;
  isFilter?: boolean;
};
const StepLineofBusiness: React.FC<props> = ({
  register,
  errors,
  setValue,
  isFilter = false,
}) => {
  const { LOBs } = useAppDataContext();
  const errorMsg = errors?.lineOfBusiness?.message || "";
  const registerObj: any = register
    ? register("lineOfBusiness", {
        required: "Please choose Line of Business.",
      })
    : {};

  const showingLoBs = `Supply Chain, Data Management, HR, Marketing, Sales, Aftermarket Service and Repair, Finance`;

  return (
    <GridCard>
      <div className={style.industryListBox}>
        <h4>Select your line of business </h4>
        {errorMsg && <p className='text-danger'>{errorMsg}</p>}
        <div className={style.list}>
          {LOBs.filter(
            (lob) => !isFilter || showingLoBs.includes(lob.name)
          ).map((lob) => {
            if (`${lob.name}`.toLowerCase() === "others") {
              return null;
            }
            return (
              <CheckBoxCard
                key={lob.id}
                label={lob.name}
                isCheckboxLeft={true}
                type='radio'
                inputProps={{
                  ...registerObj,
                  value: lob.id + "," + lob.name,
                  onChange: (e) => {
                    !!registerObj?.onChange && registerObj.onChange(e);
                    setValue && setValue("businessProblem", "");
                  },
                }}
              />
            );
          })}
        </div>
        {LOBs.length === 0 && (
          <big className={`noDataBigText`}>No data available</big>
        )}
      </div>
    </GridCard>
  );
};
export default StepLineofBusiness;
