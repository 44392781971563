import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function useQueryParams<T extends string[]>(...keys: T) {
  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchParams = new URLSearchParams(location.search);

  const params: { [K in T[number]]?: string | number } = useMemo(() => {
    const result: { [K in T[number]]?: string | number } = {};
    for (const key of keys) {
      const value = searchParams.get(key);
      if (value !== null) {
        // @ts-ignore
        result[key] = value;
      }
    }
    return result;
  }, [keys, searchParams]);

  const navigate = useNavigate();
  const setParams = useCallback(
    (newParams: {[key: string]: number | string}) => {
      const searchParams = new URLSearchParams(location.search);
      for (const key of keys) {
        if (key in newParams && newParams[key] !== undefined && newParams[key] !== "") {
          // @ts-ignore
          searchParams.set(key, newParams[key]!);
        } else {
          searchParams.delete(key);
        }
      }
      navigate(`?${searchParams.toString()}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [keys, location.search]
  );

  // Remove keys with empty string or undefined values
  for (const key in params) {
    // @ts-ignore
    if (params[key] === "" || params[key] === undefined) {
      // @ts-ignore
      delete params[key];
    }
  }
  
  return {
    params,
    setParams,
  };
}

export default useQueryParams;
