import { HTTP_METHODS } from "../common/constants";
import { getFileTypeFromUrl } from "../common/util";
import { makeNetworkCall } from "./network";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}customer`;

export const getProgramList = () => {
  let url = `${baseUrl}/project`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

type getTopSmeProps = {
  industry: string;
  lob: string;
  location: string
}
export const getTopSme = ({industry, lob, location}: getTopSmeProps) => {
  let url = `${baseUrl}/top-five-sme?${industry}&${lob}&${location}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

type addProgramProps = {
  projectName: string;
  customerProfileId: string;
};
export const addProgram = (body: addProgramProps) => {
  let url = `${baseUrl}/project`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const getProgramDetail = (id: string) => {
  let url = `${baseUrl}/project/${id}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const updateProgramDetail = (id: string, payload: any) => {
  let url = `${baseUrl}/project/${id}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, payload);
};

export const removeProgram = (id: string) => {
  let url = `${baseUrl}/project/${id}`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

export const fetchProviderList = (projectId: string) => {
  let url = `${baseUrl}/provider/${projectId}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

type providerProps = {
  providerName: string;
  projectId: string;
};
export const addProviderItem = (payload: providerProps) => {
  let url = `${baseUrl}/provider`;
  return makeNetworkCall(HTTP_METHODS.POST, url, payload);
};

export const removeProviderItem = (projectId: string) => {
  let url = `${baseUrl}/provider/${projectId}`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

export const fetchIntegratorList = (projectId: string) => {
  let url = `${baseUrl}/integrator/${projectId}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

type integratorProps = {
  integratorName: string;
  projectId: string;
};
export const addIntegratorItem = (payload: integratorProps) => {
  let url = `${baseUrl}/integrator`;
  return makeNetworkCall(HTTP_METHODS.POST, url, payload);
};

export const removeIntegratorItem = (projectId: string) => {
  let url = `${baseUrl}/integrator/${projectId}`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

type uploadDocumentProps = {
  fileName: string;
  documentName: string;
  projectId: string;
};
export const programDocumentUpload = (payload: uploadDocumentProps) => {
  let url = `${baseUrl}/upload-document`;
  const fileFormat = getFileTypeFromUrl(payload.fileName);
  const body = {
    type: fileFormat.fileType,
    ...payload,
  };
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};
type renameDocumentProps = {
  documentName: string;
};

export const programDocumentRename = (documentId: string, payload: renameDocumentProps) => {
  let url = `${baseUrl}/rename-document/${documentId}`;
  const body = {
    ...payload,
  };
  return makeNetworkCall(HTTP_METHODS.PATCH, url, body);
};

export const programDocumentDelete = (documentId: string) => {
  let url = `${baseUrl}/rename-document/${documentId}`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

export const programProcess = (processName: string) => {
  const url = `${baseUrl}/project/process/${processName}`;
  return makeNetworkCall(HTTP_METHODS.POST, url);
};

export type removeCollaboratorProps = {
  projectId: string;
  collaboratorId: string
}
export const removeCollaboratorFromprogram = ({
  projectId,
  collaboratorId
}: removeCollaboratorProps) => {
  const url = `${baseUrl}/collaborator/${projectId}/${collaboratorId}`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

type removeAviaPlanProps = {
  projectId: string;
  aviaPlanId: string;
}
export const removeAviaPlanFromprogram = ({
  projectId,
  aviaPlanId
}: removeAviaPlanProps) => {
  const url = `${baseUrl}/aivaplan/${projectId}/${aviaPlanId}`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};


