export const logConsole = (value1: any, value2: any): void => {};

export const storeInLocal = (key: string, value: any) => {
  localStorage.setItem(key, value);
};

export const getLocalData = (key: string) => {
  return JSON.parse(localStorage?.getItem(key) ?? "{}");
};

// toast

export const getDay = (date: any) => {
  const day = new Date(date).getDate();
  return "" + day;
};

export const getMonth = (date: any) => {
  const month = new Date(date).getMonth() + 1;
  return "" + month;
};

export const getMonthName = (date: any, short: boolean) => {
  const monthShortNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const monthFullNames = [
    "january",
    "feburary",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "sepetember",
    "october",
    "november",
    "december",
  ];
  const month = new Date(date).getMonth();
  if (short) {
    const monthName = monthShortNames[month];
    return monthName;
  }
  const monthName = monthFullNames[month];
  return monthName;
};

export const getYear = (date: any) => {
  const year = new Date(date).getFullYear();
  return "" + year;
};

export const capitalize = (str: string) => {
  return str && str[0].toUpperCase() + str.slice(1);
};

export const isUrlValid = (str: string, type: string) => {
  switch (type.toLowerCase()) {
    case "linkedin": {
      const linkedIn =
        /^(https?:\/\/)?(\w+\.)?linkedin\.com\/(pub|in|profile)\/([a-zA-Z0-9]+)\/?/;
      return linkedIn.test(str);
    }

    case "youtube": {
      const youtube = /^(https?:\/\/)?(www\.)?youtu(be|\.be)\.com\/.+/;
      return youtube.test(str);
    }
    case "twitter": {
      const twitter = /^https?:\/\/(?:www\.)?twitter\.com\/\w+/;
      return twitter.test(str);
    }
    case "medium": {
      const medium = /^(?:https?:)?\/\/medium\.com\/@([-a-zA-Z0-9]+)\/?/;
      return medium.test(str);
    }
    case "substack": {
      const substack =
        /^(https?:\/\/)?(\w+\.)?substack\.com\/([-a-zA-Z0-9_@./]+)\/?/;
      return substack.test(str);
    }
    case "social": {
      const pattern = new RegExp(
        //eslint-disable-next-line
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i" // fragment locator
      );
      return pattern.test(str);
    }
    default: {
      return false;
    }
  }
};

export const yearValidation = (year: any, ev: any) => {
  const text = /^\d+$/;
  if (year !== 0) {
    if (year !== "" && !text.test(year)) {
      return { status: false, message: "Please Enter Numeric Values Only" };
    }
    if (year.length !== 4) {
      return { status: false, message: "Year is not proper. Please check" };
    }
    const current_year = new Date().getFullYear();
    if (year < 1920 || year > current_year) {
      return {
        status: false,
        message: "Year should be in range 1920 to current year",
      };
    }
    return { status: true, message: "" };
  }
};

export const dobMatch = (date: string) => {
  if (new Date(date) > new Date()) {
    return { message: "DOB is not greater then today date", status: false };
  }
  let operator: any = date.split("-");
  let datepart: any = [];
  if (operator.length > 1) {
    datepart = date.split("-");
  }
  let year = parseInt(datepart[0]);
  let month = parseInt(datepart[1]);
  let day = parseInt(datepart[2]);
  let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (month === 1 || month > 2) {
    if (day > ListofDays[month - 1]) {
      return { message: "Please use proper date", status: false };
    }
  } else if (month === 2) {
    let leapYear = false;
    if ((!(year % 4) && year % 100) || !(year % 400)) leapYear = true;
    if (leapYear === false && day >= 29)
      return { message: "Please change month or date", status: false };
    else if (leapYear === true && day > 29) {
      return { message: "Please change month or date", status: false };
    }
  }
  return { message: "", status: true };
};

export const printDiv = (divName: any, headValue?: any) => {
  let printContents: any = document.getElementById(divName)?.innerHTML;
  if (headValue) {
    printContents = `<h3 style="color:black">${headValue}</h3>` + printContents;
  }
  return { content: printContents, printStat: true };
};

export const filterValue = (
  array: Array<number | string | {} | any>,
  matchedVal: string | number | {}
) => {
  let filterArray = array.filter(
    (value: number | string | {} | any, index: number) => {
      if (typeof value === "object") {
        if (value?.name === matchedVal || value?.abbr === matchedVal) {
          return value;
        }
      } else {
        if (value === matchedVal) {
          return matchedVal;
        }
      }
    }
  );
  return filterArray;
};

export function getFileTypeFromUrl(url: string): {
  extension: string;
  fileType: string;
} {
  const imageExtensions = [
    "jpeg",
    "jpg",
    "png",
    "gif",
    "bmp",
    "webp",
    "tiff",
    "ico",
    "svg",
  ]; // Add more if needed
  const videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv", "flv"]; // Add more if needed
  const audioExtensions = ["mp3", "wav", "ogg", "aac"]; // Add more if needed

  const match = url.match(/\.([a-zA-Z0-9]+)(\?.*)?$/);
  const fileExtension = match ? match[1].toLowerCase() : "";
  let fileType = match ? match[1].toLowerCase() : "";

  if (fileExtension && imageExtensions.includes(fileExtension)) {
    fileType = "IMAGE";
  } else if (fileExtension && videoExtensions.includes(fileExtension)) {
    fileType = "VIDEO";
  } else if (fileExtension && audioExtensions.includes(fileExtension)) {
    fileType = "AUDIO";
  } else {
    fileType = "DOCUMENT";
  }

  return {
    extension: fileExtension,
    fileType: fileType,
  };
}
export const handleScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export function jsonParser(jsonString: string) {
  try {
    // Replace single quotes with double quotes
    const fixedJsonString = jsonString.replace(/'/g, '"');

    // Try parsing the fixed JSON string
    const parsedData = JSON.parse(fixedJsonString);

    return parsedData || {}; // Return parsed data if not null or undefined, otherwise return an empty object
  } catch (error) {
    console.error("Error parsing JSON string:", error);
    console.error("JSON String:", jsonString);
    return {};
  }
}

export function evaluateExpression(expression: string) {
  // Preprocess the formula
  const processedExpression = expression
    .replace(/x/g, "*") // Replace 'x' with '*'
    .replace(/Σ/g, "") // Remove 'Σ'
    .replace(/\|([^|]+)\|/g, "Math.abs($1)") // Replace '|A-B|' with 'Math.abs(A-B)'
    .replace(/(\d)([A-Za-z(])/, "$1*$2") // Add multiplication operator between number and function/(
    .replace(/\s+/g, "") // Remove unnecessary spaces
    .replace(/(\b)0+(\d+(\.\d+)?)/g, "$1$2"); // Remove leading zeros from integers and decimals

  console.log("Processed Expression:", processedExpression);

  try {
    const result = eval(processedExpression);
    console.log("Result:", result);
    return result;
  } catch (error: any) {
    console.error("Error evaluating formula:", error.message);
    console.error("Error evaluating processedExpression:", processedExpression);
    return NaN;
  }
}
