import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router";
import { jsonParser } from "../../common/util";
import { getPlans } from "../../services/aivaPlan";
import { getKPI } from "../../services/authForms";
import {
  addIntegratorItem,
  addProviderItem,
  fetchIntegratorList,
  fetchProviderList,
  getProgramDetail,
  getProgramList,
  getTopSme,
  removeAviaPlanFromprogram,
  removeCollaboratorFromprogram,
  removeIntegratorItem,
  removeProgram,
  removeProviderItem,
  updateProgramDetail,
} from "../../services/program";
import { useAppDataContext } from "../appData.context";
import {
  ProgramDataState,
  ProviderProps,
  apiStatusProp,
  fetchProgramDetailsProps,
  programDetailProps,
  programListProps,
  programPayloadProps,
  successAndErrorFunProps,
} from "./type";

const CustomerProfileContext = createContext<ProgramDataState | undefined>(
  undefined
);
type onResponseProps = (d: any) => void;
// hook for getting context state and function
export const useProgramContext = (): ProgramDataState => {
  const context = useContext(CustomerProfileContext);
  if (!context) {
    throw new Error("CustomerProfile must be used within a ProgramProvider");
  }
  return context;
};

// Context Provide
const ProgramProvider = ({ children }: ProviderProps) => {
  const navigate = useNavigate();
  const { userData } = useAppDataContext();
  const { programId = "" } = useParams();
  const [programList, changeProgramList] = useState<programListProps[]>([]);
  const [selectedProgramDetail, setSelectProgramDetail] =
    useState<programDetailProps>({});
  const [providerList, setProviderList] = useState<any[]>([]);
  const [integretorList, setIntegretorList] = useState<any[]>([]);
  const [kpiRecommendation, setKpiRecommendation] = useState<any[]>([]);
  const [aivaPlanList, setAivaPlanList] = useState<any[]>([]);
  const [programLoadingStatus, setProgramLoadingStatus] =
    useState<apiStatusProp>("idle");
  const [topSme, setTopSme] = useState<any>([]);

  // constant variable
  const customerProfileId = userData?.userProfile?.customerProfile?.id || "";
  const industryName = selectedProgramDetail?.industry?.name || "";
  const lobName = selectedProgramDetail?.lineOfBusiness?.name || "";
  const userlocation = userData?.userProfile?.customerProfile?.location || "";

  const getAivaPlan = useCallback(() => {
    getPlans()?.then((res) => {
      const resData = res?.data || {};
      if (resData.status === "success") {
        setAivaPlanList(resData?.data?.aivaPlans || []);
      }
    });
  }, []);

  const fetchKpiRecommendation = useCallback(() => {
    const selctedkeyProcess = selectedProgramDetail?.keyProcess;
    if (selctedkeyProcess?.length > 0) {
      const selctedkeyProcessIds = selctedkeyProcess.map((kp: any) => kp.id);
      getKPI(selctedkeyProcessIds)?.then((res) => {
        const resData = res?.data || {};
        if (resData.status === "success") {
          const data = resData.data;
          const result = Array.from(
            new Set(
              Object.values(data)
                .flat()
                .map((item) => JSON.stringify(item))
            )
          ).map((item) => {
            const obj = JSON.parse(item);
            obj.formulaVariables = jsonParser(obj.formulaVariables);
            return obj;
          });

          const modifiedResult = result.map((item) => {
            const matches = result.filter((i) => i.name === item.name);
            item.id = matches.map((i) => i.id);
            item.keyProcessId = matches.map((i) => i.keyProcessId);
            return item;
          });
          setKpiRecommendation(modifiedResult);
        }
      });
    }
  }, [selectedProgramDetail?.keyProcess]);

  // program details
  const fetchProgramList = useCallback(() => {
    getProgramList()?.then((res: any) => {
      const resData = res?.data;
      if (resData?.status === "success") {
        const projects = resData?.data?.projects || [];
        changeProgramList(projects);
        !programId && navigate(projects[0]?.id);
        if (!projects?.length) {
          setProgramLoadingStatus("failed");
        }
      }
    });
  }, [navigate, programId]);

  const fetchProgramDetails = useCallback(
    ({ loading = true, onResponse }: fetchProgramDetailsProps = {}) => {
      if (programId) {
        loading && setProgramLoadingStatus("pending");
        getProgramDetail(programId)?.then((res: any) => {
          const resData = res?.data;
          if (resData?.status === "success") {
            const projectDetail = resData.data.projectDetail;
            setSelectProgramDetail(projectDetail);
            loading && setProgramLoadingStatus("success");
          } else {
            loading && setProgramLoadingStatus("failed");
          }
          onResponse && onResponse(resData);
        });
      }
    },
    [programId]
  );

  const addProgramItem = useCallback(
    (newProgramData: programListProps) => {
      const newProgramList = [newProgramData, ...programList];
      changeProgramList(newProgramList);
      navigate(`/program/${newProgramData.id}`);
    },
    [navigate, programList]
  );

  const removeProgramItem = useCallback(
    ({ onSuccess, onError }: successAndErrorFunProps) => {
      removeProgram(programId)?.then((res: any) => {
        const resData: any = res?.data || {};
        if (resData?.status === "success") {
          const newProgramList = programList.filter((program) => {
            return program.id !== programId;
          });
          changeProgramList(newProgramList);
          if (!!newProgramList.length) {
            navigate(`/program/${newProgramList[0].id}`);
          } else {
            navigate(`/program`);
          }
          onSuccess && onSuccess(resData.data);
        } else {
          onError && onError(resData);
        }
      });
    },
    [programId, programList, navigate]
  );

  const updateProgram = useCallback(
    (payload: programPayloadProps, onResponse?: (a?: any) => void) => {
      let customerProfileIds =
        payload?.customerProfileId ||
        selectedProgramDetail?.collaborators?.map(
          (collaborator: any) => collaborator.id
        );
      const customerProfileIdArr = [
        ...new Set([customerProfileId, ...customerProfileIds]),
      ];
      const body: any = {
        projectName: selectedProgramDetail.projectName,
        customerProfileId: customerProfileIdArr,
        projectStatus: selectedProgramDetail.projectStatus,
        ...payload,
      };

      updateProgramDetail(programId, body)?.then((res) => {
        const resData = res?.data || {};
        if (resData?.status === "success") {
          const newProgramData = resData.data.projectDetail || {};
          setSelectProgramDetail((currentDetails: any) => ({
            ...currentDetails,
            ...newProgramData,
          }));
        }
        onResponse && onResponse(resData);
      });
    },
    [customerProfileId, selectedProgramDetail, programId]
  );

  const fetchProviders = useCallback(() => {
    fetchProviderList(programId)?.then((res) => {
      const resData = res?.data || {};
      if (resData.status === "success") {
        const list = resData.data.providerList;
        setProviderList(list);
      } else {
      }
    });
  }, [programId]);

  const addProvider = (name: string, onResponse?: onResponseProps) => {
    addProviderItem({
      providerName: name,
      projectId: programId,
    })?.then((res) => {
      const resData = res?.data || {};
      if (resData.status === "success") {
        const resProviderDetail: any = resData.data.providerDetail || {};
        const newProviderList: any[] = [...providerList, resProviderDetail];
        setProviderList(newProviderList);
      }
      onResponse && onResponse(resData);
    });
  };

  const removeProvider = (id: string, onResponse?: onResponseProps) => {
    removeProviderItem(id)?.then((res) => {
      const resData = res?.data || {};
      if (resData?.status === "success") {
        const newProviderList: any[] = providerList.filter(
          (item: any) => item.id !== id
        );
        setProviderList(newProviderList);
      } else {
      }
      onResponse && onResponse(resData);
    });
  };

  const fetchIntegrators = useCallback(() => {
    fetchIntegratorList(programId)?.then((res) => {
      const resData = res?.data || {};
      if (resData?.status === "success") {
        const list = resData.data.integratorList;
        setIntegretorList(list);
      } else {
      }
    });
  }, [programId]);

  const addIntegrator = (name: string, onResponse?: onResponseProps) => {
    addIntegratorItem({
      integratorName: name,
      projectId: programId,
    })?.then((res: any) => {
      const resData = res?.data || {};
      if (resData?.status === "success") {
        const integratorDetail = resData.data.providerDetail || {};
        const newIntegraterList = [...integretorList, integratorDetail];
        setIntegretorList(newIntegraterList);
      } else {
      }
      onResponse && onResponse(resData);
    });
  };

  const removeIntegrator = (id: string, onResponse?: onResponseProps) => {
    removeIntegratorItem(id)?.then((res) => {
      const resData = res?.data || {};
      if (resData?.status === "success") {
        const newIntegratorList: any[] = integretorList.filter(
          (item: any) => item.id !== id
        );
        setIntegretorList(newIntegratorList);
      } else {
      }
      onResponse && onResponse(resData);
    });
  };

  const removeCollaborator = (
    collaboratorId: string,
    onResponse?: onResponseProps
  ) => {
    removeCollaboratorFromprogram({
      projectId: programId,
      collaboratorId: collaboratorId,
    })?.then((res) => {
      const resData = res?.data || {};
      if (resData.status === "success") {
        const filterCollaborators = selectedProgramDetail.collaborators.filter(
          (collaborator: any) => collaborator.id !== collaboratorId
        );
        const newProjectData = {
          ...selectedProgramDetail,
          collaborators: filterCollaborators,
        };
        setSelectProgramDetail(newProjectData);
      }
      onResponse && onResponse(resData);
    });
  };

  const removeAviaPlan = (aviaPlanId: string, onResponse?: onResponseProps) => {
    removeAviaPlanFromprogram({
      projectId: programId,
      aviaPlanId: aviaPlanId,
    })?.then((res: any) => {
      const resData = res?.data || {};
      if (resData?.status === "success") {
        const filterAivaPlans = selectedProgramDetail.aivaPlan.filter(
          (aiva: any) => aiva.id !== aviaPlanId
        );
        const newProjectData = {
          ...selectedProgramDetail,
          aivaPlan: filterAivaPlans,
        };
        setSelectProgramDetail(newProjectData);
      }
      onResponse && onResponse(resData);
    });
  };

  const fetchTopSme = useCallback(() => {
    getTopSme({
      location: userlocation,
      industry: industryName,
      lob: lobName,
    })?.then((res: any) => {
      setTopSme(res?.data?.data?.smeData || []);
    });
  }, [industryName, lobName, userlocation]);

  useEffect(() => {
    fetchProgramList();
    getAivaPlan();
  }, [fetchProgramList, getAivaPlan]);

  useEffect(() => {
    fetchTopSme();
  }, [fetchTopSme]);

  useEffect(() => {
    if (programLoadingStatus === "success") {
      fetchKpiRecommendation();
    }
  }, [fetchKpiRecommendation, programLoadingStatus]);

  useEffect(() => {
    fetchProgramDetails();
  }, [fetchProgramDetails]);

  useEffect(() => {
    if (!!programId) {
      fetchProviders();
      fetchIntegrators();
    }
  }, [fetchProviders, fetchIntegrators, programList, programId]);
  return (
    <CustomerProfileContext.Provider
      value={{
        programList,
        programId,
        programLoadingStatus,
        selectedProgramDetail,
        providerList,
        integretorList,
        kpiRecommendation,
        aivaPlanList,
        topSme,
        fetchProgramDetails,
        addProgramItem,
        updateProgram,
        removeProgramItem,
        addProvider,
        addIntegrator,
        removeIntegrator,
        removeProvider,
        removeCollaborator,
        removeAviaPlan,
      }}
    >
      {children}
    </CustomerProfileContext.Provider>
  );
};

export default ProgramProvider;
