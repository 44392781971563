import { ListItemButton, Tooltip } from "@mui/material";
import { InputHTMLAttributes, ReactNode, memo } from "react";
import CheckBoxCustom from "../../atoms/checkBoxCustom";
import style from "./checkBoxCard.module.scss";

export type CheckBoxCardProps = {
  label: string;
  icon?: ReactNode;
  imgSrc?: string;
  isCheckboxLeft?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  type?: "radio" | "checkbox";
  disabled?: boolean;
  title?: string;
};

const CheckBoxCard: React.FC<CheckBoxCardProps> = ({
  label,
  icon,
  imgSrc,
  isCheckboxLeft,
  inputProps,
  type = "checkbox",
  disabled = false,
  title = "",
}) => {
  return (
    <Tooltip
      title={title}
      PopperProps={{
        className: style.checkBoxTooltip,
      }}
    >
      <ListItemButton className="checkBoxCard" disabled={disabled}>
        <label className={style.checkBoxLabel}>
          <div className={style.mainWrapper}>
            {imgSrc && <img className={style.contentImg} src={imgSrc} alt="images" />}
            <div className={`content ${style.CardWrapper} ${isCheckboxLeft ? `${style.isLeft}` : ""}`}>
              <div className={style.contentWrapper}>
                {icon && <span className={style.contentIcon}>{icon}</span>}
                <span className={`${style.contentText}`}> {label} </span>
              </div>
              <div className={`input-row mb-0 ${isCheckboxLeft ? "me-2" : "ms-2"} ${style.checkBoxWrapper}`}>
                <CheckBoxCustom {...(!!inputProps ? inputProps : {})} type={type} isLabelComponent={false} />
              </div>
            </div>
          </div>
        </label>
      </ListItemButton>
    </Tooltip>
  );
};
export default memo(CheckBoxCard);
