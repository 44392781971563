export default function IdeaIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <g clipPath="url(#clip0_8265_1604)">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.8174 0.00121071C12.0922 -0.0234155 13.3503 0.293625 14.4612 0.919418C15.572 1.54521 16.4952 2.45694 17.1347 3.55996C17.774 4.66266 18.1067 5.91635 18.0981 7.19095C18.0924 8.5139 17.7179 9.80906 17.0167 10.9309C16.3954 11.9251 15.5393 12.7483 14.5266 13.3298V15.0443C14.5266 15.4232 14.3761 15.7865 14.1082 16.0544C13.8403 16.3223 13.4769 16.4728 13.0981 16.4728H8.81235C8.43346 16.4728 8.0701 16.3223 7.8022 16.0544C7.53429 15.7865 7.38378 15.4232 7.38378 15.0443V13.3285C6.41348 12.7683 5.58664 11.9865 4.97233 11.0445C4.27586 9.97651 3.87795 8.74158 3.81991 7.46789C3.76187 6.1942 4.04581 4.92819 4.64226 3.80129C5.23872 2.6744 6.12593 1.72769 7.21181 1.05946C8.29768 0.391232 9.54262 0.0258369 10.8174 0.00121071ZM7.38378 19.2857C7.38378 18.8912 7.70357 18.5714 8.09806 18.5714H13.8123C14.2068 18.5714 14.5266 18.8912 14.5266 19.2857C14.5266 19.6802 14.2068 20 13.8123 20H8.09806C7.70357 20 7.38378 19.6802 7.38378 19.2857Z" fill="#E0DFE3"/>
    </g>
    <defs>
      <clipPath id="clip0_8265_1604">
        <rect width="20" height="20" fill="white" transform="translate(0.955078)"/>
      </clipPath>
    </defs>
  </svg>
  );
}