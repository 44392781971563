export default function ZoomCallIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.9153 7.5703L19.322 4.34996C19.7068 4.0364 20 4.10928 20 4.68895V14.5093C20 15.1601 19.6339 15.0856 19.322 14.8483L14.9153 11.6279V7.5703ZM0 4.71267V12.0347C0.00403607 12.8296 0.323572 13.5904 0.888359 14.1498C1.45315 14.7092 2.21695 15.0214 3.01186 15.0178H13.6898C13.8343 15.0182 13.973 14.9614 14.0756 14.8597C14.1783 14.758 14.2364 14.6198 14.2373 14.4754V7.15335C14.2333 6.35844 13.9137 5.59767 13.3489 5.03829C12.7841 4.47891 12.0203 4.1667 11.2254 4.1703H0.547458C0.403004 4.16985 0.264264 4.22669 0.161644 4.32835C0.0590244 4.43002 0.000896524 4.56822 0 4.71267Z"
        fill="#FDFCFF"
      />
    </svg>
  );
}
