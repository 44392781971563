import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/atoms/button";
import Loader from "../../components/atoms/loader";
import { useAppDataContext } from "../../context/appData.context";
import { retrieveToken } from "../../services/authForms";

const Authentication = () => {
  let url = new URL(window?.location as any);
  const navigate = useNavigate();
  const { setProfile } = useAppDataContext();
  const [authError, setAuthError] = useState("");
  let tmpToken: string = url.searchParams.get("token") as string;

  const handleCB = async (tmpToken: string) => {
    const tokenResponse = await retrieveToken(tmpToken);
    if (tokenResponse?.status === 200) {
      const responseUser = tokenResponse?.data?.data?.user;
      window.localStorage.setItem("accessToken", tokenResponse?.data?.data?.accessToken);
      window.localStorage.setItem("refreshToken", tokenResponse?.data?.data?.refreshToken);
      window.localStorage.setItem("storedProfileData", JSON.stringify(responseUser));
      setProfile(responseUser);
      if (responseUser?.customerProfile?.customerSubscription?.subscriptionPlanId) {
        navigate("/aiva");
      } else {
        navigate("/subscriptionPlan");
      }
    } else {
      setAuthError(tokenResponse?.data?.exception?.error?.message);
    }
  };

  useEffect(() => {
    if (!!tmpToken) {
      handleCB(tmpToken);
    }
  }, []);

  return (
    <>
      {authError === "" && <Loader />}
      {authError !== "" && (
        <div style={{ margin: "15vh auto", textAlign: "center" }}>
          <img src="./assets/images/brokenLink.png" alt="" />

          <h1 style={{ margin: "3rem 0" }}>{authError} . . .</h1>

          <Button type="link" href="/login">
            Back to login
          </Button>
        </div>
      )}
    </>
  );
};

export default React.memo(Authentication);
