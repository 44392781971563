import { Delete } from "@mui/icons-material";
import {
  AlertColor,
  Autocomplete,
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { evaluateExpression } from "../../../common/util";
import { useAppDataContext } from "../../../context/appData.context";
import { useProgramContext } from "../../../context/program.context";
import { updateFile } from "../../../services/authForms";
import {
  addProgram,
  programDocumentDelete,
  programDocumentRename,
  programDocumentUpload,
} from "../../../services/program";
import CustomAlert from "../../atoms/alert/CustomAlert";
import Button from "../../atoms/button";
import CheckBoxCustom from "../../atoms/checkBoxCustom";
import { InboxIcon } from "../../atoms/icons";
import UploadDocument from "../../molecules/uploadDocument";
import style from "./programView.module.scss";

type AlertProps = {
  show: boolean;
  state: AlertColor;
  message: string;
};
const alertDefaultState: AlertProps = {
  show: false,
  state: "success",
  message: "",
};

type addProgramprops = {
  onClose?: (b?: boolean) => void;
};
export const AddNewProgram: React.FC<addProgramprops> = ({ onClose }) => {
  const [newProgramName, setNewProgramName] = useState("");
  const { customerProfile } = useAppDataContext();
  const { addProgramItem } = useProgramContext();
  const profileId = customerProfile?.customerProfile?.id;
  const [alert, SetAlert] = useState(alertDefaultState);

  const createProgram = () => {
    addProgram({
      projectName: newProgramName,
      customerProfileId: profileId,
    })?.then((res) => {
      const resData = res?.data || {};
      if (resData?.status === "success") {
        const newProject = resData.data.projectDetail;
        const newProjectListItem = {
          id: newProject.id,
          projectName: newProject.projectName,
          projectStatus: newProject.projectStatus,
        };
        addProgramItem(newProjectListItem);
        setNewProgramName("");
        SetAlert({
          show: true,
          state: "success",
          message: "New program added successfully.",
        });
      } else {
        SetAlert({
          show: true,
          state: "error",
          message:
            resData?.exception?.error?.message || "Something went wrong!",
        });
      }
    });
  };

  return (
    <>
      <div className={style.inputSection}>
        <input
          type='text'
          value={newProgramName}
          onChange={(e) => setNewProgramName(e.target.value)}
          className='input-field'
          placeholder='Program name'
        />
        <Button
          className={`w-100 mt-4`}
          variant={!newProgramName ? "disabled" : "light"}
          onClick={createProgram}
        >
          Save
        </Button>
      </div>

      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          onClose && onClose(false);
        }}
        timeDelay={2000}
      />
    </>
  );
};

type removeProgramprops = { onClose: (a: boolean) => void };
export const RemoveProgram: React.FC<removeProgramprops> = ({ onClose }) => {
  const [alert, SetAlert] = useState(alertDefaultState);
  const { selectedProgramDetail, removeProgramItem } = useProgramContext();
  const [loading, setLoading] = useState(false);
  const handleRemoveProgram = () => {
    setLoading(true);
    removeProgramItem({
      onSuccess: (data: any) => {
        SetAlert({
          show: true,
          state: "success",
          message: `${selectedProgramDetail.projectName} is removed successfully`,
        });
      },
      onError: (error: any) => {
        SetAlert({
          show: true,
          state: "error",
          message: "Something went Wrong!",
        });
      },
    });
  };

  return (
    <div className={style.inputSection}>
      <p className='font14'>
        Do you want to remove the {selectedProgramDetail.projectName}.
      </p>
      <div className={style.buttonWrapper}>
        <Button
          className='w-100 mt-4'
          variant={loading ? "disabled" : "alert"}
          onClick={handleRemoveProgram}
        >
          Remove
        </Button>
      </div>

      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          onClose && onClose(false);
        }}
        timeDelay={1000}
      />
    </div>
  );
};

type startProgram = any;
export const StartProgram: React.FC<startProgram> = ({ onClose }) => {
  const { selectedProgramDetail, updateProgram } = useProgramContext();
  const programStatus = selectedProgramDetail.projectStatus;
  const [alert, SetAlert] = useState(alertDefaultState);
  const handleStartProgram = () => {
    const onResponse = (res: any) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: `${selectedProgramDetail.projectName} is Started now.  `,
        });
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: "Something went Wrong!",
        });
      }
    };

    updateProgram(
      {
        projectStatus: "IN_PROGRESS",
      },
      onResponse
    );
  };

  return (
    <div className={style.inputSection}>
      <p className='font14'>
        Do you want to start the {selectedProgramDetail.projectName}.
      </p>

      <Button
        className='w-100 mt-4'
        variant='light'
        onClick={handleStartProgram}
      >
        Start
      </Button>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          onClose && onClose(false);
        }}
        timeDelay={1000}
      />
    </div>
  );
};

type finishProgram = any;
export const FinishProgram: React.FC<finishProgram> = ({ onClose }) => {
  const { selectedProgramDetail, updateProgram } = useProgramContext();
  const programStatus = selectedProgramDetail.projectStatus;
  const [alert, SetAlert] = useState(alertDefaultState);
  const handleStartProgram = () => {
    const onResponse = (res: any) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: `${selectedProgramDetail.projectName} is Finished.`,
        });
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: "Something went Wrong!",
        });
      }
    };

    updateProgram({ projectStatus: "FINISHED" }, onResponse);
  };

  return (
    <div className={style.inputSection}>
      <p className='font14'>
        Do you want to finish the {selectedProgramDetail.projectName}.
      </p>
      <Button
        className='w-100 mt-4'
        variant='light'
        onClick={handleStartProgram}
      >
        Finish
      </Button>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          onClose && onClose(false);
        }}
        timeDelay={1000}
      />
    </div>
  );
};

type uploadDocumentprops = {
  onClose?: () => void;
};
export const AddNewDocument: React.FC<uploadDocumentprops> = ({ onClose }) => {
  const { programId, fetchProgramDetails } = useProgramContext();
  const [documentName, setDocumentName] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [alert, SetAlert] = useState(alertDefaultState);
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadDoc = async () => {
    setIsLoading(true);
    if (!!file && !!documentName) {
      const payload = {
        fileName: file.name,
        documentName: documentName,
        projectId: programId,
      };
      const response = await programDocumentUpload(payload);
      if (response?.data?.status === "success") {
        const resData = response.data.data;
        fetchProgramDetails({ loading: false });
        const res = await updateFile(resData.presignedUrl, {
          file: file,
        });

        if (res?.status === 200) {
          SetAlert({
            show: true,
            state: "success",
            message: "Document upload successfully.",
          });
          setFile(null);
        } else {
          SetAlert({
            show: true,
            state: "error",
            message: "Error while uploading.",
          });
        }
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: "Something went wrong.",
        });
      }
      setIsLoading(false);
    }
  };
  return (
    <div className={`${style.inputSection} h-100`}>
      <input
        type='text'
        value={documentName}
        onChange={(e) => setDocumentName(e.target.value)}
        className='input-field'
        placeholder='Document name'
        disabled={isLoading}
      />
      <UploadDocument
        onChange={(f) => {
          setFile(f);
        }}
        instructions={
          <>
            <div>Allowed formats: PDF, DOC, TXT, MP4, MP3.</div>
            <div>Files cannot exceed 1GB in size.</div>
          </>
        }
        allowedFileTypes='.pdf,.docx,.doc,.mp3,.mp4,.txt'
        disabled={isLoading}
      />

      <Button
        className='w-100 mt-4'
        variant={!!file && !!documentName && !isLoading ? `light` : "disabled"}
        onClick={handleUploadDoc}
      >
        {isLoading ? "uploading..." : "Add"}
      </Button>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.state === "success" && onClose && onClose();
        }}
        timeDelay={3000}
      />
    </div>
  );
};

type renameDocumentprops = {
  documentInfo: any;
  onClose?: () => void;
};
export const RenameDocument: React.FC<renameDocumentprops> = ({
  documentInfo = {},
  onClose,
}) => {
  const defaultDocName =
    documentInfo?.documentName || documentInfo?.fileName || "";
  const { fetchProgramDetails } = useProgramContext();
  const [inputDocName, setInputDocName] = useState(defaultDocName);
  const [alert, SetAlert] = useState(alertDefaultState);

  const isButtonDisable = !inputDocName || inputDocName === defaultDocName;

  const handleRename = async (e: any) => {
    const payload = {
      documentName: inputDocName,
    };
    documentInfo?.id &&
      programDocumentRename(documentInfo?.id, payload)?.then((res) => {
        const resData = res?.data || {};
        if (resData.status === "success") {
          fetchProgramDetails({ loading: false });
          SetAlert({
            show: true,
            state: "success",
            message: "Document rename successfully.",
          });
        } else {
          SetAlert({
            show: true,
            state: "error",
            message: "Something went wrong.",
          });
        }
      });
  };

  return (
    <div className={style.inputSection}>
      <input
        type='text'
        value={inputDocName}
        onChange={(e) => setInputDocName(e.target.value)}
        className='input-field'
        placeholder='Document name'
      />

      <div className={style.buttonWrapper}>
        <Button
          className='w-100 mt-4'
          variant={isButtonDisable ? "disabled" : "light"}
          onClick={handleRename}
        >
          Rename
        </Button>
      </div>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.state === "success" && onClose && onClose();
        }}
        timeDelay={2000}
      />
    </div>
  );
};

type removeDocumentprops = {
  documentInfo: any;
  onCancel?: () => void;
};
export const RemoveDocument: React.FC<removeDocumentprops> = ({
  documentInfo = {},
  onCancel = () => {},
}) => {
  const { removeAviaPlan, fetchProgramDetails } = useProgramContext();
  const [alert, SetAlert] = useState(alertDefaultState);

  const onResponse = (resData: any) => {
    if (resData?.status === "success") {
      fetchProgramDetails({ loading: false });
      SetAlert({
        show: true,
        state: "success",
        message: "Document remove successfully.",
      });
    } else {
      SetAlert({
        show: true,
        state: "error",
        message: "Something went wrong.",
      });
    }
  };

  const handleRemove = async (e: any) => {
    if (documentInfo?.id) {
      if (documentInfo?.name) {
        removeAviaPlan(documentInfo.id, onResponse);
      } else {
        programDocumentDelete(documentInfo.id)?.then((res) =>
          onResponse(res?.data)
        );
      }
    }
  };

  return (
    <div className={style.inputSection}>
      <p className='font14'>
        Do you want to remove the{" "}
        {documentInfo?.documentName ||
          documentInfo?.fileName ||
          documentInfo.name}
      </p>
      <div className={style.buttonWrapper}>
        <Button className='w-100 mt-4' variant='light' onClick={onCancel}>
          Cancel
        </Button>
        <Button className='w-100 mt-4' variant='dark' onClick={handleRemove}>
          Remove
        </Button>
      </div>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          onCancel();
        }}
        timeDelay={2000}
      />
    </div>
  );
};

type assignDocumentprops = {
  onClose?: () => void;
};
export const AssignDocument: React.FC<assignDocumentprops> = ({ onClose }) => {
  const { aivaPlanList, updateProgram } = useProgramContext();
  const [alert, SetAlert] = useState(alertDefaultState);
  const [selectedPlan, setSelectedPlan] = useState<any[]>([]);

  const planWithFiles = useMemo(() => {
    return aivaPlanList.filter((plan: any) => !!plan?.documentLink);
  }, [aivaPlanList]);

  const selectPlan = (e: React.ChangeEvent<HTMLInputElement>, plan: any) => {
    const isChecked = e.target.checked;
    setSelectedPlan((prevSelectedPlan: any[]) => {
      if (isChecked) {
        // Add the plan to the selected plans
        return [...prevSelectedPlan, plan.id];
      } else {
        // Remove the plan from the selected plans
        return prevSelectedPlan.filter(
          (selectedPlanId) => selectedPlanId !== plan.id
        );
      }
    });
  };

  const handleAssignDoc = () => {
    updateProgram({ aivaPlan: selectedPlan }, (res: any) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "Plan add successfully.",
        });
      } else {
        const errMsg = res?.exception?.error?.message;
        SetAlert({
          show: true,
          state: "error",
          message: errMsg || "Something went wrong.",
        });
      }
    });
  };
  return (
    <div className={style.inputSection}>
      {planWithFiles.map((plan: any) => {
        return (
          <div
            key={plan.id}
            className={`input-row mb-0 ${style.checkBoxWrapper}`}
          >
            <CheckBoxCustom
              label={plan?.name}
              name={plan?.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                selectPlan(e, plan);
              }}
            />
          </div>
        );
      })}

      {!planWithFiles.length && (
        <div className='noData py-5'>
          <InboxIcon />
          <p> No documents in A.I.va plan yet</p>
        </div>
      )}

      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.state === "success" && onClose && onClose();
        }}
        timeDelay={2000}
      />

      {!!planWithFiles.length && (
        <Button
          className='w-100 mt-4'
          variant='light'
          onClick={handleAssignDoc}
        >
          Assign
        </Button>
      )}
    </div>
  );
};

type addProviderProps = {
  onClose?: () => void;
};
export const AddProviderBody: React.FC<addProviderProps> = ({ onClose }) => {
  const { programId, addProvider } = useProgramContext();
  const [provideName, setProvideName] = useState("");
  const [alert, SetAlert] = useState(alertDefaultState);
  const submitProvider = () => {
    addProvider(provideName, (res: any) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "New Provider add successfully.",
        });
        setProvideName("");
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: "Something went wrong.",
        });
      }
    });
  };

  return (
    <>
      <div className='input-row'>
        <input
          className={`input-field ${style.inputBg}`}
          type='text'
          value={provideName}
          placeholder='Example Pvt. Ltd.'
          onChange={(e) => {
            setProvideName(e.target.value);
          }}
        />
      </div>

      <div>
        <Button
          className='w-100'
          variant={provideName ? "light" : "disabled"}
          onClick={submitProvider}
        >
          Save
        </Button>
      </div>

      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.state === "success" && onClose && onClose();
        }}
        timeDelay={2000}
      />
    </>
  );
};

type removeProviderprops = {
  providerDetail: any;
  onClose?: () => void;
};
export const RemoveProviderBody: React.FC<removeProviderprops> = ({
  providerDetail = {},
  onClose,
}) => {
  const [alert, SetAlert] = useState(alertDefaultState);
  const { removeProvider } = useProgramContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleRemove = async (e: any) => {
    setIsLoading(true);
    removeProvider(providerDetail?.id, (res: any) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "Remove provider successfully.",
        });
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: "Something went wrong.",
        });
      }
    });
  };

  return (
    <div className={style.inputSection}>
      <p className='font14'>
        Do you want to remove the{" "}
        {providerDetail?.providerName || providerDetail?.projectId}
      </p>
      <div className={style.buttonWrapper}>
        <Button
          className='w-100 mt-4'
          variant={isLoading ? "disabled" : "light"}
          onClick={handleRemove}
        >
          Remove
        </Button>
      </div>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.state === "success" && onClose && onClose();
        }}
        timeDelay={2000}
      />
    </div>
  );
};

type addIntegratorProps = {
  onClose?: () => void;
};
export const AddIntegratorBody: React.FC<addIntegratorProps> = ({
  onClose,
}) => {
  const { addIntegrator } = useProgramContext();
  const [integrateName, setIntegrateName] = useState("");
  const [alert, SetAlert] = useState(alertDefaultState);

  const submitIntegrator = () => {
    addIntegrator(integrateName, (res: any) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "New Integretor add successfully.",
        });
        setIntegrateName("");
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: "Something went wrong.",
        });
      }
    });
  };

  return (
    <>
      <div className='input-row'>
        <input
          className={`input-field ${style.inputBg}`}
          type='text'
          value={integrateName}
          placeholder='Example Pvt. Ltd.'
          onChange={(e) => {
            setIntegrateName(e.target.value);
          }}
        />
      </div>

      <div>
        <Button
          className='w-100'
          variant={integrateName ? "light" : "disabled"}
          onClick={submitIntegrator}
        >
          Save
        </Button>
      </div>

      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.state === "success" && onClose && onClose();
        }}
        timeDelay={2000}
      />
    </>
  );
};

type removeIntegratorprops = {
  integratorDetail: any;
  onClose?: () => void;
};
export const RemoveIntegratorBody: React.FC<removeIntegratorprops> = ({
  integratorDetail = {},
  onClose,
}) => {
  const [alert, SetAlert] = useState(alertDefaultState);
  const { removeIntegrator } = useProgramContext();
  const [isLoading, setIsLoading] = useState(false);
  const handleRemove = async (e: any) => {
    setIsLoading(true);
    removeIntegrator(integratorDetail?.id, (res: any) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "Remove integretor successfully.",
        });
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: "Something went wrong.",
        });
      }
    });
  };

  return (
    <div className={style.inputSection}>
      <p className='font14'>
        Do you want to remove the{" "}
        {integratorDetail?.integratorName || integratorDetail?.projectId}
      </p>
      <div className={style.buttonWrapper}>
        <Button
          className='w-100 mt-4'
          variant={isLoading ? "disabled" : "light"}
          onClick={handleRemove}
        >
          Remove
        </Button>
      </div>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.state === "success" && onClose && onClose();
        }}
        timeDelay={2000}
      />
    </div>
  );
};

type KpiInfoProps = {
  kpiItemInfo: any;
  onClose?: () => void;
};
export const AddAnotherKPI: React.FC<KpiInfoProps> = ({
  kpiItemInfo,
  onClose,
}) => {
  const { updateProgram } = useProgramContext();
  const [alert, SetAlert] = useState(alertDefaultState);
  const [isLoading, setIsloading] = useState(false);
  const formulaVariablesDetails = kpiItemInfo?.formulaVariables || {};
  const formulaVariablesArr = Object.keys(formulaVariablesDetails) || [];
  const kpiFormula = kpiItemInfo?.formula || "";
  const [inputVariables, setInputVariables] = useState<string[]>([]);

  let formulaError = "";
  let evalResult = NaN;
  let finalResult = "0";

  if (
    formulaVariablesArr.every((variable: string) =>
      kpiFormula.includes(variable)
    )
  ) {
    let formula = kpiFormula;
    formulaVariablesArr.forEach((variable: string, i: number) => {
      const val = inputVariables[i] ? inputVariables[i] : 0;
      formula = formula.replace(new RegExp(variable, "g"), val);
    });
    evalResult = evaluateExpression(formula);
    if (!isNaN(evalResult)) {
      finalResult = `${Math.round(evalResult)}`;
    } else {
      console.error("Error in For", formula);
      finalResult = "--";
      formulaError = "Calculation error!";
    }
    finalResult = finalResult === "Infinity" ? "--" : finalResult;
  } else {
    formulaError = "Calculation error! please, choose other KPI";
  }

  const handleSubmit = () => {
    setIsloading(true);
    const variables = formulaVariablesArr.map((varItem: string, i: number) => {
      return {
        name: varItem,
        label: formulaVariablesDetails[varItem],
        value: inputVariables[i],
      };
    });
    const kpiPayload = [
      {
        baseLineValue: finalResult !== "--" ? finalResult : "NA",
        kpi: kpiItemInfo.id,
      },
    ];

    updateProgram(
      {
        kpis: kpiPayload,
      },
      (res) => {
        if (res?.status === "success") {
          SetAlert({
            show: true,
            state: "success",
            message: "New Integretor add successfully.",
          });
        } else {
          const errMsg = res?.exception?.error?.message;
          SetAlert({
            show: true,
            state: "error",
            message: errMsg || "Somthing went wrong!",
          });
          setIsloading(false);
        }
      }
    );
  };

  return (
    <div data-formula={kpiFormula}>
      {formulaVariablesArr.map((variable: string, i: number) => {
        return (
          <div className='input-row' key={i}>
            <label>{formulaVariablesDetails[variable]}</label>
            <input
              className={`input-field ${style.inputBg}`}
              type='number'
              placeholder='0.00'
              value={inputVariables[i]}
              onChange={(e) => {
                const inpVar = [...inputVariables];
                inpVar[i] = e.target.value;
                setInputVariables(inpVar);
              }}
            />
          </div>
        );
      })}
      <div className='d-flex mb-3'>
        {!!formulaError &&
          formulaVariablesArr?.length === inputVariables?.length &&
          inputVariables.every((item: any) => !!item) && (
            <div className='text-danger'>{formulaError}</div>
          )}
        <p className='ms-auto'>
          Total:{" "}
          <span>
            {" "}
            {finalResult !== "--"
              ? `${finalResult} ${kpiItemInfo?.Metrics || ""}`
              : "--"}
          </span>
        </p>
      </div>
      <div>
        <Button
          className='w-100'
          variant={!!formulaError || isLoading ? "disabled" : "light"}
          onClick={handleSubmit}
        >
          Add
        </Button>
      </div>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.state === "success" && onClose && onClose();
        }}
        timeDelay={2000}
      />
    </div>
  );
};

export const EditKPIItem: React.FC<KpiInfoProps> = ({
  kpiItemInfo,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { updateProgram } = useProgramContext();
  const [alert, SetAlert] = useState(alertDefaultState);
  const { selectedProgramDetail } = useProgramContext();
  const collaborators = selectedProgramDetail?.collaborators || [];
  const metrics = !!kpiItemInfo?.kpi?.length ? kpiItemInfo.kpi[0]?.Metrics : "";

  const onSubmit = handleSubmit((data) => {
    const kpiPayload = {
      id: kpiItemInfo.id,
      customerProfileId: data.customerProfileId,
      currentValue: data.currentValue,
      targetValue: data.targetValue,
    };

    updateProgram({ kpis: [kpiPayload] }, (res) => {
      if (res?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "KPI update successfully.",
        });
      } else {
        const errMsg = res?.exception?.error?.message;
        SetAlert({
          show: true,
          state: "error",
          message: errMsg || "Somthing went wrong!",
        });
      }
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <div className='input-row'>
        <label>Current value</label>
        <div className='input-wrap'>
          <input
            type='number'
            placeholder='0.00'
            className={`input-field ${style.inputBg}`}
            defaultValue={kpiItemInfo?.currentValue}
            {...register("currentValue", { required: true })}
          />
          <span className='input-icon' title={metrics}>
            {metrics}
          </span>
        </div>
      </div>
      <div className='input-row'>
        <label>Target value</label>
        <div className='input-wrap'>
          <input
            type='number'
            placeholder='0.00'
            className={`input-field ${style.inputBg}`}
            defaultValue={kpiItemInfo?.targetValue}
            {...register("targetValue", { required: true })}
          />
          <span className='input-icon' title={metrics}>
            {metrics}
          </span>
        </div>
      </div>
      <div className='input-row'>
        <label> Assignee</label>
        <Autocomplete
          defaultValue={kpiItemInfo?.customerProfile?.user?.name}
          {...register("customerProfileId", { required: true })}
          className={style.autoCompleteInput}
          id='kpi-collaborators-options'
          options={collaborators.map((customer: any) => ({
            ...customer,
            label: customer.user.name,
          }))}
          sx={{ width: 300 }}
          onChange={(_, value: any) => {
            setValue("customerProfileId", value?.id || "");
          }}
          renderInput={(params) => {
            return (
              <TextField
                className={`input-field ${style.autoInputCollaborators}`}
                {...params}
                placeholder='Assign collaborators'
              />
            );
          }}
          renderOption={(pops, option: any) => {
            const imgUrl =
              option?.user?.avatarUrl || "/assets/images/userdummy.png";
            return (
              <ListItem
                {...pops}
                alignItems='flex-start'
                id={option?.id}
                key={option?.id}
              >
                <ListItemAvatar>
                  <Avatar alt={option.user.name} src={imgUrl} />
                </ListItemAvatar>
                <ListItemText primary={option.user.name} />
              </ListItem>
            );
          }}
        />
      </div>

      <div>
        <Button type='submit' className='w-100' variant='light'>
          Save
        </Button>
      </div>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
          alert.state === "success" &&
            alert.state === "success" &&
            onClose &&
            onClose();
        }}
        timeDelay={2000}
      />
    </form>
  );
};

type collaboratorsProps = any;
export const AddCollborators: React.FC<collaboratorsProps> = () => {
  const { userData } = useAppDataContext();
  const {
    selectedProgramDetail,
    programId,
    updateProgram,
    removeCollaborator,
  } = useProgramContext();
  const collaborators = selectedProgramDetail?.collaborators || [];
  const [newCollaborator, setNewCollaborator] = useState<any>(null);
  const logedInUserCustomerProfileId =
    userData?.userProfile?.customerProfile?.id;
  const licensedCustomer = (
    selectedProgramDetail?.licensedCustomer || []
  ).filter(
    (user: any) =>
      !collaborators.some((selectedUser: any) => selectedUser.id === user.id)
  );
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [alert, SetAlert] = useState(alertDefaultState);

  const handleAssignCollaborators = () => {
    if (newCollaborator) {
      updateProgram(
        {
          customerProfileId: [
            newCollaborator.id,
            ...collaborators.map((item: any) => item.id),
          ],
          newCollaborator: newCollaborator.id,
        },
        (res) => {
          setIsApiLoading(false);
          if (res?.status === "success") {
            setNewCollaborator(null);
            SetAlert({
              show: true,
              state: "success",
              message: "Collaborator add successfully.",
            });
          } else {
            const errMsg = res?.exception?.error?.message;
            SetAlert({
              show: true,
              state: "error",
              message: errMsg || "Something went wrong!",
            });
          }
        }
      );
    }
  };

  const removeCollaboratorItem = (selectCollaborators: any) => {
    setIsApiLoading(true);
    removeCollaborator(selectCollaborators.id, (res) => {
      setIsApiLoading(false);
      if (res?.status === "success") {
        setNewCollaborator(null);
        SetAlert({
          show: true,
          state: "success",
          message: "Collaborator remove successfully.",
        });
      } else {
        const errMsg = res?.exception?.error?.message;
        SetAlert({
          show: true,
          state: "error",
          message: errMsg || "Something went wrong!",
        });
      }
    });
  };

  return (
    <div className={style.inputSection}>
      <div className={style.collaboratorDropdown}>
        <div className={style.leftItem}>
          <Autocomplete
            className={style.autoCompleteInput}
            id='collaborators-options'
            options={licensedCustomer.map((customer: any) => ({
              ...customer,
              label: customer.user.name,
            }))}
            value={newCollaborator}
            sx={{ width: 300 }}
            renderInput={(params) => {
              return (
                <TextField
                  className={`input-field ${style.autoInput}`}
                  {...params}
                  placeholder='Assign collaborators'
                />
              );
            }}
            renderOption={(pops, option: any) => {
              const imgUrl =
                option?.user?.avatarUrl || "/assets/images/userdummy.png";
              return (
                <ListItem
                  {...pops}
                  alignItems='flex-start'
                  id={option?.id}
                  key={option?.id}
                >
                  <ListItemAvatar>
                    <Avatar alt={option.user.name} src={imgUrl} />
                  </ListItemAvatar>
                  <ListItemText primary={option.user.name} />
                </ListItem>
              );
            }}
            onChange={(props, customer) => {
              setNewCollaborator(customer);
            }}
          />
        </div>
        <div className={style.rightItem}>
          <Button
            variant={!!newCollaborator && !isApiLoading ? "light" : "disabled"}
            onClick={handleAssignCollaborators}
          >
            Assign
          </Button>
        </div>
      </div>
      <CustomAlert
        show={alert.show}
        message={alert.message}
        type={alert.state}
        onClose={() => {
          SetAlert({ ...alert, show: false });
        }}
        timeDelay={2000}
      />
      <div>
        {collaborators.map((collaborator: any) => {
          const imgUrl =
            collaborator?.user?.avatarUrl || "/assets/images/userdummy.png";
          const collaboratorName = collaborator.user.name;
          return (
            <div
              key={collaborator.id}
              className={`${style.collaboratorsList} ${style.darkAccordion}`}
            >
              <div>
                <Avatar alt={collaboratorName} src={imgUrl} />
              </div>
              <p className={style.collaboratorsName}>{collaboratorName}</p>
              <div className='pointer'>
                {logedInUserCustomerProfileId !== collaborator.id && (
                  <IconButton
                    color='error'
                    disabled={isApiLoading}
                    title='Remove collaborator'
                    onClick={() => {
                      removeCollaboratorItem(collaborator);
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
