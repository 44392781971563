import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";
const BaseHelpCenterURL = `${process.env.REACT_APP_API_BASE_URL}help-center`;

export const getIssues = () => {
  let url = `${BaseHelpCenterURL}/issues`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const getMessages = (id:string) => {
  let url = `${BaseHelpCenterURL}/issue/${id}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};


export const postIssue = (body:any) => {
  let url = `${BaseHelpCenterURL}/issue`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const postMessage = (id:string , body:any) => {
  let url = `${BaseHelpCenterURL}/issue/${id}/message`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};
