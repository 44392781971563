import { FieldErrors, UseFormRegister } from "react-hook-form";
import { useAppDataContext } from "../../../context/appData.context";
import { FormValuesType } from "../../../pages/programSetup/formConfigerProgramme";
import GridCard from "../../atoms/gridCard";
import CheckBoxCard from "../../molecules/checkBoxCard";
import style from "./stepIndustryList.module.scss";

type props = {
  register?: UseFormRegister<FormValuesType>;
  errors?: FieldErrors<FormValuesType>;
  isFilter?: boolean;
};

const StepIndustryList: React.FC<props> = ({
  register,
  errors,
  isFilter = false,
}) => {
  const { industries } = useAppDataContext();
  const errorMsg = errors?.industry?.message || "";
  const showingIndustry = "Manufacturing, Retail";
  return (
    <GridCard>
      <div className={style.stepBoxWrapper}>
        <h4>Which industry is your problem related to?</h4>
        {errorMsg && <p className='text-danger'>{errorMsg}</p>}
        <div className={style.list}>
          {industries
            .filter(
              (industrie: any) =>
                !isFilter || showingIndustry.includes(industrie.name)
            )
            .map((industrie) => {
              if (`${industrie.name}`.toLowerCase() === "others") {
                return null;
              }
              return (
                <CheckBoxCard
                  key={industrie.id}
                  label={industrie.name}
                  icon={
                    <img
                      src={industrie?.iconUrl || "/assets/images/industry.png"}
                      alt=''
                      width={"20px"}
                    />
                  }
                  type='radio'
                  inputProps={{
                    ...(register
                      ? register("industry", {
                          required: "Please choose Industry.",
                        })
                      : {}),
                    value: industrie.id + "," + industrie.name,
                  }}
                />
              );
            })}
        </div>
        {industries.length === 0 && (
          <big className={`noDataBigText`}>No data available</big>
        )}
      </div>
    </GridCard>
  );
};

export default StepIndustryList;
