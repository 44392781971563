import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ReactNode } from "react";
import "./dialog.scss";

interface DialogProps {
  heading: any;
  body?: any;
  action?: any;
  closeIcon?: any;
  maxWidth?: any;
  openModal: boolean;
  setOpenModal?: any;
  className?: any;
  handleAddSection?: () => void;
  position?: "center" | "top-right";
  children?: ReactNode;
}

function DialogModal({
  openModal,
  setOpenModal,
  heading,
  body,
  action,
  position = "center",
  closeIcon = true,
  maxWidth = "sm",
  className = "",
  children = <></>,
}: DialogProps) {
  let paperStyle: React.CSSProperties = {
    backgroundColor: "#1f1e25",
    borderRadius: "1rem",
  };

  if (position === "top-right") {
    paperStyle = {
      ...paperStyle,
      position: "fixed",
      top: "2.5rem",
      right: 0,
      maxWidth: "450px",
    };
  } else if (maxWidth === "sm") {
    paperStyle = {
      ...paperStyle,
      width: "480px",
    };
  } else if (maxWidth === "md") {
    paperStyle = {
      ...paperStyle,
      width: "640px",
    };
  } else if (maxWidth === "lg") {
    paperStyle = {
      ...paperStyle,
      width: "800px",
    };
  }

  return (
    <Dialog
      className={className}
      maxWidth={maxWidth}
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      PaperProps={{
        style: paperStyle,
      }}
    >
      <DialogTitle>
        <div>{heading}</div>
        {closeIcon && <CloseIcon onClick={() => setOpenModal(false)} />}
      </DialogTitle>
      <DialogContent sx={{ overflow: "auto" }}>
        {body}
        {children}
      </DialogContent>
      {action && <DialogActions>{action}</DialogActions>}
    </Dialog>
  );
}

export default DialogModal;
