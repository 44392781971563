import Routing from "./Routing";
import AppDataProvider from "./context/appData.context";
import { useNavigate } from "react-router-dom";

const App = () => {
  // import Interceptor and run once
  let navigate = useNavigate();

  return (
    <div className='App'>
      <AppDataProvider>
        <Routing />
      </AppDataProvider>
    </div>
  );
};

export default App;
