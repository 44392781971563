import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
} from "@mui/material";
import { useAppDataContext } from "../../../context/appData.context";
import Button from "../../atoms/button";
import GridCard from "../../atoms/gridCard";
import { CaretIcon, SearchIcon } from "../../atoms/icons";

import CheckBoxCustom from "../../atoms/checkBoxCustom";
import style from "./expertFilter.module.scss";

type props = {
  queryParams: any;
  setQueryParams: (a: any) => any;
};

const menuOptions = [
  { text: "Low to High ", value: "asc" },
  { text: "High to Low", value: "desc" },
];

const ExpertFilter: React.FC<props> = ({ queryParams, setQueryParams }) => {
  const { industries, LOBs, locationList } = useAppDataContext();

  const handleIndustry = (e: {
    target: { checked: boolean; value: string };
  }) => {
    const isChecked = e.target.checked;
    const val = e.target.value;
    const industryIds = !!queryParams?.industryIds
      ? queryParams.industryIds.split(",")
      : [];
    if (isChecked) {
      industryIds.push(val);
      setQueryParams({
        ...queryParams,
        industryIds: industryIds.join(","),
      });
    } else {
      const filterIndustryIds = industryIds
        .filter((id: string) => id !== val)
        .join(",");
      setQueryParams({
        ...queryParams,
        industryIds: filterIndustryIds,
      });
    }
  };
  const handleLOBs = (e: { target: { checked: boolean; value: string } }) => {
    const isChecked = e.target.checked;
    const val = e.target.value;
    const lineOfBusinessIds = !!queryParams?.lineOfBusinessIds
      ? queryParams.lineOfBusinessIds.split(",")
      : [];
    if (isChecked) {
      lineOfBusinessIds.push(val);
      setQueryParams({
        ...queryParams,
        lineOfBusinessIds: lineOfBusinessIds.join(","),
      });
    } else {
      const filterLineOfBusinessIds = lineOfBusinessIds
        .filter((id: string) => id !== val)
        .join(",");
      setQueryParams({
        ...queryParams,
        lineOfBusinessIds: filterLineOfBusinessIds,
      });
    }
  };
  const handleLocation = (e: {
    target: { checked: boolean; value: string };
  }) => {
    const isChecked = e.target.checked;
    const val = e.target.value;
    const locations = !!queryParams?.locations
      ? queryParams.locations.split(",")
      : [];
    if (isChecked) {
      locations.push(val);
      setQueryParams({
        ...queryParams,
        locations: locations.join(","),
      });
    } else {
      const filterLocations = locations
        .filter((id: string) => id !== val)
        .join(",");
      setQueryParams({
        ...queryParams,
        locations: filterLocations,
      });
    }
  };

  return (
    <div>
      <Button variant='grey' className='w-100 mb-4'>
        <Select
          labelId='expertSorting'
          id='expertSorting'
          label='expertSorting'
          className={`w-100 ${style.sortingSelect}`}
          value={queryParams.ratingSort || "asc"}
          IconComponent={CaretIcon}
          onChange={(e: any) => {
            setQueryParams({ ...queryParams, ratingSort: e.target.value });
          }}
          renderValue={(selected) => {
            const opt = menuOptions.find((item) => item.value === selected);
            return `Sort by Rating: ${opt?.text}`;
          }}
        >
          {menuOptions.map((menu) => (
            <MenuItem key={menu.value} value={menu.value}>
              {menu.text}
            </MenuItem>
          ))}
        </Select>
      </Button>

      <GridCard title='Filters' className={style.filterCard}>
        <div className='input-row input-with-icon' style={{ marginBottom: 0 }}>
          <div className='input-icon'>
            <SearchIcon />
            <input
              type='text'
              className='input-field'
              style={{ backgroundColor: "#2c2b33" }}
              placeholder='Search by Name'
              value={queryParams?.search || ""}
              onChange={(e: any) => {
                setQueryParams({ ...queryParams, search: e.target.value });
              }}
            />
          </div>
        </div>
        <div className={style.divider}></div>
        <div className={style.accordionWrapper}>
          <Accordion
            className={style.darkAccordion}
            disableGutters
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={style.expandMore} />}
              aria-controls='filter-industry-content'
              id='filter-industry-header'
              className={`m-0 ${style.accordionHeader}`}
            >
              Industry
            </AccordionSummary>
            <AccordionDetails className={style.accordionBody}>
              {industries.map((industry) => {
                return (
                  <div
                    className={`input-row mb-0 ${style.checkBoxWrapper}`}
                    key={industry.id}
                  >
                    <CheckBoxCustom
                      label={industry.name}
                      value={industry.id}
                      checked={
                        !!queryParams?.industryIds
                          ? queryParams.industryIds.includes(industry.id)
                          : false
                      }
                      onChange={handleIndustry}
                    />
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion className={style.darkAccordion} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={style.expandMore} />}
              aria-controls='filter-LoB-content'
              id='filter-LoB-header'
              className={style.accordionHeader}
            >
              Line of business
            </AccordionSummary>
            <AccordionDetails className={style.accordionBody}>
              {LOBs.map((lob) => {
                return (
                  <div
                    className={`input-row mb-0 ${style.checkBoxWrapper}`}
                    key={lob.id}
                  >
                    <CheckBoxCustom
                      label={lob.name}
                      value={lob.id}
                      checked={
                        !!queryParams?.lineOfBusinessIds
                          ? queryParams.lineOfBusinessIds.includes(lob.id)
                          : false
                      }
                      onChange={handleLOBs}
                    />
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
          <Accordion className={style.darkAccordion} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={style.expandMore} />}
              aria-controls='filter-location-content'
              id='filter-location-header'
              className={style.accordionHeader}
            >
              Location
            </AccordionSummary>
            <AccordionDetails className={style.accordionBody}>
              {locationList.map((location) => {
                return (
                  <div
                    className={`input-row mb-0 ${style.checkBoxWrapper}`}
                    key={location}
                  >
                    <CheckBoxCustom
                      label={location}
                      value={location}
                      checked={
                        !!queryParams?.locations
                          ? queryParams.locations.includes(location)
                          : false
                      }
                      onChange={handleLocation}
                    />
                  </div>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      </GridCard>
    </div>
  );
};
export default ExpertFilter;
