import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getIndustries, getLOBs, logoutHandler } from "../../services/authForms";
import { getLocationsList } from "../../services/commonService";
import { getProfile } from "../../services/settings";
import { AppDataState, LOBsProps, ProviderProps, apiLoadingProps, customerUserProps, industriesProps, locationListProps } from "./type";

const CustomerProfileContext = createContext<AppDataState | undefined>(undefined);

// hook for getting context state and function
export const useAppDataContext = (): AppDataState => {
  const context = useContext(CustomerProfileContext);
  if (!context) {
    throw new Error("CustomerProfile must be used within a AppDataProvider");
  }
  return context;
};

const sampleData = {};
const arr: any[] = [];

// Context Provide
const AppDataProvider = ({ children }: ProviderProps) => {
  const navigate = useNavigate();
  // customer user data
  const [customerProfile, setCustomerProfile] = useState<any>(sampleData);
  const [userData, setUserData] = useState<customerUserProps>();
  const [industries, setIndustries] = useState<industriesProps>(arr);
  const [LOBs, setLoBs] = useState<LOBsProps>(arr);
  const [locationList, setLocationList] = useState<locationListProps>(arr);
  const [userLoadingState, setUserLoadingState] = useState<apiLoadingProps>("idle");

  const setProfile = useCallback((customerData: customerUserProps) => {
    setCustomerProfile(customerData);
  }, []);

  const logout = useCallback(() => {
    console.log("Logout...");
    logoutHandler()?.then((res: any) => {
      console.log(res);
      if (res?.status === 200) {
        localStorage.clear();
        setUserLoadingState("idle");
        setCustomerProfile(sampleData);
        setUserData(sampleData);
        navigate("/login");
      }
    });
  }, []);

  const fetchUserData = useCallback(async () => {
    setUserLoadingState("pending")
    try {
      const res = await getProfile();
      if (res?.data?.status === "success") {
        setUserData(res?.data?.data);
        setUserLoadingState("success");
      } else {
        console.error("getProfile: Somthing went wrong", res);
        setUserLoadingState("failed")
      }
    } catch (err) {
      setUserLoadingState("failed")
      console.error(err);
    }
  }, []);

  useEffect(() => {
    const userData = window.localStorage.getItem("storedProfileData");
    if (userData) {
      setCustomerProfile(JSON.parse(userData));
    }
    getIndustries()?.then((res) => {
      if (res?.data?.status === "success") {
        setIndustries(res.data.data);
      }
    });
    getLOBs()?.then((res) => {
      if (res?.data?.status === "success") {
        setLoBs(res.data.data);
      }
    });
    if (userData) fetchUserData();
  }, []);

  const customerEmail = customerProfile?.email;
  useEffect(() => {
    if (!!customerEmail) {
      getLocationsList()?.then((res) => {
        if (res?.data?.status === "success") {
          setLocationList(res.data.data.locations);
        }
      });
    }
  }, [customerEmail]);

  useEffect(() => {
    console.log("userData", userData);
  }, [userData]);

  return (
    <CustomerProfileContext.Provider
      value={{
        customerProfile,
        userData,
        userLoadingState,
        industries,
        LOBs,
        locationList,
        setProfile,
        fetchUserData,
        logout,
      }}
    >
      {children}
    </CustomerProfileContext.Provider>
  );
};

export default AppDataProvider;
