import styles from "./scheduledCalls.module.scss";
import Header from "../../components/organisms/header";
import ScheduledCallsFullView from "../../components/organisms/scheduledCallsFullView";

const ScheduledCallsPage = () => {
  return (
    <div className="mainWrapper">
      <Header />

      <section className="contentWrapper">
        <div className={styles.scheduledCallsContainer}>
          <ScheduledCallsFullView />
        </div>
      </section>
    </div>
  );
};

export default ScheduledCallsPage;
