import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}customer`;
const baseAuthUrl = `${process.env.REACT_APP_API_BASE_URL}auth`;

export const getIndustries = () => {
  const url = `${baseAuthUrl}/industry`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};
export const getLOBs = () => {
  const url = `${baseAuthUrl}/line-of-business`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const handleLogin = (body: any) => {
  const url = `${baseUrl}/magic-link`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const retrieveToken = (tmpToken: string) => {
  const url = `${baseUrl}/user/token`;
  return makeNetworkCall(HTTP_METHODS.POST, url, { token: tmpToken });
};

export const logoutHandler = () => {
  const url = `${baseAuthUrl}/user/token`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

export const getBusinessProblemList = ({ industryId = "", lineofBusinessId = "" }) => {
  const indId = industryId.split(",")[0];
  const lobId = lineofBusinessId.split(",")[0];

  const url = `${baseAuthUrl}/business-problems/${indId}/${lobId}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const getKeyProcessList = (businessProblemIds = "") => {
  const url = `${baseAuthUrl}/key-process`;
  const body = {
    businessProblemIds: [businessProblemIds],
  };
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const getValueDriverList = (keyProcessIds: string[] = []) => {
  const url = `${baseAuthUrl}/value-driver`;
  const body = { keyProcessIds: keyProcessIds };
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};
export const getKPI = (keyProcessIds: string[] = []) => {
  const url = `${baseAuthUrl}/kpi`;
  const body = { keyProcessIds: keyProcessIds };
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

type updateFilePayload = {
  type?: "file";
  file: File;
};
export const updateFile = (url: string, payload: updateFilePayload) => {
  const body = {
    type: "file",
    ...payload,
  };
  return makeNetworkCall(HTTP_METHODS.PUT, url, body);
};
