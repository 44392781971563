import { EditPencil } from "../../components/atoms/icons";
import Congratulations from "../../components/organisms/congratulations";
import { useProgramContext } from "../../context/program.context";
import style from "./programSetup.module.scss";

type props = {
  onEdit?: (i: number) => void;
  submitDetails?: any;
  formData?: any;
};

const ProgramFinished: React.FC<props> = ({
  onEdit,
  submitDetails,
  formData,
}) => {
  const { selectedProgramDetail } = useProgramContext();
  const KPIs = formData.valueDriversAndKPIs.KPIs;
  const valueDrivers = formData.valueDriversAndKPIs.valueDrivers;
  const valueDriversAndKPIsKeys = [
    ...new Set([...Object.keys(KPIs), ...Object.keys(valueDrivers)]),
  ];
  return (
    <div className={style.congratulationsWrapper}>
      <Congratulations setupName={selectedProgramDetail?.projectName}>
        <div className={style.contentWrapper}>
          <div className={style.contentText}>
            <div>
              <h5>Industry</h5>
              <p>{`${formData.industry}`.split(",")[1]}</p>
            </div>
          </div>
          <div className={style.contentText}>
            <div>
              <h5> Line of business </h5>
              <p>{`${formData.lineOfBusiness}`.split(",")[1]}</p>
            </div>
          </div>
          <div className={style.contentText}>
            <div>
              <h5>Business problem</h5>
              <p>{`${formData.businessProblem}`.split(",")[1]}</p>
            </div>
          </div>
          <div className={style.contentText}>
            <div>
              <h5> Key processes </h5>
              <p>
                {" "}
                {formData.keyProcesses
                  .map((keyProcess: any) => keyProcess.name)
                  .join(", ")}
              </p>
            </div>
          </div>
          <div className={style.contentText}>
            <div>
              <h5 className={style.heading}> Value drivers and KPIs </h5>
              {valueDriversAndKPIsKeys.map((key: string) => {
                const groupName = (KPIs[key] || valueDrivers[key])[0].name;
                return (
                  <div className='mb-4' key={groupName}>
                    <p className='mb-3'>{groupName}</p>
                    <p className={style.subHeading}>
                      KPIs:{" "}
                      {KPIs[key].reduce(
                        (
                          accumulator: string,
                          currentValue: any,
                          currentIndex: number,
                          array: any[]
                        ) => {
                          if (currentIndex === array.length - 1) {
                            return accumulator + currentValue.name;
                          } else {
                            return accumulator + currentValue.name + ", ";
                          }
                        },
                        ""
                      )}
                    </p>
                    <p className={style.subHeading}>
                      Value drivers:{" "}
                      {valueDrivers[key].reduce(
                        (
                          accumulator: string,
                          currentValue: any,
                          currentIndex: number,
                          array: any[]
                        ) => {
                          if (currentIndex === array.length - 1) {
                            return accumulator + currentValue.name;
                          } else {
                            return accumulator + currentValue.name + ", ";
                          }
                        },
                        ""
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
            <div
              onClick={() => {
                onEdit && onEdit(4);
              }}
            >
              <EditPencil />
            </div>
          </div>

          <div className={style.contentText}>
            <div>
              <h5 className={style.heading}> Baseline </h5>
              {formData.baseline.map((baselineItem: any, i: number) => {
                return (
                  <div className='mb-3' key={i}>
                    <p> {baselineItem.name}</p>
                    {baselineItem.formulaVariables.map((variable: any) => {
                      return (
                        <p className={style.subHeading}>
                          {variable.label}: {variable.value}
                        </p>
                      );
                    })}
                  </div>
                );
              })}
            </div>
            <div
              onClick={() => {
                onEdit && onEdit(5);
              }}
            >
              <EditPencil />
            </div>
          </div>
        </div>
      </Congratulations>
    </div>
  );
};
export default ProgramFinished;
