import CloseIcon from "@mui/icons-material/Close";
import { Drawer } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { fetchMeeting } from "../../../services/calendar";
import { LeftArrowIcon } from "../../atoms/icons";
import styles from "./upcomingCalls.module.scss";

interface DrawerProps {
  openDrawer: boolean;
  setOpenDrawer: any;
}

interface MeetingProps {
  id: string;
  startTime: string;
  agenda: string;
  smeUser: {
    avatarUrl: string;
  };
}

const UpcomingCalls = ({ openDrawer, setOpenDrawer }: DrawerProps) => {
  const now = new Date();
  const [currentDate, setCurrentData] = useState(now);

  const [meetingsData, setMeetingsData] = useState<
    Record<string, MeetingProps[]>
  >({});

  const navigate = useNavigate();

  const limit = 5;

  // Calculating the start time (Monday) and end time (Friday) of the current week
  const startTime = new Date(currentDate);

  const fetchData = async () => {
    try {
      const res = await fetchMeeting(
        startTime.toISOString(),
        startTime.toISOString(),
        limit
      );
      console.log(res?.data?.data?.meetings);
      const data: MeetingProps[] = res?.data?.data?.meetings || [];

      // Group meetings by date
      const groupedMeetings: Record<string, MeetingProps[]> = {};
      data.forEach((meeting) => {
        const date = new Date(meeting.startTime).toDateString();
        if (groupedMeetings[date]) {
          groupedMeetings[date].push(meeting);
        } else {
          groupedMeetings[date] = [meeting];
        }
      });

      console.log(groupedMeetings);

      setMeetingsData(groupedMeetings);
    } catch (err) {
      console.log(err);
    }
  };

  const formatTime = (startTime: string): string => {
    const date = new Date(startTime);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    fetchData();
  }, [openDrawer]);

  const navigateToSchedulesCallsPage = () => {
    navigate("/scheduledCalls");
  };

  const drawer = (
    <Drawer
      anchor='right'
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
      classes={{ paper: styles.upcomingCallsDrawer }}
      transitionDuration={{ appear: 500, enter: 500, exit: 500 }}
    >
      <div className={styles.upcomingCallsContainer}>
        <div className={styles.upcomingCallsHeader}>
          <div>Upcoming calls</div>
          <div
            className={styles.upcomingCallsCloseIcon}
            onClick={() => setOpenDrawer(false)}
          >
            <CloseIcon />
          </div>
        </div>

        <div className={styles.upcomingCallsBody}>
          {Object.entries(meetingsData).map(([date, meetings]) => (
            <div key={date} className={styles.upcomingCallsBodyData}>
              <p
                className={
                  new Date(date).toDateString() ===
                  new Date(currentDate).toDateString()
                    ? styles.currentDate
                    : styles.date
                }
              >
                {new Date(date).getDate()}
              </p>
              {meetings.map((meeting) => (
                <div
                  className={styles.upcomingCallsTimeSubjectImage}
                  key={meeting.startTime}
                >
                  <div>
                    {" "}
                    <span>{formatTime(meeting.startTime)}</span>
                    <span style={{ color: "#F3F2F7" }}>{meeting.agenda}</span>
                  </div>
                  <img
                    src={
                      meeting?.smeUser?.avatarUrl ||
                      "/assets/images/userdummy.png"
                    }
                    alt=''
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div
          className={styles.upcomingCallsAction}
          onClick={navigateToSchedulesCallsPage}
        >
          Full schedule <LeftArrowIcon />
        </div>
      </div>
    </Drawer>
  );

  return drawer;
};

export default UpcomingCalls;
