import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}meeting`;
const customerBaseUrl = `${process.env.REACT_APP_API_BASE_URL}customer`;

export const fetchMeeting = (start: string, end: string, limit?: number) => {
  let url;
  if (limit) url = `${baseUrl}/?startTime=${start}&skip=0&limit=${limit}`;
  else url = `${baseUrl}/?startTime=${start}&endTime=${end}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const postEngagementRating = (data: any) => {
  let url = `${customerBaseUrl}/engagement-rating`;
  return makeNetworkCall(HTTP_METHODS.POST, url, data);
};

export const getExpertCalendarDetails = (
  expertId: string,
  start: string,
  end: string
) => {
  let url = `${baseUrl}/sme/${expertId}/meeting/?startTime=${start}&endTime=${end}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const removeEvent = (eventId: string) => {
  let url = `${baseUrl}/sme/${eventId}/meeting`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

export const rescheduleEvent = (eventId: string, start: string) => {
  let url = `${baseUrl}/sme/${eventId}/meeting/?startTime=${start}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url);
};

export const getEngagementRating = (eventId: string) => {
  let url = `${customerBaseUrl}/engagement-rating/${eventId}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const editEngagementRating = (eventId: string, data: any) => {
  let url = `${customerBaseUrl}/engagement-rating/${eventId}`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, data);
};
