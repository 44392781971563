export default function StarFill() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9997 0.833344C10.3169 0.833344 10.6066 1.0134 10.747 1.29782L13.1283 6.12207L18.4536 6.90044C18.7674 6.94631 19.0279 7.16631 19.1257 7.46798C19.2236 7.76966 19.1417 8.1007 18.9145 8.32198L15.0618 12.0745L15.971 17.3758C16.0247 17.6885 15.8961 18.0044 15.6395 18.1909C15.3828 18.3773 15.0426 18.4019 14.7618 18.2542L9.9997 15.7499L5.23757 18.2542C4.95681 18.4019 4.61657 18.3773 4.35992 18.1909C4.10327 18.0044 3.97473 17.6885 4.02836 17.3758L4.9376 12.0745L1.08492 8.32198C0.85774 8.1007 0.775846 7.76966 0.87366 7.46798C0.971475 7.16631 1.23204 6.94631 1.54584 6.90044L6.87115 6.12207L9.25244 1.29782C9.39284 1.0134 9.68252 0.833344 9.9997 0.833344ZM9.9997 3.54939L8.17196 7.2522C8.05067 7.4979 7.81635 7.66829 7.54522 7.70792L3.45671 8.30551L6.41448 11.1864C6.61104 11.3778 6.70076 11.6538 6.65437 11.9242L5.95652 15.9931L9.61182 14.0708C9.85464 13.9431 10.1448 13.9431 10.3876 14.0708L14.0429 15.9931L13.345 11.9242C13.2986 11.6538 13.3884 11.3778 13.5849 11.1864L16.5427 8.30551L12.4542 7.70792C12.183 7.66829 11.9487 7.4979 11.8274 7.2522L9.9997 3.54939Z"
        fill="#FDFCFF"
      />
    </svg>
  );
}
