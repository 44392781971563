import styles from "./helpModal.module.scss";
import DialogModal from "../../atoms/dialog";
import Button from "../../atoms/button";
import { useForm } from "react-hook-form";
import { LeftArrowIcon } from "../../atoms/icons";
import { postIssue } from "../../../services/helpcenter";
import { useState } from "react";
import { Alert, Collapse } from "@mui/material";
import { Link } from "react-router-dom";

interface ModalProps {
  openModal: boolean;
  setOpenModal: any;
}

function HelpModal({ openModal, setOpenModal }: ModalProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [success, SetSuccess] = useState(false);

  const onSubmit = async (data: any) => {
    const apiPayload = {
      title: data?.title,
      feedbackMessage: data?.description,
      tags: [],
      digiDoubleAccuracy: -1,
    };
    reset();

    // api to create a new issue
    postIssue(apiPayload)?.then((res: any) => {
      if (res?.data?.status === "success") {
        SetSuccess(true);
        setTimeout(() => {
          SetSuccess(false);
        }, 3000);
      }
    });
  };

  const heading = (
    <>
      <Collapse in={success} unmountOnExit>
        <Alert
          className="mb-3 rounded-pill"
          variant="filled"
          severity="success"
          icon={""}
          onClose={() => {
            SetSuccess(false);
          }}
        >
          Feedback Sent Successfully
        </Alert>
      </Collapse>
      <div className={styles.dialogHeader}>
        <div>Feedback</div>
        <Link to="/helpCenter" className={styles.closeButton}>
          Help Center
          <span className={styles.icon}>
            <LeftArrowIcon />
          </span>
        </Link>
      </div>
    </>
  );

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.body}>
        <p>
          Is there something we can help you with? Leave your message below to
          contact our support.{" "}
        </p>
        <div>
          <label className={styles.label}>Title</label>
          <input
            className="input-field"
            type="text"
            placeholder="What it is about?"
            {...register("title", { required: true })}
          />
          {errors.title && (
            <span className="invalid-feedback d-block">This is required</span>
          )}
        </div>
        <div>
          <label className={styles.label}>Description</label>
          <textarea
            className={styles.textBoxTextArea}
            placeholder="Write your issue here..."
            rows={7}
            cols={50}
            {...register("description", { required: true })}
          />
          {errors.description && (
            <span className="invalid-feedback d-block">This is required</span>
          )}
        </div>
      </div>
      <div style={{ marginTop: "0.5rem" }}>
        <Button variant="light" className="w-100" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );

  const action = <div className="styles.buttonAction"></div>;

  return (
    <div>
      {openModal ? (
        <DialogModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          heading={heading}
          body={body}
          action={action}
          position="top-right"
          closeIcon={false}
        />
      ) : null}
    </div>
  );
}

export default HelpModal;
