import styles from "./customStatus.module.scss";

interface StatusProps {
  status: "pending" | "inProgress" | "approved" | "error";
  message: string;
}

const CustomStatus = ({ status, message }: StatusProps) => {
  return <div className={`${styles.status} ${styles[status]}`}>{message}</div>;
};

export default CustomStatus;
