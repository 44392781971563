import { Ref, forwardRef } from "react";
import styles from "./button.module.scss";
type VariantProps =
  | "primary"
  | "primaryDisabled"
  | "light"
  | "lightDisabled"
  | "noBorder"
  | "dark"
  | "darkDisabled"
  | "blue"
  | "grey"
  | "greyNoBorder"
  | "transparent"
  | "disabled"
  | "searchIcon"
  | "success"
  | "error"
  | "alert";
interface ChildrenProps {
  type?: "link" | "button" | "submit";
  variant?: VariantProps;
  children?: any;
  className?: string;
  onClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  [attr: string]: any;
}

const Button = forwardRef(
  (
    {
      type = "button",
      variant = "primary",
      children = "",
      className = "",
      onClick,
      ...attr
    }: ChildrenProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    return (
      <>
        {type === "link" && (
          <a
            onClick={onClick}
            className={`${styles.button} ${styles[variant]} ${className}`}
            {...attr}
          >
            {children}
          </a>
        )}

        {type === "button" && (
          <button
            ref={ref}
            type='button'
            onClick={onClick}
            className={`${styles.button} ${styles[variant]} ${className}`}
            {...attr}
          >
            {children}
          </button>
        )}

        {type === "submit" && (
          <button
            ref={ref}
            type={type}
            className={`${styles.button} ${styles[variant]} ${className}`}
            {...attr}
          >
            {children}
          </button>
        )}
      </>
    );
  }
);

export default Button;
