import Loader from "../../components/atoms/loader";
import ProgramView from "../../components/organisms/programView";
import { useProgramContext } from "../../context/program.context";
import NoProgram from "./noProgram";
type props = {};

const Programme: React.FC<props> = () => {
  const { programList, programLoadingStatus } = useProgramContext();
  if(programLoadingStatus === "idle"){
    return <Loader />
  }
  return <>{!!programList?.length ? <ProgramView /> : <NoProgram />}</>;
};
export default Programme;
