import { useEffect, useState } from "react";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FormValuesType } from "../../../pages/programSetup/formConfigerProgramme";
import { getKeyProcessList } from "../../../services/authForms";
import GridCard from "../../atoms/gridCard";
import CheckBoxCard from "../../molecules/checkBoxCard";
import style from "./stepCrucialProcessesProblem.module.scss";

type props = {
  errors?: FieldErrors<FormValuesType>;
  businessProblemId: string;
  register?: UseFormRegister<FormValuesType>;
  setValue?: UseFormSetValue<FormValuesType>;
  watch: UseFormWatch<FormValuesType>;
};

const StepCrucialProcessesProblem: React.FC<props> = ({
  businessProblemId,
  errors,
  register,
  setValue,
  watch,
}) => {
  const [keyProcessData, setKeyProcessData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const errorMsg = errors?.keyProcesses?.message || "";
  const keyProcessesValues = watch("keyProcesses") || [];
  const valueCount = keyProcessesValues?.length || 0;

  useEffect(() => {
    setIsDataLoading(true);
    const id = businessProblemId.split(",")[0];
    getKeyProcessList(id)?.then((res: any) => {
      const resData = res?.data;
      if (resData?.status === "success") {
        setKeyProcessData(resData.data);
      }
      setIsDataLoading(false);
    });
  }, [businessProblemId]);
  return (
    <GridCard>
      <div className={style.stepBoxWrapper}>
        <h4>What processes are crucial to your problem?</h4>
        <p>Select 1 or more processes.</p>
        {errorMsg && <p className='text-danger'>{errorMsg}</p>}
        <div className={style.list}>
          {keyProcessData.map((keyProcess: any) => {
            const reg: any = register
              ? register("keyProcesses", {
                  required: "Please select at least one option.",
                })
              : {};
            const isSelected = keyProcessesValues.some((item) =>
              item.includes(keyProcess.id)
            );
            return (
              <CheckBoxCard
                key={keyProcess.id}
                label={keyProcess.name}
                disabled={!isSelected && valueCount >= 3}
                inputProps={{
                  ...reg,
                  onChange: (e) => {
                    reg?.onChange && reg.onChange(e);
                    setValue &&
                      setValue("valueDriversAndKPIs.valueDrivers", {});
                    setValue && setValue("valueDriversAndKPIs.KPIs", {});
                  },
                  value: JSON.stringify(keyProcess),
                }}
                title={keyProcess.summary}
              />
            );
          })}
        </div>
        {!keyProcessData.length && (
          <div>
            {isDataLoading ? (
              <big className={`noDataBigText`}>Loading...</big>
            ) : (
              <big className={`noDataBigText`}>
                Data not found! <br />{" "}
                <small>Go back and change options.</small>
              </big>
            )}
            <input
              hidden
              value=''
              {...(register
                ? register("keyProcesses", {
                    required: "You can't go forward.",
                  })
                : {})}
            />
          </div>
        )}
      </div>
    </GridCard>
  );
};
export default StepCrucialProcessesProblem;
