import React, { ReactNode, useCallback, useEffect, useState } from "react";
import Button from "../../atoms/button";
import { LeftIcon, RightIcon } from "../../atoms/icons";
import style from "./stepperCustom.module.scss";

type props = {
  title?: string;
  data: ReactNode[];
  activeStep?: number;
  finalBtnContent?: ReactNode;
  finalBtnType?: "submit" | "button";
  nextBtnType?: "submit" | "button";
  onStepChange?: (i: number, b: boolean) => void;
  onBackClick?: (i: number, b: boolean) => void;
};

const StepperCustom: React.FC<props> = ({
  title,
  data,
  activeStep,
  finalBtnContent,
  finalBtnType = "button",
  nextBtnType = "button",
  onStepChange,
  onBackClick,
}) => {
  const [selectStep, setSelectStep] = useState(0);
  const dataLength = React.Children.toArray(data).length || 0;
  const isFinalStep = dataLength - 1 <= selectStep;
  const currentStep = (activeStep && activeStep > 0) ? activeStep : 0;

  const handleBack = useCallback(
    (e: any) => {
      const prevStep = selectStep - 1;
      currentStep === undefined && setSelectStep(prevStep >0 ? selectStep : 0);
      onStepChange && onStepChange(prevStep, false);
      onBackClick && onBackClick(prevStep, false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectStep, currentStep]
  );

  const handleNext = useCallback(
    (e: any) => {
      const nextStep = !isFinalStep ? selectStep + 1 : selectStep;
      currentStep === undefined && setSelectStep(nextStep);
      onStepChange && onStepChange(nextStep, isFinalStep);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataLength, selectStep, currentStep]
  );

  useEffect(() => {
    if (currentStep !== undefined) {
      const isLast = currentStep >= dataLength;
      setSelectStep(isLast ? dataLength - 1 : currentStep);
      if (nextBtnType === "submit") {
        onStepChange && onStepChange(currentStep, isLast);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, isFinalStep, nextBtnType]);

  return (
    <div className={style.stepper}>
      <div className={style.steps}>
        {data.map((item, i) => {
          const activeClass = (selectStep || activeStep) === i ? style.activeStep : "";
          return <div key={i} className={`${style.step} ${activeClass}`} />;
        })}
      </div>
      {title && <h2 className={style.stepperTitle}>{title}</h2>}
      <div className={style.stepperContent}>{data[selectStep]}</div>
      <div className={style.stepperFooter}>
        <Button type="button" variant={`noBorder`} onClick={handleBack}>
          {selectStep > 0 ? (
            <>
              <LeftIcon /> Back
            </>
          ) : (
            `Cancel`
          )}
        </Button>
        <Button
          type={isFinalStep ? finalBtnType : nextBtnType}
          variant={`light`}
          onClick={handleNext}
        >
          {isFinalStep && finalBtnContent && finalBtnContent}
          {!(isFinalStep && finalBtnContent) && (
            <>
              Next <RightIcon />
            </>
          )}
        </Button>
      </div>
    </div>
  );
};
export default StepperCustom;
