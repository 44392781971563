export default function EditPencil(props:any) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="edit">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3602 3.33317C15.1886 3.33317 15.0188 3.36696 14.8603 3.43261C14.7018 3.49826 14.5578 3.59448 14.4365 3.71579L4.38033 13.7719L3.68754 16.3122L6.22778 15.6194L16.2839 5.56324C16.4052 5.44193 16.5014 5.29792 16.5671 5.13943C16.6327 4.98094 16.6665 4.81107 16.6665 4.63951C16.6665 4.46796 16.6327 4.29809 16.5671 4.1396C16.5014 3.98111 16.4052 3.8371 16.2839 3.71579C16.1626 3.59448 16.0186 3.49826 15.8601 3.43261C15.7016 3.36696 15.5317 3.33317 15.3602 3.33317ZM14.2225 1.89281C14.5832 1.7434 14.9698 1.6665 15.3602 1.6665C15.7506 1.6665 16.1372 1.7434 16.4979 1.89281C16.8586 2.04222 17.1863 2.26121 17.4624 2.53728C17.7385 2.81335 17.9575 3.14109 18.1069 3.50179C18.2563 3.8625 18.3332 4.24909 18.3332 4.63951C18.3332 5.02993 18.2563 5.41653 18.1069 5.77723C17.9575 6.13794 17.7385 6.46568 17.4624 6.74175L7.24981 16.9544C7.14726 17.0569 7.01973 17.1309 6.87982 17.1691L2.71912 18.3038C2.43061 18.3825 2.12206 18.3006 1.9106 18.0891C1.69914 17.8776 1.6172 17.5691 1.69589 17.2806L2.83062 13.1199C2.86878 12.98 2.94279 12.8524 3.04534 12.7499L13.2579 2.53728C13.534 2.26121 13.8618 2.04222 14.2225 1.89281Z"
          fill="#E0DFE3"
        />
      </g>
    </svg>
  );
}
