import React from "react";
import styles from "./loader.module.scss";

type props = {
  absolute?: boolean;
};
const Loader: React.FC<props> = ({ absolute = false }) => {
  return (
    <section className={`${styles.appLoader} ${absolute ? styles.absoluteLoader : ""}`}>
      <div className={styles.bouncingLoader}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
};

export default React.memo(Loader);
