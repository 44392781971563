import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Rating } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useAppDataContext } from "../../../context/appData.context";
import {
  editEngagementRating,
  fetchMeeting,
  getEngagementRating,
  postEngagementRating,
} from "../../../services/calendar";
import { fetchRecording } from "../../../services/meeting";
import { stripeCheckout } from "../../../services/scheduleCall";
import CustomAlert from "../../atoms/alert/CustomAlert";
import Button from "../../atoms/button";
import DialogModal from "../../atoms/dialog";
import {
  CalendarIcon,
  DollarIcon,
  FileIcon,
  ImageIcon,
  LeftIcon,
  MicIcon,
  RightArrowIcon,
  RightIcon,
  StarFill,
  UserIcon,
  VideoIcon,
  ZoomCallIcon,
} from "../../atoms/icons";
import "./calendar.scss";
import styles from "./scheduledCallsFullView.module.scss";

const ScheduledCallsFullView = () => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<any | null>(null);
  const [eventModal, setEventModal] = useState(false);
  const [openRateEngagementModal, setOpenRateEngagementModal] = useState(false);
  const [events, setEvents] = useState<any | []>([]);
  const [feedbackField, setFeedbackField] = useState("");
  const [alert, SetAlert] = useState<any>({
    show: false,
    state: "success",
    message: "",
  });
  const [disableButton, setDisableButton] = useState(true);
  const [callFetchMeetingApi, setCallFetchMeetingApi] = useState(false);
  const navigate = useNavigate();

  const { userData } = useAppDataContext();
  const customerSubscriptionDetails =
    userData?.userProfile?.customerProfile?.customerSubscription;

  const alertRef = useRef(null);

  const [editRateEngagementModal, setEditRateEngagementModal] = useState(false);

  // rateEngagement
  const [ratings, setRatings] = useState({
    overall: 0,
    knowledge: 0,
    communication: 0,
    problemResolution: 0,
  });

  // editRateEngagementModal
  const [editRatings, setEditRatings] = useState({
    overall: 0,
    knowledge: 0,
    communication: 0,
    problemResolution: 0,
  });
  const [editFeedbackField, setEditFeedbackField] = useState("");

  const [isFormModified, setIsFormModified] = useState(false);

  const currentMonth = currentDate.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  // custom star styles
  const starStyles = {
    color: "#faaf00",
    fontSize: 48,
  };

  //custom empty star styles
  const emptyStarStyles = {
    color: "#606060",
    fontSize: 48,
  };

  useEffect(() => {
    const handleResize = () => {
      // Update the state based on the screen width
      setIsSmallScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchMeetingData = async (start: Date, end: Date) => {
    try {
      const res = await fetchMeeting(start.toISOString(), end.toISOString());
      const meetingsData = res?.data?.data?.meetings;
      const formattedEvents = meetingsData?.map((meeting: any) => {
        return {
          id: meeting?.id,
          customerName: meeting?.customerUser?.name,
          title: meeting?.agenda,
          start: meeting?.startTime,
          end: meeting?.endTime,
          customerImageUrl: meeting?.customerUser?.avatarUrl,
          amount: meeting?.amount,
          meetingLink: meeting?.meetingLink,
          sharedProjects: meeting?.sharedProjects,
          smeName: meeting?.smeUser?.name,
          smeImageUrl: meeting?.smeUser?.avatarUrl,
          smeUserId: meeting?.smeUserId,
          engagementRating: meeting?.engagementRating,
          status: meeting?.status,
          isDeleted: meeting?.isDeleted,
          documents: {
            aivaPlanFiles: meeting.aivaPlanFiles || [],
            files: meeting.files || [],
          },
        };
      });
      setEvents(formattedEvents);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const startOfMonth = moment(currentDate).startOf("month");
    const endOfMonth = moment(currentDate).endOf("month");

    fetchMeetingData(startOfMonth.toDate(), endOfMonth.toDate());

    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(currentDate); // Manually set the calendar date
    }
  }, [currentDate, callFetchMeetingApi]);

  useEffect(() => {
    // Checking if any of the ratings values is 0
    const isAnyRatingZero =
      ratings.overall === 0 ||
      ratings.knowledge === 0 ||
      ratings.communication === 0 ||
      ratings.problemResolution === 0;

    // Setting the disableButton state based on the check
    setDisableButton(isAnyRatingZero);
  }, [ratings, feedbackField]);

  useEffect(() => {
    if (alert.show) {
      if (alertRef.current) {
        (alertRef.current as HTMLElement)?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [alert.show, alertRef, alert]);

  const goToPreviousMonth = () => {
    const newDate = moment(currentDate).subtract(1, "months").toDate();
    setCurrentDate(newDate);
  };

  const goToNextMonth = () => {
    const newDate = moment(currentDate).add(1, "months").toDate();
    setCurrentDate(newDate);
  };

  const handleTodayClick = () => {
    const today = new Date();
    setCurrentDate(today);
    calendarRef?.current?.getApi()?.gotoDate(today);
  };

  const customHeader = () => {
    return (
      <div className={styles.customHeader}>
        <span onClick={goToPreviousMonth}>
          <LeftIcon />
        </span>
        <span onClick={goToNextMonth}>
          <RightIcon />
        </span>
      </div>
    );
  };

  function renderEventContent(info: any) {
    const event = info?.event;
    const unpaidClass =
      customerSubscriptionDetails?.subscriptionPlanName !==
        "Evise Enterprise" &&
      event?.extendedProps?.status === "PAYMENT_TO_BE_PAID"
        ? styles.unpaidEvent
        : event?.extendedProps?.isDeleted
        ? styles.cancelEvent
        : "";
    return (
      <div
        className={`${styles.customEvent}  ${unpaidClass}
      `}
        title={event?.title}
      >
        <div>
          <span>{moment(event?.start).format("HH:mm")}</span>
          <span>{event?.title}</span>
        </div>
        <img
          src={
            event?.extendedProps?.smeImageUrl || "/assets/images/userdummy.png"
          }
          alt='User Avatar'
          loading='lazy'
        />
      </div>
    );
  }

  // Event handler for Rating change
  const handleRatingChange = (category: string, value: any) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [category]: value,
    }));
  };

  // Event handler for editRating change
  const handleEditRatingChange = (category: string, value: any) => {
    setEditRatings((prevRatings) => ({
      ...prevRatings,
      [category]: value,
    }));
    setIsFormModified(true);
  };

  const handleCloseModal = () => {
    //setOpenRateEngagementModal(false);
    handleRateEngagementModal();
    setEventModal(false);
  };

  const goBackToPreviousPage = () => {
    navigate(-1);
  };

  // Event handler for Submit button click
  const handleFeedbackSubmit = async () => {
    const data = {
      overall: ratings?.overall,
      knowledge: ratings?.knowledge,
      communication: ratings?.communication,
      resolution: ratings?.problemResolution,
      comment: feedbackField,
      smeUserId: selectedEvent?.extendedProps?.smeUserId,
      callId: selectedEvent?.id,
    };
    try {
      const res = await postEngagementRating(data);
      console.log(res?.data);
      if (res?.data?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "Feedback Submitted Successfully",
        });
        setCallFetchMeetingApi(!callFetchMeetingApi);
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: res?.data?.exception?.error?.message,
        });
      }
    } catch (err) {
      console.log(err);
      SetAlert({
        show: true,
        state: "error",
        message: "Something went wrong",
      });
    }
  };

  const handleEditFeedbackSubmit = async () => {
    const data = {
      overall: editRatings?.overall,
      knowledge: editRatings?.knowledge,
      communication: editRatings?.communication,
      resolution: editRatings?.problemResolution,
      comment: editFeedbackField,
      smeUserId: selectedEvent?.extendedProps?.smeUserId,
      callId: selectedEvent?.id,
    };
    console.log(data);
    try {
      const res = await editEngagementRating(selectedEvent?.id, data);
      console.log(res?.data);
      if (res?.data?.status === "success") {
        SetAlert({
          show: true,
          state: "success",
          message: "Feedback Submitted Successfully",
        });
        setCallFetchMeetingApi(!callFetchMeetingApi);
        setTimeout(() => {
          handleCloseModal();
        }, 2000);
      } else {
        SetAlert({
          show: true,
          state: "error",
          message: res?.data?.exception?.error?.message,
        });
      }
    } catch (err) {
      console.log(err);
      SetAlert({
        show: true,
        state: "error",
        message: "Something went wrong",
      });
    }
  };

  const handleOpenEditRateEditEngagamentModal = async () => {
    const res = await getEngagementRating(selectedEvent?.id);
    if (res?.data?.status === "success") {
      //console.log(res?.data?.data);
      const engagementRating = res?.data?.data?.engagementRating;
      setEditRatings({
        overall: engagementRating?.overall,
        knowledge: engagementRating?.knowledge,
        communication: engagementRating?.communication,
        problemResolution: engagementRating?.resolution,
      });
      setEditFeedbackField(engagementRating?.comment);
    } else {
      console.log("Something went wrong");
    }
    setEditRateEngagementModal(true);
  };

  const handleRateEngagementModal = () => {
    setRatings({
      overall: 0,
      knowledge: 0,
      communication: 0,
      problemResolution: 0,
    });
    SetAlert({
      show: false,
      state: "",
      message: "",
    });
    setFeedbackField("");
    setOpenRateEngagementModal(false);
    setEditRateEngagementModal(false);
    setIsFormModified(false);
  };

  const handleEditTextField = (e: any) => {
    setEditFeedbackField(e?.target?.value);
    setIsFormModified(true);
  };

  const handleStripeCheckoutFlow = async () => {
    try {
      const res = await stripeCheckout(selectedEvent?.id);
      if (res?.data?.status === "success") {
        const url = res?.data?.data?.session?.url;
        const width = 450;
        const height = 730;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;
        window.open(
          url,
          "stripe",
          `menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=${width}, height=${height}, top=${top}, left=${left}`
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const rateEngagementBody = (
    <div className={styles.rateEngagementBodyContainer}>
      <p>
        With respect to your recent engagement with the{" "}
        {selectedEvent?.extendedProps?.smeName} please share your feedback.
      </p>
      <div>
        <small>Overall satisfaction</small>
        <div>
          <Rating
            name='size-large'
            value={ratings?.overall}
            onChange={(event, value) => handleRatingChange("overall", value)}
            size='large'
            icon={<StarIcon style={starStyles} />}
            emptyIcon={<StarBorderIcon style={emptyStarStyles} />}
          />
        </div>
      </div>
      <div>
        <small>Knowledge and expertise</small>
        <div>
          <Rating
            name='size-large'
            value={ratings?.knowledge}
            onChange={(event, value) => handleRatingChange("knowledge", value)}
            size='large'
            icon={<StarIcon style={starStyles} />}
            emptyIcon={<StarBorderIcon style={emptyStarStyles} />}
          />
        </div>
      </div>
      <div>
        <small>Communication skills</small>
        <div>
          <Rating
            name='size-large'
            value={ratings?.communication}
            onChange={(event, value) =>
              handleRatingChange("communication", value)
            }
            size='large'
            icon={<StarIcon style={starStyles} />}
            emptyIcon={<StarBorderIcon style={emptyStarStyles} />}
          />
        </div>
      </div>
      <div>
        <small>Problem resolution</small>
        <div>
          <Rating
            name='size-large'
            value={ratings?.problemResolution}
            onChange={(event, value) =>
              handleRatingChange("problemResolution", value)
            }
            size='large'
            icon={<StarIcon style={starStyles} />}
            emptyIcon={<StarBorderIcon style={emptyStarStyles} />}
          />
        </div>
      </div>
      <div>
        <small>Other</small>
        <textarea
          className='textarea-field mt-2'
          placeholder='Write your feedback and suggestions for improvement here...'
          rows={4}
          value={feedbackField}
          onChange={(e) => setFeedbackField(e?.target?.value)}
        />
      </div>
      <Button
        variant={disableButton ? "disabled" : "light"}
        className='w-100 mt-1'
        onClick={handleFeedbackSubmit}
      >
        Submit
      </Button>
      <div ref={alertRef}>
        {alert.show ? (
          <CustomAlert
            show={alert.show}
            message={alert.message}
            type={alert.state}
            onClose={() => {
              SetAlert({ ...alert, show: false });
            }}
          />
        ) : null}
      </div>
    </div>
  );

  const editRateEngagementBody = (
    <div className={styles.rateEngagementBodyContainer}>
      <p>
        With respect to your recent engagement with the{" "}
        {selectedEvent?.extendedProps?.smeName} please share your feedback.
      </p>
      <div>
        <small>Overall satisfaction</small>
        <div>
          <Rating
            name='size-large'
            value={editRatings?.overall}
            icon={<StarIcon style={starStyles} />}
            emptyIcon={<StarBorderIcon style={emptyStarStyles} />}
            size='large'
            readOnly
          />
        </div>
      </div>
      <div>
        <small>Knowledge and expertise</small>
        <div>
          <Rating
            name='size-large'
            value={editRatings?.knowledge}
            icon={<StarIcon style={starStyles} />}
            emptyIcon={<StarBorderIcon style={emptyStarStyles} />}
            size='large'
            readOnly
          />
        </div>
      </div>
      <div>
        <small>Communication skills</small>
        <div>
          <Rating
            name='size-large'
            value={editRatings?.communication}
            icon={<StarIcon style={starStyles} />}
            emptyIcon={<StarBorderIcon style={emptyStarStyles} />}
            size='large'
            readOnly
          />
        </div>
      </div>
      <div>
        <small>Problem resolution</small>
        <div>
          <Rating
            name='size-large'
            value={editRatings?.problemResolution}
            icon={<StarIcon style={starStyles} />}
            emptyIcon={<StarBorderIcon style={emptyStarStyles} />}
            size='large'
            readOnly
          />
        </div>
      </div>
      <div>
        <small>Other</small>
        <textarea
          className='textarea-field mt-2'
          placeholder='Write your feedback and suggestions for improvement here...'
          rows={4}
          value={editFeedbackField}
        />
      </div>
    </div>
  );

  const modalBody = useMemo(() => {
    const extendedInfo: any = selectedEvent?.extendedProps || {};
    const aivaPlanFiles = extendedInfo?.documents?.aivaPlanFiles;
    const docFiles = extendedInfo?.documents?.files;
    return (
      <ul className={styles.callDetails}>
        <li>
          <CalendarIcon />
          <p>{`${moment(selectedEvent?.start).format("D MMMM YYYY")}, ${moment(
            selectedEvent?.start
          ).format("HH:mm")}-${moment(selectedEvent?.end).format("HH:mm")}`}</p>
        </li>
        <li>
          <UserIcon />
          <div className={styles.customerName}>
            <img
              src={extendedInfo?.smeImageUrl || "/assets/images/userdummy.png"}
              alt=''
              loading='lazy'
            />
            {extendedInfo?.smeName}
          </div>
        </li>
        <li style={{ alignItems: "flex-start" }}>
          <VideoIcon style={{ marginTop: "0.5rem" }} />
          {moment(selectedEvent?.end).isBefore(new Date()) ? (
            <>
              <WatchRecording info={selectedEvent} />
            </>
          ) : customerSubscriptionDetails?.subscriptionPlanName !==
              "Evise Enterprise" &&
            extendedInfo?.status === "PAYMENT_TO_BE_PAID" ? (
            <p>Zoom link will be shared after payment</p>
          ) : (
            <>
              <Button
                variant='blue'
                type='link'
                href={extendedInfo?.meetingLink}
                target='_blank'
              >
                <ZoomCallIcon /> &nbsp; Join Zoom call
              </Button>
            </>
          )}
        </li>
        <li>
          <DollarIcon /> <p>{extendedInfo?.amount} USD</p>
        </li>
        {!!(aivaPlanFiles?.length || docFiles?.length) && (
          <li className={styles.files}>
            <FileIcon />
            <div className={styles.fileList}>
              {aivaPlanFiles?.map((file: any) => (
                <a href={file?.documentLink} title={file?.name} key={file.id}>
                  <FileIcon />
                  <span>{file?.name}</span>
                </a>
              ))}
              {docFiles?.map((file: any) => (
                <a href={file?.link} title={file?.fileName} key={file.id}>
                  {(file.type.toUpperCase() === "AUDIO" && <MicIcon />) ||
                    (file.type.toUpperCase() === "IMAGE" && <ImageIcon />) ||
                    (file.type.toUpperCase() === "VIDEO" && <VideoIcon />) || (
                      <FileIcon />
                    )}
                  <span>{file?.fileName}</span>
                </a>
              ))}
            </div>
          </li>
        )}

        {moment(selectedEvent?.end).isBefore(new Date()) && (
          <li>
            {(extendedInfo?.engagementRating === null && (
              <Button
                variant='dark'
                className='w-100 mt-1'
                onClick={() => setOpenRateEngagementModal(true)}
              >
                <StarFill /> <span className='m-1'>Rate engagement</span>
              </Button>
            )) || (
              <Button
                variant='dark'
                className='w-100 mt-1'
                onClick={handleOpenEditRateEditEngagamentModal}
              >
                <StarFill /> <span className='m-1'>View engagement rating</span>
              </Button>
            )}
          </li>
        )}
        {!moment(selectedEvent?.end).isBefore(new Date()) &&
          customerSubscriptionDetails?.subscriptionPlanName !==
            "Evise Enterprise" &&
          extendedInfo?.status === "PAYMENT_TO_BE_PAID" && (
            <Button
              variant='light'
              className='w-100 mt-1'
              onClick={handleStripeCheckoutFlow}
            >
              Proceed to Payment
            </Button>
          )}
      </ul>
    );
  }, [customerSubscriptionDetails?.subscriptionPlanName, selectedEvent]);

  const cancelBody = (
    <div>
      <h5>Event has been Cancelled</h5>
      <Button className='w-100 mt-2' variant='light' onClick={handleCloseModal}>
        Close
      </Button>
    </div>
  );

  const handleEventClick = (info: any) => {
    const event = info.event;
    setSelectedEvent(event);
    setEventModal(true);
  };

  return (
    <div className={styles.scheduledCallsFullViewContainer}>
      <div className={styles.scheduledCallsFullViewHeader}>
        <span onClick={goBackToPreviousPage}>
          <RightArrowIcon /> Scheduled calls
        </span>
        <div>
          <Button variant='dark' onClick={handleTodayClick}>
            Today
          </Button>
          <span>{customHeader()}</span>
          <p>{currentMonth}</p>
        </div>
      </div>
      <FullCalendar
        ref={calendarRef}
        views={{ dayGridMonth: {} }}
        plugins={[dayGridPlugin]}
        initialView='dayGridMonth' // Set the initial view to month view
        weekends={true}
        showNonCurrentDates={false}
        headerToolbar={{ start: "", center: "", end: "" }} // Hide the default header
        dayHeaderFormat={
          isSmallScreen ? { weekday: "short" } : { weekday: "long" } // Display full weekday names
        }
        events={events?.map((event: any) => {
          return {
            id: event?.id?.toString(), // Convert the id to a string
            title: event?.title,
            start: new Date(event?.start), // Convert start to a Date object
            end: new Date(event?.end), // Convert end to a Date object
            imageUrl: event?.customerImageUrl,
            amount: event?.amount,
            meetingLink: event?.meetingLink,
            sharedProjects: event?.sharedProjects,
            customerUser: {
              avatarUrl: event?.customerUser?.avatarUrl,
              name: event?.customerUser?.name,
            },
            name: event?.customerName,
            smeName: event?.smeName,
            smeImageUrl: event?.smeImageUrl,
            smeUserId: event?.smeUserId,
            engagementRating: event?.engagementRating,
            status: event?.status,
            isDeleted: event?.isDeleted,
            documents: event.documents,
          };
        })}
        eventContent={renderEventContent}
        eventClick={handleEventClick}
      />
      {eventModal && selectedEvent && (
        <DialogModal
          openModal={eventModal}
          setOpenModal={setEventModal}
          heading={selectedEvent?.title}
          body={
            selectedEvent?.extendedProps?.isDeleted ? cancelBody : modalBody
          }
        />
      )}
      {openRateEngagementModal && (
        <DialogModal
          openModal={openRateEngagementModal}
          setOpenModal={handleRateEngagementModal}
          heading={"Rate engagement"}
          body={rateEngagementBody}
        />
      )}
      {editRateEngagementModal && (
        <DialogModal
          openModal={editRateEngagementModal}
          setOpenModal={handleRateEngagementModal}
          heading={"View engagement rating"}
          body={editRateEngagementBody}
        />
      )}
    </div>
  );
};

export default ScheduledCallsFullView;

const WatchRecording = ({ info }: any) => {
  const [videoDetails, setVideoDetails] = useState<any>();
  const [apiLoading, setApiLoading] = useState<
    "idle" | "pending" | "success" | "failed"
  >("idle");
  const [statusMsg, setStatusMsg] = useState("");

  const getVideoDetails = () => {
    if (!videoDetails) {
      setApiLoading("pending");
      fetchRecording(`${info.id}`)?.then((res: any) => {
        const statusCode = res?.status;
        const resData = res.data || {};
        if (resData?.status === "success") {
          const videoData = resData?.data;
          setVideoDetails(videoData);
          setApiLoading("success");
        } else {
          const msg =
            resData?.exception?.error?.message || "Something went wrong!";
          setStatusMsg(msg);
          setApiLoading("failed");
        }
      });
    }
  };

  return (
    <>
      <div>
        <Button variant='blue' type='button' onClick={getVideoDetails}>
          <ZoomCallIcon /> <span className='mx-2'> Watch Recording </span>
        </Button>

        {apiLoading === "success" && (
          <div className={`${styles.zoomLink} ${styles.zoomLinkWrapper}`}>
            <p>
              <strong>Meeting link:</strong>{" "}
              <a
                href={videoDetails.fileData.play_url}
                target='_blank'
                rel='noreferrer'
              >
                Go to zoom
              </a>
            </p>
            <p>
              <strong>Passcode :</strong> {videoDetails.paasword || "NA"}{" "}
            </p>
          </div>
        )}

        {apiLoading === "failed" && (
          <div className={`${styles.popoverError} ${styles.zoomLinkWrapper}`}>
            {statusMsg}
          </div>
        )}

        {apiLoading === "pending" && (
          <div className={`${styles.zoomLink} ${styles.zoomLinkWrapper}`}>
            {`Loading...`}
          </div>
        )}
      </div>
    </>
  );
};
