import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}customer`;
const baseUrlUser = `${process.env.REACT_APP_API_BASE_URL}user`;
const baseAuthUrl = `${process.env.REACT_APP_API_BASE_URL}auth`;

export const getProfile = () => {
  let url;
  url = `${baseUrl}/profile`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const updateProfile = (body: any) => {
  let url;
  url = `${baseUrl}/profile`;
  return makeNetworkCall(HTTP_METHODS.PATCH, url, body);
};

export const pushFileOnS3 = (url: string, body: any) => {
  return makeNetworkCall(HTTP_METHODS.PUT, url, body);
};

export const removeProfileImage = () => {
  let url = `${baseUrlUser}/avatar`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

export const getConsentData = () => {
  const url = `${baseAuthUrl}/consent-content`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const cancelSubscription = () => {
  const url = `${baseUrl}/subscription`;
  return makeNetworkCall(HTTP_METHODS.DELETE, url);
};

export const manageStripeBilling = () => {
  const url = `${baseUrl}/billing/session`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};
