export default function RightArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33325 10.0002C3.33325 9.53993 3.70635 9.16683 4.16659 9.16683H15.8333C16.2935 9.16683 16.6666 9.53993 16.6666 10.0002C16.6666 10.4604 16.2935 10.8335 15.8333 10.8335H4.16659C3.70635 10.8335 3.33325 10.4604 3.33325 10.0002Z"
        fill="#E0DFE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5892 3.57757C10.9146 3.90301 10.9146 4.43065 10.5892 4.75609L5.3451 10.0002L10.5892 15.2442C10.9146 15.5697 10.9146 16.0973 10.5892 16.4228C10.2637 16.7482 9.7361 16.7482 9.41066 16.4228L3.57733 10.5894C3.25189 10.264 3.25189 9.73634 3.57733 9.41091L9.41066 3.57757C9.7361 3.25214 10.2637 3.25214 10.5892 3.57757Z"
        fill="#E0DFE3"
      />
    </svg>
  );
}
