import { Navigate, useLocation } from "react-router-dom";
import { useAppDataContext } from "../../../context/appData.context";
import Loader from "../loader";
import "./RequiredAuth.scss";

function RequiredAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const authToken = localStorage.getItem("accessToken");

  if (!authToken) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return children;
}

export function RequiredPlan({ children }: { children: JSX.Element }) {
  const { userData } = useAppDataContext();

  let location = useLocation();
  const customerSubscriptionDetails =
    userData?.userProfile?.customerProfile?.customerSubscription;

  if (!!userData && !customerSubscriptionDetails?.subscriptionPlanId) {
    return (
      <Navigate to='/subscriptionPlan' state={{ from: location }} replace />
    );
  }

  return children;
}

export function RequiredFreeProPlan({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const { userData } = useAppDataContext();
  const customerSubscriptionDetails =
    userData?.userProfile?.customerProfile?.customerSubscription;

  if (
    customerSubscriptionDetails?.subscriptionPlanName === "Evise Free Trial" ||
    customerSubscriptionDetails?.subscriptionPlanName === "Evise Pro"
  ) {
    return <Navigate to='/aiva' state={{ from: location }} replace />;
  }

  return children;
}

export function RequiredEnterprisePlan({
  children,
}: {
  children: JSX.Element;
}) {
  let location = useLocation();
  const { userLoadingState, userData } = useAppDataContext();
  const customerSubscriptionDetails =
    userData?.userProfile?.customerProfile?.customerSubscription;

  if (userLoadingState === "pending" || userLoadingState === "idle") {
    return (
      <>
        <Loader />
      </>
    );
  }

  if (
    customerSubscriptionDetails?.subscriptionPlanName !== "Evise Enterprise"
  ) {
    return <Navigate to='/aiva' state={{ from: location }} replace />;
  }

  return children;
}

export default RequiredAuth;
