import { Outlet } from "react-router";
import Header from "../../components/organisms/header";
import ProgramProvider from "../../context/program.context";

type props = {};

const ProgramLayout: React.FC<props> = () => {
  return (
    <div className="mainWrapper">
      {/* header */}
      <Header />
      <div className="contentWrapper">
        <ProgramProvider>
          <Outlet />
        </ProgramProvider>
      </div>
    </div>
  );
};
export default ProgramLayout;
