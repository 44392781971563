export default function ExternalLinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5003 3.33317C12.0401 3.33317 11.667 2.96007 11.667 2.49984C11.667 2.0396 12.0401 1.6665 12.5003 1.6665H17.5003C17.9606 1.6665 18.3337 2.0396 18.3337 2.49984V7.49984C18.3337 7.96007 17.9606 8.33317 17.5003 8.33317C17.0401 8.33317 16.667 7.96007 16.667 7.49984V4.51168L8.92291 12.2558C8.59748 12.5812 8.06984 12.5812 7.7444 12.2558C7.41897 11.9303 7.41897 11.4027 7.7444 11.0772L15.4885 3.33317H12.5003ZM4.16699 5.83317C3.94598 5.83317 3.73402 5.92097 3.57774 6.07725C3.42146 6.23353 3.33366 6.44549 3.33366 6.6665V15.8332C3.33366 16.0542 3.42146 16.2661 3.57774 16.4224C3.73402 16.5787 3.94598 16.6665 4.16699 16.6665H13.3337C13.5547 16.6665 13.7666 16.5787 13.9229 16.4224C14.0792 16.2661 14.167 16.0542 14.167 15.8332V10.8332C14.167 10.3729 14.5401 9.99984 15.0003 9.99984C15.4606 9.99984 15.8337 10.3729 15.8337 10.8332V15.8332C15.8337 16.4962 15.5703 17.1321 15.1014 17.6009C14.6326 18.0698 13.9967 18.3332 13.3337 18.3332H4.16699C3.50395 18.3332 2.86807 18.0698 2.39923 17.6009C1.93038 17.1321 1.66699 16.4962 1.66699 15.8332V6.6665C1.66699 6.00346 1.93038 5.36758 2.39923 4.89874C2.86807 4.4299 3.50395 4.1665 4.16699 4.1665H9.16699C9.62723 4.1665 10.0003 4.5396 10.0003 4.99984C10.0003 5.46007 9.62723 5.83317 9.16699 5.83317H4.16699Z"
        fill="#151419"
      />
    </svg>
  );
}
