import { Menu, MenuItem, MenuItemProps } from "@mui/material";
import React, { ReactNode, useState } from "react";
import Button from "../../atoms/button";
import { ThreeDotsIcon } from "../../atoms/icons";

import styles from "./listItemCustom.module.scss";

type props = {
  title?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  dots?: ReactNode;
  options?: MenuItemProps[];
  className?: string;
};
const ListItemCustom: React.FC<props> = ({ title, leftIcon, rightIcon, options, className = "" }) => {
  const [summaryMenu, setSummaryMenu] = useState<null | HTMLElement>(null);

  const openMenu = (e: any) => {
    setSummaryMenu(e.currentTarget);
  };

  const closeMenu = () => {
    setSummaryMenu(null);
  };

  return (
    <div className={`${styles.mainWrapper} ${styles.listItemCustomBox} ${className}`}>
      <div className={`${styles.contentBox} ${styles.textSummary}`}>
        {leftIcon && <div className={styles.icon}>{leftIcon}</div>}
        <p className={`font16 p-0 m-0`}>{title} </p>
      </div>
      <div className={`${styles.contentBox} ${styles.imgSummary}`}>
        {rightIcon && (
          <div>
            <span className={`${styles.imageIcon} `}> {rightIcon} </span>
          </div>
        )}
        {options && (
          <>
            <Button className={styles.threeDotsBtn} id={"options"} variant="noBorder" onClick={openMenu}>
              <ThreeDotsIcon />
            </Button>

            <Menu
              className={styles.dropDown}
              open={Boolean(summaryMenu)}
              anchorEl={summaryMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={closeMenu}
            >
              {options.map(({ className, children, onClick, ...restMenuProps }, i: number) => {
                const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                  onClick && onClick(event);
                  closeMenu();
                };
                return (
                  <MenuItem
                    key={i}
                    className={`${styles.menuOption} ${className}`}
                    onClick={handleClick}
                    {...restMenuProps}
                  >
                    {children}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </div>
    </div>
  );
};

export default ListItemCustom;
