import { AudioFile, Image, PictureAsPdf } from "@mui/icons-material";
import {
  Avatar,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFileTypeFromUrl } from "../../../common/util";
import { useProgramContext } from "../../../context/program.context";
import { formattedDate } from "../../../helper/time";
import Button from "../../atoms/button";
import CustomStatus from "../../atoms/customStatus";
import DialogModal from "../../atoms/dialog";
import GridCard from "../../atoms/gridCard";
import GridContainer from "../../atoms/gridContainer";
import {
  EditPencil,
  FileIcon,
  InboxIcon,
  PlusIcon,
  VideoIcon,
} from "../../atoms/icons";
import Loader from "../../atoms/loader";
import ListItemCustom from "../../molecules/ListItemCustom";
import ProgramHeader from "./programHeader";
import {
  AddAnotherKPI,
  AddIntegratorBody,
  AddNewDocument,
  AddProviderBody,
  AssignDocument,
  EditKPIItem,
  RemoveDocument,
  RemoveIntegratorBody,
  RemoveProviderBody,
  RenameDocument,
} from "./programModalBody";
import style from "./programView.module.scss";

type props = {};
interface ListingProps {
  id: string;
  name: string;
  process: string;
  status: boolean;
  baseLineValue: string;
  targetValue: string;
  currentValue: string;
  assignee: string;
}

type ModalNameProps =
  | ""
  | "uploadDocModal"
  | "renameDocumentModal"
  | "removeDocumentModal"
  | "assignDocModal"
  | "addProviderModal"
  | "removeProviderModal"
  | "addIntegratorModal"
  | "removeIntegratorModal"
  | "editKpiModal"
  | "addAnotherKPIModal";

const FileTypeIcon = ({ type = "" }: { type: string }) => {
  switch (type) {
    case "PDF":
      return <PictureAsPdf sx={{ color: "#ADAAB5" }} />;
    case "VIDEO":
      return <VideoIcon style={{ color: "#ADAAB5" }} />;
    case "IMAGE":
      return <Image sx={{ color: "#ADAAB5" }} />;
    case "AUDIO":
      return <AudioFile sx={{ color: "#ADAAB5" }} />;
    default:
      return <FileIcon sx={{ color: "#ADAAB5" }} />;
  }
};

const ProgramView: React.FC<props> = () => {
  const navigate = useNavigate();
  const {
    selectedProgramDetail,
    providerList,
    integretorList,
    kpiRecommendation,
    programLoadingStatus,
    topSme,
  } = useProgramContext();
  const [activeModal, setActiveModal] = useState<ModalNameProps>("");
  const [activeModalInfo, setActiveModalInfo] = useState<any>();
  const programStatus = selectedProgramDetail?.projectStatus;
  const documents = selectedProgramDetail?.files || [];
  const aivaPlanDocument = selectedProgramDetail?.aivaPlan || [];
  const KPIs = selectedProgramDetail?.kpis || [];
  const top4Sme = topSme.slice(0, 4);
  const [anchorElKpiOthers, setAnchorElKpiOthers] =
    React.useState<null | HTMLElement>(null);

  const handleDocument = (e: any) => {
    const val = e.target.value;
    if (val === "uploadDoc") {
      setActiveModal("uploadDocModal");
    } else if (val === "assignPlan") {
      setActiveModal("assignDocModal");
    }
  };

  const handleModalClose = () => {
    setActiveModal("");
    setActiveModalInfo(undefined);
  };

  const downloadFile = async ({
    fileName = "",
    fileLink = "",
  }: {
    fileName: string;
    fileLink: string;
  }) => {
    const fileFormat = getFileTypeFromUrl(fileLink);
    try {
      const response = await fetch(fileLink || "");
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName || "evise-document"}.${fileFormat.extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      <div className={style.programView}>
        <div>
          <ProgramHeader />
        </div>
        <div>
          {programLoadingStatus === "pending" && (
            <div className={style.loaderWrapper}>
              <Loader absolute={true} />
            </div>
          )}

          {programLoadingStatus === "success" && (
            <GridContainer>
              <div className='gridCol'>
                <GridCard>
                  <div className={`${style.content}`}>
                    <h4> Documents </h4>
                    <div className='pointer'>
                      <Select
                        labelId='programSorting'
                        id='programSorting'
                        label='programSorting'
                        className={`w-100 ${style.SortingSelect}`}
                        IconComponent={() => <></>}
                        value={"addDocument"}
                        onChange={handleDocument}
                      >
                        <MenuItem
                          className={`${style.menuItem} p-0`}
                          value={"addDocument"}
                          hidden
                        >
                          <PlusIcon className={`${style.plus} mb-1`} />
                          Add document
                        </MenuItem>
                        <MenuItem
                          className={style.menuOption}
                          value={"uploadDoc"}
                        >
                          Upload document
                        </MenuItem>

                        <MenuItem
                          className={style.menuOption}
                          value={"assignPlan"}
                        >
                          Assign existing plan
                        </MenuItem>
                      </Select>
                    </div>
                  </div>

                  <div className={style.docListWrap}>
                    {!!aivaPlanDocument?.length &&
                      aivaPlanDocument?.map((aivaDoc: any, i: number) => {
                        const fileFormat = getFileTypeFromUrl(
                          `${aivaDoc.link}`
                        );
                        return (
                          <ListItemCustom
                            className={style.listItemDesign}
                            key={i}
                            title={aivaDoc?.name}
                            leftIcon={
                              <FileTypeIcon type={fileFormat.fileType || ""} />
                            }
                            rightIcon={
                              <img
                                className={style.docUsericon}
                                src={
                                  aivaDoc?.customerProfile?.user?.avatarUrl ||
                                  "/assets/images/userdummy.png"
                                }
                                alt='profile pic'
                                title={aivaDoc?.customerProfile?.user?.name}
                                width={24}
                                height={24}
                              />
                            }
                            options={[
                              {
                                children: "Download",
                                onClick: (e) => {
                                  downloadFile({
                                    fileName: aivaDoc.name,
                                    fileLink: aivaDoc.documentLink,
                                  });
                                },
                              },
                              {
                                children: "Remove",
                                onClick: (e) => {
                                  setActiveModalInfo(aivaDoc);
                                  setActiveModal("removeDocumentModal");
                                },
                              },
                            ]}
                          />
                        );
                      })}

                    {!!documents?.length &&
                      documents?.map((doc: any, i: number) => {
                        const fileFormat = getFileTypeFromUrl(`${doc.link}`);
                        return (
                          <ListItemCustom
                            className={style.listItemDesign}
                            key={i}
                            title={doc?.documentName || doc?.fileName}
                            leftIcon={
                              <FileTypeIcon type={fileFormat.fileType || ""} />
                            }
                            rightIcon={
                              <img
                                className={style.docUsericon}
                                src={
                                  doc?.customerProfile?.user?.avatarUrl ||
                                  "/assets/images/userdummy.png"
                                }
                                alt='profile pic'
                                title={doc?.customerProfile?.user?.name}
                                width={24}
                                height={24}
                              />
                            }
                            options={[
                              {
                                children: "Download",
                                onClick: (e) => {
                                  downloadFile({
                                    fileName:
                                      doc?.documentName || doc?.fileName,
                                    fileLink: doc.link,
                                  });
                                  setActiveModalInfo(doc);
                                },
                              },
                              {
                                children: "Rename",
                                divider: true,
                                onClick: (e) => {
                                  setActiveModal("renameDocumentModal");
                                  setActiveModalInfo(doc);
                                },
                              },
                              {
                                children: "Remove",
                                onClick: (e) => {
                                  setActiveModalInfo(doc);
                                  setActiveModal("removeDocumentModal");
                                },
                              },
                            ]}
                          />
                        );
                      })}
                    {!documents?.length && !aivaPlanDocument?.length && (
                      <div className='noData'>
                        <InboxIcon />
                        <p> No documents yet</p>
                      </div>
                    )}
                  </div>
                </GridCard>
              </div>
              <div className={`gridCol`}>
                <GridCard title='Details'>
                  <div
                    className={`cardContent ${style.cardContent} ${style.detailCard}`}
                  >
                    <ul className={style.projectStages}>
                      <ul className={`${style.content}`}>
                        <li> Program set-up</li>{" "}
                        <li>
                          {" "}
                          {selectedProgramDetail?.setupAt
                            ? formattedDate(selectedProgramDetail.setupAt)
                            : "--"}{" "}
                        </li>
                      </ul>
                      <ul className={`${style.content}`}>
                        <li> Program started</li>{" "}
                        <li>
                          {selectedProgramDetail?.startedAt
                            ? formattedDate(selectedProgramDetail?.startedAt)
                            : "--"}{" "}
                        </li>
                      </ul>
                      <ul className={`${style.content}`}>
                        <li> Program finished</li>{" "}
                        <li>
                          {selectedProgramDetail?.finishedAt
                            ? formattedDate(selectedProgramDetail?.finishedAt)
                            : "--"}{" "}
                        </li>
                      </ul>
                    </ul>
                  </div>
                </GridCard>

                <GridCard title='Recommended Evisors'>
                  <div
                    className={style.ListItemCustomBox}
                    style={{ maxHeight: "228px", overflow: "auto" }}
                  >
                    {!!top4Sme.length &&
                      top4Sme?.map((smeUser: any, i: number) => {
                        return (
                          <div
                            onClick={() => navigate(`/experts/${smeUser.id}`)}
                          >
                            <ListItemCustom
                              key={smeUser.id}
                              title={smeUser.name}
                              leftIcon={
                                <Avatar
                                  alt={smeUser?.name}
                                  src={
                                    smeUser?.avatarUrl ||
                                    "/assets/images/userdummy.png"
                                  }
                                  title={smeUser?.name}
                                  className={style.keyAssignee}
                                  sx={{ width: "32px", height: "32px" }}
                                />
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                  {!top4Sme.length && (
                    <div className='noData py-3'>
                      <InboxIcon />
                      <p> No SMEs contacted yet</p>
                    </div>
                  )}
                </GridCard>
              </div>
              <div className='gridCol'>
                <GridCard title='Software providers'>
                  <div className={`cardContent ${style.cardContent}`}>
                    <div className={`${style.list} ${style.listContainer}`}>
                      {providerList.map((provider: any, i: number) => {
                        return (
                          <ListItemCustom
                            key={i}
                            title={provider.providerName}
                            options={[
                              {
                                children: "Remove",
                                onClick: (e) => {
                                  setActiveModalInfo(provider);
                                  setActiveModal("removeProviderModal");
                                },
                              },
                            ]}
                          />
                        );
                      })}

                      {programStatus !== "FINISHED" && (
                        <div
                          className={style.addBorder}
                          onClick={() => {
                            setActiveModal("addProviderModal");
                          }}
                        >
                          <Button variant='noBorder'>
                            <span className={style.addIcon}>
                              <PlusIcon />
                            </span>
                            Add provider
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </GridCard>
                <GridCard title='System integrators'>
                  <div className={`cardContent ${style.cardContent}`}>
                    <div className={`${style.list} ${style.listContainer}`}>
                      {integretorList.map((integrator: any, i: number) => {
                        return (
                          <ListItemCustom
                            key={i}
                            title={integrator.integratorName}
                            options={[
                              {
                                children: "Remove",
                                onClick: (e) => {
                                  setActiveModalInfo(integrator);
                                  setActiveModal("removeIntegratorModal");
                                },
                              },
                            ]}
                          />
                        );
                      })}
                      {programStatus !== "FINISHED" && (
                        <div
                          className={style.addBorder}
                          onClick={() => {
                            setActiveModal("addIntegratorModal");
                          }}
                        >
                          <Button variant='noBorder'>
                            <span className={style.addIcon}>
                              <PlusIcon />
                            </span>
                            Add integrator
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </GridCard>
              </div>
            </GridContainer>
          )}

          {programLoadingStatus === "success" && !!KPIs?.length && (
            <div className='gridCol'>
              <GridCard className={style.KpiWrapper}>
                <div className={style.headers}>
                  <h5> Key Performance Indicators</h5>
                  <div>
                    {programStatus !== "FINISHED" && (
                      <>
                        <Button
                          aria-label='more'
                          id='add-kpi-button'
                          aria-controls={
                            Boolean(anchorElKpiOthers)
                              ? "add-kpi-menu"
                              : undefined
                          }
                          aria-expanded={
                            Boolean(anchorElKpiOthers) ? "true" : undefined
                          }
                          aria-haspopup='true'
                          onClick={(e: any) => {
                            setAnchorElKpiOthers(e.currentTarget);
                          }}
                          variant='dark'
                        >
                          <PlusIcon /> Add KPI
                        </Button>
                        <Menu
                          id='long-menu'
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorElKpiOthers}
                          open={Boolean(anchorElKpiOthers)}
                          onClose={() => {
                            setAnchorElKpiOthers(null);
                          }}
                        >
                          {kpiRecommendation.map((kpi: any, i: number) => {
                            return (
                              <MenuItem
                                className={style.menuOption}
                                onClick={() => {
                                  setActiveModalInfo(kpi);
                                  setActiveModal("addAnotherKPIModal");
                                  setAnchorElKpiOthers(null);
                                }}
                                key={i}
                              >
                                {kpi.name}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </>
                    )}
                  </div>
                </div>
                <TableContainer
                  className={style.tableContainer}
                  component={Paper}
                >
                  <Table
                    aria-label='simple table'
                    className={style.tableWrapper}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "15%" }}>Name</TableCell>
                        <TableCell>Impacted processes</TableCell>
                        <TableCell>KPI status</TableCell>
                        <TableCell>Baseline value</TableCell>
                        <TableCell>Target value</TableCell>
                        <TableCell>Current value</TableCell>
                        <TableCell>Assignee</TableCell>
                        {programStatus !== "FINISHED" && (
                          <TableCell> </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {KPIs?.map((kpi: any, i: number) => {
                        let kpiName = "";
                        let kpiMetrics = "";
                        let keyProcessNames = "";
                        if (kpi?.kpi?.length) {
                          keyProcessNames = kpi.kpi.reduce(
                            (accumulator: string, currentItem: any) => {
                              kpiName = currentItem.name;
                              kpiMetrics = currentItem.Metrics;
                              return (
                                currentItem.keyProcess.name +
                                (!!accumulator ? `, ${accumulator}` : "")
                              );
                            },
                            ""
                          );
                        }
                        const baseValue = kpi.baseLineValue ?? "NA";
                        const targetValue = kpi.targetValue ?? "NA";
                        const currentValue = kpi.currentValue ?? "NA";
                        const assignee = kpi?.customerProfile?.user;

                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            key={i}
                          >
                            <TableCell>{kpiName}</TableCell>
                            <TableCell>{keyProcessNames}</TableCell>
                            <TableCell>
                              <CustomStatus
                                message={
                                  currentValue !== "NA" &&
                                  currentValue >= targetValue
                                    ? "achieved"
                                    : "in progress"
                                }
                                status={
                                  currentValue !== "NA" &&
                                  currentValue >= targetValue
                                    ? "approved"
                                    : "inProgress"
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {baseValue !== "NA"
                                ? `${baseValue} ${kpiMetrics || ""}`
                                : "NA"}
                            </TableCell>

                            <TableCell>
                              {targetValue !== "NA"
                                ? `${targetValue} ${kpiMetrics || ""}`
                                : "NA"}
                            </TableCell>
                            <TableCell>
                              {currentValue !== "NA"
                                ? `${currentValue} ${kpiMetrics || ""}`
                                : "NA"}
                            </TableCell>
                            <TableCell className={style.listItem}>
                              <div className='d-flex align-items-center py-3'>
                                <Avatar
                                  alt={assignee?.name}
                                  src={
                                    assignee?.avatarUrl ||
                                    "/assets/images/userdummy.png"
                                  }
                                  title={assignee?.name}
                                  className={style.keyAssignee}
                                />
                                <p className='text-ellipsis mb-0 ms-2'>
                                  {assignee?.name || "NA"}
                                </p>
                              </div>
                            </TableCell>
                            {programStatus !== "FINISHED" && (
                              <TableCell align='right'>
                                <Button
                                  variant='noBorder'
                                  onClick={() => {
                                    setActiveModalInfo(kpi);
                                    setActiveModal("editKpiModal");
                                  }}
                                >
                                  <EditPencil />
                                </Button>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </GridCard>
            </div>
          )}

          {programLoadingStatus === "failed" && (
            <GridCard className={style.failedStatus}>
              <big className={`noDataBigText`}>
                404!!!
                <div style={{ fontSize: "75%", marginBottom: "1.25rem" }}>
                  Program details not found.
                </div>
              </big>
            </GridCard>
          )}
        </div>
      </div>

      <DialogModal
        openModal={activeModal === "uploadDocModal"}
        setOpenModal={handleModalClose}
        heading={"Add document"}
      >
        <AddNewDocument onClose={handleModalClose} />
      </DialogModal>

      <DialogModal
        openModal={activeModal === "assignDocModal"}
        setOpenModal={handleModalClose}
        heading={"Assign plan to this program"}
      >
        <AssignDocument />
      </DialogModal>

      <DialogModal
        openModal={activeModal === "removeDocumentModal"}
        setOpenModal={handleModalClose}
        heading={"Remove Document"}
      >
        <RemoveDocument
          documentInfo={activeModalInfo}
          onCancel={handleModalClose}
        />
      </DialogModal>

      <DialogModal
        openModal={activeModal === "renameDocumentModal"}
        setOpenModal={handleModalClose}
        heading={"Rename Document"}
      >
        <RenameDocument
          documentInfo={activeModalInfo}
          onClose={handleModalClose}
        />
      </DialogModal>

      <DialogModal
        openModal={activeModal === "addAnotherKPIModal"}
        setOpenModal={handleModalClose}
        heading={`Add ${activeModalInfo?.name} KPI`}
      >
        <AddAnotherKPI
          kpiItemInfo={activeModalInfo}
          onClose={handleModalClose}
        />
      </DialogModal>

      <DialogModal
        openModal={activeModal === "editKpiModal"}
        setOpenModal={handleModalClose}
        heading={`Edit ${
          activeModalInfo?.kpi ? activeModalInfo?.kpi[0]?.name : "KPI"
        }`}
      >
        <EditKPIItem kpiItemInfo={activeModalInfo} onClose={handleModalClose} />
      </DialogModal>

      <DialogModal
        openModal={activeModal === "addProviderModal"}
        setOpenModal={handleModalClose}
        heading={"Add a new provider"}
      >
        <AddProviderBody onClose={handleModalClose} />
      </DialogModal>

      <DialogModal
        openModal={activeModal === "removeProviderModal"}
        setOpenModal={handleModalClose}
        heading={"Remove a provider"}
      >
        <RemoveProviderBody
          providerDetail={activeModalInfo}
          onClose={handleModalClose}
        />
      </DialogModal>

      <DialogModal
        openModal={activeModal === "addIntegratorModal"}
        setOpenModal={handleModalClose}
        heading={"Add a new integrator"}
      >
        <AddIntegratorBody onClose={handleModalClose} />
      </DialogModal>

      <DialogModal
        openModal={activeModal === "removeIntegratorModal"}
        setOpenModal={handleModalClose}
        heading={"Remove a integrator"}
      >
        <RemoveIntegratorBody
          integratorDetail={activeModalInfo}
          onClose={handleModalClose}
        />
      </DialogModal>
    </>
  );
};
export default ProgramView;
