import { useState } from "react";
import Button from "../../components/atoms/button";
import DialogModal from "../../components/atoms/dialog";
import { PlusIcon } from "../../components/atoms/icons";
import { AddNewProgram } from "../../components/organisms/programView/programModalBody";
import style from "./program.module.scss";

type props = {};

const NoProgram: React.FC<props> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div className={`${style.contentBox}`}>
        <div>
          <h1> Program </h1>
          <p> You don't have any program yet.</p>
          <Button
            className={style.addProgramBtn}
            variant="light"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <PlusIcon className={`me-2 ${style.plusIcon}`} /> Add first Program
          </Button>
        </div>
      </div>

      {/* add program modal */}
      <DialogModal openModal={isModalOpen} setOpenModal={setIsModalOpen} heading={"Name new program"}>
        <AddNewProgram />
      </DialogModal>
    </>
  );
};
export default NoProgram;
