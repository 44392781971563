import { HTTP_METHODS } from "../common/constants";
import { makeNetworkCall } from "./network";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}customer`;

export const createPlan = (body: any) => {
  const url = `${baseUrl}/aiva-plan`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};
export const getPlans = () => {
  const url = `${baseUrl}/aiva-plan`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};
export const getSinglePlan = (id:string) => {
  const url = `${baseUrl}/aiva-plan/${id}`;
  return makeNetworkCall(HTTP_METHODS.GET, url);
};

export const promptOptions = (promptId:number,planId:number,body:any) => {
  const url = `${baseUrl}/aiva/prompt/options/${promptId}/${planId}`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const promptDesc = (promptId:number,planId:number,body:any) => {
  const url = `${baseUrl}/aiva/prompt/descripton/${promptId}/${planId}`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};

export const exportPlan = (id:string, body:any) => {
  const url = `${baseUrl}/aiva-plan/${id}/export`;
  return makeNetworkCall(HTTP_METHODS.POST, url, body);
};