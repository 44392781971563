import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "../components/atoms/loader";
import {
  RequiredEnterprisePlan,
  RequiredFreeProPlan,
  RequiredPlan,
} from "../components/atoms/requiredAuth";
import ProgramLayout from "../layout/programLayout";
import Authentication from "../pages/authentication";
import ExpertsList from "../pages/expertsList";
import Program from "../pages/program";
import ProjectSetup from "../pages/programSetup";
import ScheduledCallsPage from "../pages/scheduledCalls";

const LoginPage = lazy(() => import("../pages/login"));
const SignupPage = lazy(() => import("../pages/signup"));
const SubscriptionPage = lazy(() => import("../pages/subscriptionPlan"));
const SubscriptionSuccess = lazy(() => import("../pages/subscriptionSuccess"));
const ConfirmationPage = lazy(() => import("../pages/confirmation"));
const HelpCenterPage = lazy(() => import("../pages/helpCenter"));
const DigiDoublePage = lazy(() => import("../pages/digiDouble"));
const AivaPage = lazy(() => import("../pages/aiva"));
const ExpertProfile = lazy(() => import("../pages/expertProfile"));
const AivaPlanSetup = lazy(() => import("../pages/aivaPlanSetup"));
const SettingsPage = lazy(() => import("../pages/settings"));
const SoftwareProvidersPage = lazy(() => import("../pages/softwareProviders"));
const BuildWithAiva = lazy(() => import("../pages/buildWithAIva"));
const MeetingSuccessPage = lazy(() => import("../pages/meetingSuccess"));
const SessionExpiredPage = lazy(() => import("../pages/sessionExpired"));
const ProgramSummary = lazy(() => import("../pages/programSummary"));

type props = {};

const Routing: React.FC<props> = () => {
  return (
    <>
      <Routes>
        <Route
          path='*'
          element={
            <Suspense fallback={<Loader />}>
              <Navigate to='/login' replace />
            </Suspense>
          }
        />
        <Route
          path='/sessionExpired'
          element={
            <Suspense fallback={<Loader />}>
              <SessionExpiredPage />
            </Suspense>
          }
        />
        <Route
          path='/authentication'
          element={
            <Suspense fallback={<Loader />}>
              <Authentication />
            </Suspense>
          }
        />
        <Route
          path='/login'
          element={
            <Suspense fallback={<Loader />}>
              <LoginPage />
            </Suspense>
          }
        />
        <Route
          path='/signup'
          element={
            <Suspense fallback={<Loader />}>
              <SignupPage />
            </Suspense>
          }
        />
        <Route
          path='/subscriptionPlan'
          element={
            <Suspense fallback={<Loader />}>
              <SubscriptionPage />
            </Suspense>
          }
        />
        <Route
          path='/subscriptionSuccess'
          element={
            <Suspense fallback={<Loader />}>
              <SubscriptionSuccess />
            </Suspense>
          }
        />
        <Route
          path='/confirmation'
          element={
            <RequiredPlan>
              <Suspense fallback={<Loader />}>
                <ConfirmationPage />
              </Suspense>
            </RequiredPlan>
          }
        />
        <Route
          path='/scheduledCalls'
          element={
            <RequiredPlan>
              <Suspense fallback={<Loader />}>
                <ScheduledCallsPage />
              </Suspense>
            </RequiredPlan>
          }
        />
        <Route
          path='/helpCenter'
          element={
            <RequiredPlan>
              <Suspense fallback={<Loader />}>
                <HelpCenterPage />
              </Suspense>
            </RequiredPlan>
          }
        />
        <Route
          path='/experts/digiDouble'
          element={
            <RequiredPlan>
              <Suspense fallback={<Loader />}>
                <DigiDoublePage />
              </Suspense>
            </RequiredPlan>
          }
        />

        <Route
          path='/aiva'
          element={
            <RequiredPlan>
              <Suspense fallback={<Loader />}>
                <AivaPage />
              </Suspense>
            </RequiredPlan>
          }
        />
        <Route
          path='/aiva/aivaPlanSetup'
          element={
            <RequiredPlan>
              <RequiredFreeProPlan>
                <Suspense fallback={<Loader />}>
                  <AivaPlanSetup />
                </Suspense>
              </RequiredFreeProPlan>
            </RequiredPlan>
          }
        />

        <Route
          path='/aiva/buildWithAiva'
          element={
            <RequiredPlan>
              <RequiredFreeProPlan>
                <Suspense fallback={<Loader />}>
                  <BuildWithAiva />
                </Suspense>
              </RequiredFreeProPlan>
            </RequiredPlan>
          }
        />

        <Route
          path='/experts'
          element={
            <RequiredPlan>
              <Suspense fallback={<Loader />}>
                <ExpertsList />
              </Suspense>
            </RequiredPlan>
          }
        />

        <Route
          path='/experts/:expertId'
          element={
            <RequiredPlan>
              <Suspense fallback={<Loader />}>
                <ExpertProfile />
              </Suspense>
            </RequiredPlan>
          }
        />
        <Route
          path='/setting'
          element={
            <RequiredPlan>
              <Suspense fallback={<Loader />}>
                <SettingsPage />
              </Suspense>
            </RequiredPlan>
          }
        />

        <Route
          path='/meetingSuccess'
          element={
            <Suspense fallback={<Loader />}>
              <MeetingSuccessPage />
            </Suspense>
          }
        />

        {/* program's route */}
        <Route
          path='/program'
          element={
            <RequiredPlan>
              <RequiredFreeProPlan>
                <RequiredEnterprisePlan>
                  <ProgramLayout />
                </RequiredEnterprisePlan>
              </RequiredFreeProPlan>
            </RequiredPlan>
          }
        >
          <Route index element={<Program />} />
          <Route path=':programId' element={<Program />} />
          <Route path='setup/:programId' element={<ProjectSetup />} />
          <Route path='summary/:programId' element={<ProgramSummary />} />
        </Route>
      </Routes>
    </>
  );
};
export default Routing;
