export const ALLOWED_FILE_TYPES = [
  "pdf",
  "doc",
  "docx",
  "txt",
  "msword",
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const ALLOWED_FILE_TYPES_AUDIO = [
  "pdf",
  "doc",
  "docx",
  "txt",
  "msword",
  "vnd.openxmlformats-officedocument.wordprocessingml.document",
  "mp3",
  "mpeg",
];

export const HTTP_METHODS: {
  GET: "get",
  POST: "post",
  UPDATE: "update",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
} = {
  GET: "get",
  POST: "post",
  UPDATE: "update",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export const SOCIAL_APPS = {
  LINKEDIN: "linkedin",
  YOUTUBE: "youtube",
  TWITTER: "twitter",
  MEDIUM: "medium",
  SUBSTACK: "substack",
  CALENDLY: "calendly",
  SOCIALLINK: "social"
};

export const URLS = {
  BASE: `${process.env.REACT_APP_API_BASE_URL}`,
  AUTH: `${process.env.REACT_APP_API_BASE_URL_AUTH}`,
  USER: `${process.env.REACT_APP_API_BASE_URL_USER}`,
  SSO_LINKEDIN: `${process.env.REACT_APP_LINKEDIN_SSO_URL}`,
};

export const LOCAL_STORAGE_VARIABLES = {
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  STORED_PROFILE_DATA: "storedProfileData"
}

export const FILE_SIZE = 10485760;

export const FILE_SIZE_AUDIO = 10737418240;
