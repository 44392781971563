export default function HelpCricleIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="help-circle" clipPath="url(#clip0_3334_309)">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99992 2.50016C5.85778 2.50016 2.49992 5.85803 2.49992 10.0002C2.49992 14.1423 5.85778 17.5002 9.99992 17.5002C14.1421 17.5002 17.4999 14.1423 17.4999 10.0002C17.4999 5.85803 14.1421 2.50016 9.99992 2.50016ZM0.833252 10.0002C0.833252 4.93755 4.93731 0.833496 9.99992 0.833496C15.0625 0.833496 19.1666 4.93755 19.1666 10.0002C19.1666 15.0628 15.0625 19.1668 9.99992 19.1668C4.93731 19.1668 0.833252 15.0628 0.833252 10.0002ZM10.215 6.68709C9.82708 6.62055 9.42812 6.69345 9.08878 6.89288C8.74945 7.09231 8.49164 7.4054 8.36103 7.7767C8.2083 8.21086 7.73254 8.439 7.29838 8.28627C6.86422 8.13355 6.63608 7.65778 6.78881 7.22363C7.05003 6.48103 7.56564 5.85486 8.24431 5.456C8.92298 5.05714 9.72091 4.91134 10.4968 5.04442C11.2726 5.1775 11.9764 5.58088 12.4833 6.1831C12.9902 6.7852 13.2676 7.54723 13.2666 8.33426C13.2662 9.60955 12.3207 10.4517 11.6455 10.9019C11.2825 11.1439 10.9254 11.3218 10.6623 11.4388C10.5296 11.4977 10.4179 11.5425 10.3373 11.5732C10.297 11.5885 10.2643 11.6004 10.2405 11.6089L10.2116 11.619L10.2023 11.6222L10.1991 11.6233L10.1978 11.6237C10.1976 11.6238 10.1968 11.6241 9.93325 10.8335L10.1968 11.6241C9.76015 11.7696 9.28822 11.5336 9.14268 11.097C8.99719 10.6605 9.23295 10.1888 9.66929 10.0431L9.68215 10.0386C9.69497 10.034 9.71598 10.0264 9.74401 10.0157C9.8002 9.99429 9.88376 9.96092 9.98543 9.91574C10.1911 9.82432 10.459 9.68977 10.721 9.51512C11.2957 9.13201 11.5999 8.72444 11.5999 8.3335L11.5999 8.33225C11.6005 7.93866 11.4618 7.55755 11.2083 7.25644C10.9548 6.95532 10.603 6.75363 10.215 6.68709ZM9.16658 14.1668C9.16658 13.7066 9.53968 13.3335 9.99992 13.3335H10.0083C10.4685 13.3335 10.8416 13.7066 10.8416 14.1668C10.8416 14.6271 10.4685 15.0002 10.0083 15.0002H9.99992C9.53968 15.0002 9.16658 14.6271 9.16658 14.1668Z"
          fill="#E0DFE3"
        />
      </g>
      <defs>
        <clipPath id="clip0_3334_309">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
