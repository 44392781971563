export default function CheckGrayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.2524 5.6209C12.5054 5.35334 12.4935 4.9314 12.2259 4.67847C11.9584 4.42554 11.5364 4.4374 11.2835 4.70496L6.7263 9.5258L4.9191 7.61405C4.66617 7.34649 4.24422 7.33463 3.97666 7.58756C3.7091 7.84049 3.69724 8.26243 3.95017 8.53L6.24183 10.9542C6.36776 11.0875 6.54299 11.1629 6.7263 11.1629C6.90961 11.1629 7.08484 11.0875 7.21076 10.9542L12.2524 5.6209Z"
        fill="#5A576A"
      />
    </svg>
  );
}
