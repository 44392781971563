import { NavLink, useParams } from "react-router-dom";
import { useAppDataContext } from "../../../context/appData.context";
import { DashboardIcon, UsersIcon } from "../../atoms/icons";
import IdeaIcon from "../../atoms/icons/IdeaIcon";
import styles from "./header.module.scss";

function HeaderResponsive() {
  const { programId } = useParams();
  const { userData } = useAppDataContext();

  const customerSubscriptionDetails =
    userData?.userProfile?.customerProfile?.customerSubscription;

  return (
    <div className={styles.dynamicHeader}>
      <div className={styles.logo}>
        <img src='/assets/images/header-logo.svg' alt='' loading='lazy' />
      </div>
      <ul className={styles.menuItems}>
        <li>
          <NavLink to='/aiva'>
            <DashboardIcon />
            A.I.va
          </NavLink>
        </li>
        {customerSubscriptionDetails?.subscriptionPlanName ===
          "Evise Enterprise" && (
          <li>
            <NavLink to={programId ? `/program/${programId}` : "/program"}>
              <IdeaIcon />
              Program
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to='/experts'>
            <UsersIcon /> Experts
          </NavLink>
        </li>
      </ul>
    </div>
  );
}

export default HeaderResponsive;
