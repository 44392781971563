import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { countries } from "../../../common/locationSearch";
import { filterValue } from "../../../common/util";
import Button from "../../atoms/button";
import StarRating from "../../atoms/starRating";

import style from "./expertCard.module.scss";

const ExpertCard: React.FC<any> = (expertProps) => {
  const userData = expertProps?.user || {};
  const badge = expertProps?.badges?.filter(
    (badge: any) =>
      badge?.name !== "Overall Top 1% Earning" && badge?.name !== "Top 1% SME"
  );
  const navigate = useNavigate();
  const address = filterValue(countries, expertProps?.address)[0];
  const country = filterValue(countries, expertProps?.country)[0];
  const finalAddress = country || null;

  return (
    <>
      <div className={style.expertCardWrapper}>
        <div className={style.rating}>
          <StarRating rating={expertProps?.rating} />
        </div>
        <div className={style.imgWrapper}>
          <Avatar
            className={style.avatarImg}
            alt={userData?.name}
            src={userData.avatarUrl || `/assets/images/userdummy.png`}
          />
          {!!badge.length && (
            <div className={style.imgCaption} title={badge.description}>
              {badge[0].name}
            </div>
          )}
        </div>
        <div className={style.primaryInfo}>
          <h5>{userData?.name}</h5>
          <div className={style.addressInfo}>
            {!!finalAddress && (
              <img
                loading="lazy"
                width="18.6"
                height="14px"
                src={`https://flagcdn.com/w20/${finalAddress.abbr?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${finalAddress.abbr?.toLowerCase()}.png 2x`}
                alt=""
              />
            )}
            <span>{expertProps?.country || "Country not available"}</span>
          </div>
        </div>
        <div className={style.overview}>
          <p>{expertProps?.overview}</p>
        </div>
      </div>
      <Button
        variant="greyNoBorder"
        className={`w-100`}
        onClick={() => {
          navigate(`/experts/${expertProps?.userId}`);
        }}
      >
        View profile
      </Button>
    </>
  );
};
export default ExpertCard;
